import Queue from './queue';

export class LogoutLock implements AbstractLogoutLock {
	private queue: Queue<LogoutQueueItem>;

	private isLogoutResolved: boolean;

	private idCounter: number;

	constructor() {
		this.queue = new Queue();
		this.isLogoutResolved = false;
		this.idCounter = 0;
	}

	private createReturnFunc(lockReceived: boolean, obj: LogoutQueueItem) {
		const resFunc = (ack: boolean) => {
			this.releaseLock(obj, ack);
		};
		if (lockReceived) {
			return resFunc;
		}
		return new Promise<typeof resFunc>((resolve) => {
			const timeout = setTimeout(() => {
				resolve(resFunc);
			}, 60000);
			const checkQueue = () => {
				if (this.queue.get() === obj) {
					this.queue.off('priorityChanged', checkQueue);
					clearTimeout(timeout);
					resolve(resFunc);
				}
			};

			this.queue.on('priorityChanged', checkQueue);
		});
	}

	async getLock() {
		if (this.isLogoutResolved) {
			return null;
		}

		const obj: LogoutQueueItem = { id: this.idCounter += 1 };
		this.queue.add(obj);
		const res = await this.createReturnFunc(this.queue.get() === obj, obj);
		return res;
	}

	private releaseLock(data: LogoutQueueItem, ack: boolean) {
		this.queue.delete(data);
		if (ack) {
			this.isLogoutResolved = true;
		}
	}
}
