import { CQLoginClient, CQQuizClient } from '../clients';
import { APP_CONFIG } from '../config';
import { createAppStore } from './app';
import { createQuizStore } from './quiz';
import { createSessionStore } from './session';
import { createProctorStore } from './ai-proctor';
import SpeedTest from '../libs/utils/speed-test/speed-test-by-cloudflare';
import { UploadQueue } from './uploadHander';

export * from './app';
export * from './session';
export * from './quiz';
export * from './ai-proctor';

const cqQuizClient = new CQQuizClient({
	quizServerURL: APP_CONFIG.QuizServerURL,
	staticStorageApiURL: APP_CONFIG.StaticStorageApiURL,
	socketServerURL: APP_CONFIG.SocketServerURL,
});

const cqLoginClient = new CQLoginClient({
	loginServiceURL: APP_CONFIG.LoginServiceURL,
});

export const speedTestObj = new SpeedTest(60);
speedTestObj.start();

const uploadQueue = new UploadQueue(
	APP_CONFIG.StaticStorageApiURL,
	APP_CONFIG.QuizServerURL,
);

export const useAppStore = createAppStore(cqQuizClient, speedTestObj);
export const useSessionStore = createSessionStore({ cqQuizClient, cqLoginClient });
export const useQuizStore = createQuizStore(cqQuizClient, uploadQueue);
export const useAiProctorStore = createProctorStore(cqQuizClient, uploadQueue);
