import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';

import { ReactComponent as CQLogo } from '../../../assets/img/cq-logo.svg';
import ChitkaraLogo from '../../../assets/img/chitkara.png';
import { ReactComponent as CQTime } from '../../../assets/img/testTime.svg';
import { ReactComponent as UnauthorizedIp } from '../../../assets/img/Unauthorized-pana.svg';
import { useAppStore, useQuizStore, useSessionStore } from '../../../store';
import { LoginForm } from '../../../components/login-form';
import { RegisterForm } from '../../../components/register-form';
import { ForgotPasswordForm } from '../../../components/forgot-password-form';

import './quiz-login.scoped.css';
import { logger } from '../../../libs/utils/logger';
import { OpenInApp } from '../../../components/open-in-app';
import { APP_CONFIG, localStorageKeyForVmDetection } from '../../../config';
import { VerifyEmail } from '../../../components/email-verification-form';

const quizStoreSelector = (state: QuizState) => ({
	quizData: state.quizData,
	serverClientTimeOffset: state.serverClientTimeOffset,
	serverTime: state.serverTime,
	isQuizSubmitted: state.isQuizSubmitted,
	logoutMessage: state.logoutMessage,

	setLogoutMessage: state.setLogoutMessage,
	validateEmail: state.validateEmail,
});

const sessionStoreSelector = (state: SessionState) => ({
	loginUser: state.loginUser,
	registerUser: state.registerUser,
	forgotHandler: state.forgotPassword,
	getSession: state.getSessionData,
});

const appStoreSelector = (state: AppState) => ({
	setAppLoading: state.setAppLoading,
	handleOpenInApp: state.handleOpenInApp,
	openInApp: state.openInApp,
	downloadApp: state.downloadApp,
});

export enum FormType {
	Login = 'login',
	Register = 'register',
	Forgot = 'forgot',
	EmailVerification = 'emailVerify',
}

interface QuizLoginScreenProps {
	quizName: string
	noOverlay?: boolean
	noForm?: boolean,
	formType?: FormType,
	token?: string,
	openInAppHandler?: () => Promise<void>,
}

function getDatesDifference(timeA: Date, timeB: Date) {
	const diff = Math.abs(timeA.getTime() - timeB.getTime());
	const d = Math.floor(diff / 1000 / 60 / 60 / 24);
	const days = d ? `${d} days` : '';
	const h = (Math.floor(diff / 1000 / 60 / 60) % 24);
	const hrs = h ? `${h} hrs` : '';
	const m = Math.floor(diff / 1000 / 60) % 60;
	const mins = m ? `${m} mins` : '';
	const s = Math.floor(diff / 1000) % 60;
	const sec = `${s} sec`;

	return {
		d,
		h,
		m,
		s,
		displayString: `${days} ${hrs} ${mins} ${sec}`.trim(),
	};
}

declare const navigator: any;
export const QuizLoginScreen: React.FunctionComponent<QuizLoginScreenProps> = (props) => {
	const {
		quizName, noOverlay, openInAppHandler, noForm, formType, token,
	} = props;

	const isElectronApp = localStorage.getItem('electronApp') || false;

	const {
		setAppLoading, handleOpenInApp,
		openInApp, downloadApp,
	} = useAppStore(appStoreSelector);

	const {
		loginUser, registerUser, forgotHandler, getSession,
	} = useSessionStore(sessionStoreSelector);
	const {
		quizData, serverTime,
		isQuizSubmitted, logoutMessage,
		validateEmail, setLogoutMessage,
	} = useQuizStore(quizStoreSelector);

	const [currentFormType, setCurrentFormType] = useState<string>(formType ?? FormType.Login);

	const [isBraveBrowser, setIsBraveBrowser] = useState<boolean>(false);
	const [isTestStarted, setIsTestStarted] = useState<boolean>(false);
	const [isTestEnded, setIsTestEnded] = useState<boolean>(false);
	const [isTestPrivate, setIsTestPrivate] = useState<boolean>(false);
	const [ipNotValid, setIpNotValid] = useState<boolean>(false);
	const [startIn, setStartIn] = useState<string>('');
	const handleLogin = useCallback(async (email: string, password: string, quizCode: string) => {
		try {
			// setAppLoading(true, 'logging you in...');
			setAppLoading(true);

			await loginUser(email, password, quizCode, quizName);
		} catch (e: any) {
			logger.error(e);
			message.error(e.message);

			throw e;
		} finally {
			setAppLoading(false);
		}
	}, [quizName, loginUser, setAppLoading]);

	const handleValidateEmail = useCallback(async (data: { email: string, token: string }) => {
		await validateEmail({ ...data, quizName });
		await getSession();
	}, [validateEmail, getSession, quizName]);

	const handleRegister = useCallback(async (
		name: string, email: string, password: string,
		quizCode: string, mobile: string, enrollmentId?: string,
	) => {
		try {
			// setAppLoading(true, 'signing you up...');
			setAppLoading(true);
			if (!quizData?.createdBy) {
				throw new Error('quiz createdBy not present.');
			}

			await registerUser(
				name, email, password, quizCode, mobile, quizName, quizData.createdBy, enrollmentId,
			);
		} catch (e: any) {
			logger.error(e);
			message.error(e.message);

			throw e;
		} finally {
			setAppLoading(false);
		}
	}, [quizName, quizData?.createdBy, registerUser, setAppLoading]);

	const isBrave = useCallback(async () => {
		const isBraveBrowser1 = await ((navigator?.brave && await navigator?.brave.isBrave()) || false);
		setIsBraveBrowser(isBraveBrowser1);
		if (isBraveBrowser1) {
			message.error('Switch to Chrome browser to get the best experience');
		}
	}, []);

	const isForceLogout = useCallback(async () => {
		const isVMLogout = JSON.parse(localStorage.getItem(localStorageKeyForVmDetection) || '{}');
		if (isVMLogout && (Date.now() - parseInt(isVMLogout, 10) < 20000)) {
			message.error('You have been logged out due to suspicious environment.');
			setLogoutMessage('Suspicious environment detected');
			localStorage.clear();
		}

		const isSessionExpired = localStorage.getItem('forceSessionExpired');
		if (isSessionExpired && (Date.now() - parseInt(isSessionExpired, 10) < 20000)) {
			message.error('Session expired');
			setLogoutMessage('Session expired');
			localStorage.clear();
		}
		const isSocketLogout = JSON.parse(localStorage.getItem('socketLogout') || '{}');
		console.log('isSocketLogoutisSocketLogoutisSocketLogoutisSocketLogout', isSocketLogout);
		if (isSocketLogout && isSocketLogout.date
			&& (Date.now() - parseInt(isSocketLogout.date, 10) < 20000)) {
			message.error(isSocketLogout.msg);
			setLogoutMessage(isSocketLogout.msg);
			localStorage.clear();
		}
	}, [setLogoutMessage]);

	useEffect(() => {
		isBrave();
		isForceLogout();
		if (!(window as any).chrome) {
			message.error('Switch to Chrome browser to get the best experience');
		}
	}, [isBrave, isForceLogout]);

	useEffect(() => {
		// testExpire.svg
		// console.log('Quiz Started...', quizData);
		if (quizData && quizData.invalidIp === 1) {
			console.log('IP check', quizData.invalidIp);
			setIpNotValid(true);
		} else {
			setIpNotValid(false);
		}

		if (quizData && quizData.isPrivate) {
			setIsTestPrivate(true);
		}

		if (quizData && serverTime && new Date(quizData.startTime) <= new Date(serverTime)) {
			setIsTestStarted(true);
		} else setIsTestStarted(false);

		if (quizData && serverTime
			&& quizData.endTime && new Date(quizData.endTime) < new Date(serverTime)) {
			setIsTestEnded(true);
		}
	}, [quizData, serverTime]);

	useEffect(() => {
		if (isQuizSubmitted) {
			return;
		}
		if (quizData && serverTime && quizData.startTime && !isTestStarted) {
			const currentTime = new Date(serverTime);
			const startTime = new Date(quizData.startTime);
			const {
				displayString, d, h, m, s,
			} = getDatesDifference(startTime, currentTime);
			if (currentTime < startTime) {
				setStartIn(`Test start in ${displayString}`);
				if (d <= 0 && h <= 0 && m <= 0 && s <= 0) window.location.reload();
			} else {
				setStartIn('Test already started.');
				setIsTestStarted(true);
			}
		}
	}, [isQuizSubmitted, serverTime, quizData, isTestStarted]);

	useEffect(() => {
		if (quizData && serverTime && quizData.endTime && !isTestEnded) {
			const currentTime = new Date(serverTime);
			const endTime = new Date(quizData.endTime);
			if (currentTime >= endTime) {
				setIsTestEnded(true);
			}
		}
	}, [serverTime, quizData, isTestEnded]);

	return (
		<div className="quiz-login-wrapper">
			{openInApp ? (
				<OpenInApp
					isOpen={openInApp}
					handleClose={() => handleOpenInApp(false)}
					downloadApp={downloadApp}
				/>
			) : ''}
			{ipNotValid ? (
				<div className="quiz-not-started">
					<div>
						<UnauthorizedIp width="300" height="300" />
						<div>
							<h1>Ip not allowed</h1>
						</div>
					</div>
				</div>
			) : ''}
			{!ipNotValid && isTestPrivate && !noOverlay && !FormType.EmailVerification ? (
				<div className="quiz-not-started">
					<div>
						<div className="quiz-not-started-TextPart">
							<h1>Test is private. Not allowed to attempt.</h1>
						</div>
					</div>
				</div>
			) : ''}
			{!ipNotValid && !isTestStarted && !isTestPrivate && !noOverlay ? (
				<div className="quiz-not-started">
					<div>
						<CQTime width="100%" />
						<div className="quiz-not-started-TextPart">
							<h1>The test hasn&apos;t started yet</h1>
							<h3>
								The test will be started on&nbsp;
								{quizData && new Date(quizData.startTime).toLocaleString()}
							</h3>
							<p>
								&nbsp;
								{startIn}
							</p>
						</div>
					</div>
				</div>
			) : ''}
			{!ipNotValid && isTestEnded && !isTestPrivate && quizData?.endTime ? (
				<div className="quiz-not-started">
					<div>
						<CQTime width="100%" />
						<div className="quiz-not-started-TextPart">
							<h1>The test has ended</h1>
							<h3>
								The test was ended on&nbsp;
								{quizData && new Date(quizData.endTime).toLocaleString()}
							</h3>
						</div>
					</div>
				</div>
			) : ''}
			{isBraveBrowser ? '' : (
				<>
					<div className="logo-container">
						{APP_CONFIG.isChitkara
							&& (
								<img src={ChitkaraLogo} alt="Logo" height={35} />
							)}
						{!APP_CONFIG.isChitkara
							&& (
								<CQLogo height="50" width="42" />
							)}
					</div>
					<div className={(ipNotValid || !isTestStarted || isTestEnded || (isTestPrivate && !FormType.EmailVerification)) && !noOverlay ? 'quiz-login-container blur' : 'quiz-login-container'}>
						<div className="quiz-specs-container">
							{
								quizData?.logo && (
									<div className="quiz-logo">
										<img src={quizData.logo} alt="organization logo" />
									</div>
								)
							}
							<div className="quiz-title">
								<span style={{ wordBreak: 'break-all' }}>{quizData?.title}</span>
							</div>
							<div
								className="fancy-divider"
								style={{ height: 5, margin: '1rem 0', maxWidth: 250 }}
							>
								<div />
								<div />
								<div />
							</div>
							<div className="quiz-info-text">
								<span>Total Questions</span>
								&nbsp;&nbsp;&nbsp;
								<span style={{ color: 'var(--primary-color)' }}>{quizData?.questions}</span>
							</div>
							<div className="quiz-info-text">
								<span>Total Time</span>
								&nbsp;&nbsp;&nbsp;
								<span style={{ color: 'var(--primary-color)' }}>
									{quizData?.quizTime}
									{' '}
									mins
								</span>
							</div>
							{(!('api' in window) && quizData?.isAppOnly && !isTestEnded && isTestStarted)
								&& (
									<div>
										<div
											className="quiz-info-text"
											style={{
												marginTop: '20px',
												display: 'flex',
												gap: '20px',
											}}
										>
											<Button onClick={() => downloadApp()} type="primary" style={{ borderRadius: '5px' }}>Download App</Button>
											<Button
												onClick={() => {
													if (openInAppHandler) {
														openInAppHandler();
														return;
													}
													handleOpenInApp(true);
												}}
												type="primary"
												style={{ borderRadius: '5px' }}
											>
												Open In App

											</Button>
										</div>
									</div>
								)}
						</div>
						{!noForm
							&& (
								<div className="forms-container">
									{logoutMessage
										&& (
											<>
												<div className="message-area">
													<strong className="message-danger">
														You have been logged out of the test.
													</strong>
													<br />
													<strong>Reason: </strong>
													{logoutMessage}
													.
												</div>
											</>
										)}
									<div className="forms-card">
										{!isElectronApp && quizData?.isAppOnly
											? (
												<>
													<h3>This test is only allowed in Desktop Application</h3>
												</>
											)
											: (
												<>
													<div className="forms-card-header" style={{ display: (currentFormType === FormType.Forgot) ? 'none' : 'inherit' }}>
														{currentFormType === FormType.EmailVerification
															&& (
																<Button
																	type="text"
																	size="small"
																>
																	Verify Credentials
																</Button>
															)}
														{currentFormType !== FormType.EmailVerification
															&& (
																<Button
																	className={currentFormType === FormType.Login ? 'active' : ''}
																	type="text"
																	size="small"
																	onClick={() => setCurrentFormType(FormType.Login)}
																>
																	sign in
																</Button>
															)}

														{
															quizData?.isSignUpAllowed
															&& currentFormType !== FormType.EmailVerification && (
																<Button
																	type="text"
																	size="small"
																	onClick={() => setCurrentFormType(FormType.Register)}
																>
																	sign up
																</Button>
															)
														}
													</div>
													{
														(() => {
															if (currentFormType === FormType.Login) {
																return (
																	<LoginForm
																		onLogin={handleLogin}
																		forgotPassword={() => setCurrentFormType(FormType.Forgot)}
																	/>
																);
															} if (currentFormType === FormType.Register) {
																return (
																	<RegisterForm onRegister={handleRegister} />);
															}
															if (currentFormType === FormType.Forgot) {
																return (
																	<ForgotPasswordForm
																		onForgot={forgotHandler}
																		goBack={() => setCurrentFormType(FormType.Login)}
																	/>
																);
															}

															if (currentFormType === FormType.EmailVerification) {
																return (
																	<VerifyEmail
																		token={token ?? ''}
																		onFinish={handleValidateEmail}
																	/>
																);
															}
															return null;
														})()
													}
												</>
											)}
									</div>
								</div>
							)}
					</div>

					{/* <div className="powered-by">
						<h3>Powered by CodeQuotient</h3>
					</div> */}
					{/* <div className="footer">
				<h3>Powered by CodeQuotient</h3>
				<p>
					We&apos;re an all in one recruitment solution
					for fast growing tech companies who want to plug
					and play their engineering requirements.
				</p>
			</div> */}

					<div className="cq-watermark">
						<span />
						<span />
					</div>
				</>
			)}
		</div>
	);
};
