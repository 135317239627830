import { EventEmitter } from 'events';
import TypedEventEmitter from 'typed-emitter';
import { logger } from '../../libs/utils/logger';
import { LiveStream } from './live-stream';
import JitsiTrack from '../../@types/jitsi/hand-crafted/modules/RTC/JitsiTrack';

interface SupportedEvents {
	'micStateChanged': () => void,
	'roomStateChanged': () => void,
	'webCamStateChanged': () => void,
	'newMessageReceived': () => void,
}

interface MessageData {
	roomName: string,
	message: string,
	messageId: string,
	sentAt: Date,
	sentBy: string,
	byCurrentUser: boolean,
}

export class LiveStreamManager extends (
	EventEmitter as new () => TypedEventEmitter<SupportedEvents>
) {
	private roomMap: Map<string, LiveStream>;

	private activeRoomSet: Set<string>;

	private roomsUsingMic: Set<string>;

	private roomUsingWebcam: Set<string>;

	private WebCamStream: MediaStreamTrack | null;

	private AudioStream: MediaStreamTrack | null;

	private messages: Array<MessageData>;

	constructor() {
		super();
		this.roomMap = new Map();
		this.activeRoomSet = new Set();
		this.roomsUsingMic = new Set();
		this.roomUsingWebcam = new Set();
		this.AudioStream = null;
		this.WebCamStream = null;
		this.messages = [];
	}

	getRoomsArray(): Array<string> {
		// eslint-disable-next-line no-restricted-syntax
		const result = Array.from(this.roomMap);
		return result.map((value) => value[0]);
	}

	async removeUserFromRoom(roomName: string): Promise<boolean> {
		const roomToDelete = this.roomMap.get(roomName);
		if (!roomToDelete) {
			return false;
		}
		try {
			const result = await roomToDelete.disconnectCall();
			return result;
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	get chatMessages(): Array<MessageObj> {
		return this.messages.map((ele) => ({
			currentUserMessage: ele.byCurrentUser,
			message: ele.message,
			messageId: ele.messageId,
			particientName: ele.sentBy,
			roomName: ele.roomName,
		}));
	}

	get roomsWhereMicIsInUse(): Array<string> {
		return Array.from(this.roomsUsingMic);
	}

	async sendMessage(message: string): Promise<void> {
		const roomsArray = Array.from(this.roomMap);
		this.messages.push({
			byCurrentUser: true,
			message,
			messageId: crypto.randomUUID(),
			sentBy: 'CurrentUser',
			roomName: 'all',
			sentAt: new Date(),
		});
		this.emit('newMessageReceived');
		// eslint-disable-next-line no-restricted-syntax
		for (const [_, room] of roomsArray) {
			try {
				// eslint-disable-next-line no-await-in-loop
				await room.sendMessage(message);
			} catch (error) {
				logger.error(error);
			}
		}
	}

	setWebCamStream(webCamStream: MediaStreamTrack | null): void {
		this.WebCamStream = webCamStream;
		const result = Array.from(this.roomMap);
		result.forEach(([roomName, room]) => {
			room.setWebCamStream(webCamStream);
		});
	}

	setAudioStream(audioStream: MediaStreamTrack | null): void {
		this.AudioStream = audioStream;
		const result = Array.from(this.roomMap);
		result.forEach(async ([roomName, room]) => {
			if (this.AudioStream) {
				try {
					await room.setAudioStream(audioStream);
					return;
				} catch (error) {
					console.error(error);
				}
			}
		});
	}

	private handleWebCamState(roomName: string, value: boolean) {
		const method = value ? 'add' : 'delete';
		this.roomUsingWebcam[method](roomName);
		this.emit('webCamStateChanged');
	}

	private handleMicState(roomName: string, value: boolean) {
		const method = value ? 'add' : 'delete';
		this.roomsUsingMic[method](roomName);
		this.emit('micStateChanged');
	}

	private handleLiveStreamRoomStateChange(roomName: string, value: boolean) {
		const room = this.roomMap.get(roomName);
		if (!room) {
			this.activeRoomSet.delete(roomName);
			logger.info('Something went wrong');
			return;
		}
		if (value) {
			this.activeRoomSet.add(roomName);
			return;
		}
		this.activeRoomSet.delete(roomName);
		this.roomMap.delete(roomName);
		this.roomUsingWebcam.delete(roomName);
		this.roomUsingWebcam.delete(roomName);
		this.emit('webCamStateChanged');
		this.emit('micStateChanged');
		this.emit('roomStateChanged');
	}

	addNewStream(room: LiveStream): void {
		this.roomMap.set(room.room, room);
		room.setAudioStream(this.AudioStream);
		room.setWebCamStream(this.WebCamStream);
		room.on('messageReceieved', (data) => {
			this.messages.push({
				messageId: data.messageId,
				roomName: room.room,
				message: data.message,
				byCurrentUser: data.byCurrentUser,
				sentAt: data.sentAt,
				sentBy: data.sentBy,
			});
			this.emit('newMessageReceived');
		});
		room.on('streamStatusChange', (value) => {
			this.handleLiveStreamRoomStateChange(room.room, value);
		});
		room.on('micStatusChanged', (value) => {
			this.handleMicState(room.room, value);
		});
		room.on('webCamStatusChange', (value) => {
			this.handleWebCamState(room.room, value);
		});
	}
}
