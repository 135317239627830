import create, { UseStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import { CQQuizClient } from '../../clients';
import {
	QuestionExecutionType, QuestionStatus, QuestionType, ScreenShareState, TestCaseStatus,
} from '../../config';
import { logger } from '../../libs/utils/logger';
import { immer } from '../middleware';
import { UploadQueue } from '../uploadHander';

enum QuizAction {
	SocketDisconnected = 'socket-disconnected',

	QuizStarted = 'quiz-started',
	QuizUpdated = 'quiz-updated',
	QuizDataReceived = 'quiz-data-received',
	QuizDashboardDataReceived = 'quiz-dashboard-data-received',
	QuizSegmentSubmitted = 'quiz-segment-submitted',
	QuizSubmitted = 'quiz-submitted',

	AttemptQuestionDataReceived = 'attempt-question-data-received',
	AttemptQuestionDataRemoved = 'attempt-question-data-removed',
	TestCaseStatusUpdated = 'test-case-status-updated',

	SetQuizTimeLeft = 'set-quiz-time-left',
}

const initialState: QuizState = {
	serverClientTimeOffset: null,
	recording: false,
	quizName: null,
	nextLink: null,
	previousLink: null,
	submittedQuestions: [],
	lastUpdatedAt: null,
	compiling: false,
	compilingCodeId: null,
	compilingQuestionId: null,
	isSubmitted: false,
	submittingQuestion: false,
	hasStarted: false,
	timeLeft: Infinity,
	tabSwitchCount: 0,
	tabSwitchInCount: 0,
	fullScreenInCount: 0,
	fullScreenOutCount: 0,
	quizData: null,
	quizDashboardData: null,
	attemptQuestionData: null,
	serverTime: null,
	tabSwitchCountChangeLocal: 0,
	uploadingAllRecording: false,
	liveStreamRoomName: [],
	isQuizSubmitted: false,
	isAiProctorEnabled: true,
	isRecordingEnabledFromConfig: true,
	logoutMessage: '',
	screenShareStream: null,

	setScreenShareStream: () => { },
	setLogoutMessage: () => { },
	userRemovedFromRoom: () => { },
	incrementByOneSecServerTime: () => { },
	getServerTime: async () => { },
	incrementLocalTabCount: () => { },
	getQuizData: async () => { },
	startQuiz: async () => { },
	getQuizDashboardData: async () => { },
	getAttemptQuestionData: async () => { },
	getRemainingTime: async () => { },
	removeAttemptQuestionData: () => { },
	submitQuizSegment: async () => { },
	submitQuiz: async () => { },
	submitQuestion: async () => { },

	setQuizTimeLeft: async () => { },

	compileCode: async () => { },
	setQuestionTestCaseStatus: async () => { },
	sendImageData: async () => { },
	startRecording: () => { },
	stopRecording: async () => { },

	sendCheatingDetails: async () => { },
	validateToken: async () => false,
	getLiveStreamRoom: async () => [],
	getJitsiCredentials: async () => { },
	validateEmail: async (data) => { },
	updateJitsiCredentials: async () => { },
	sendButtonSubmissionEvent: (data) => { },
	uploadEvent: async (data) => { },
	uploadErrorEvent: async (data) => { },
};

export function createQuizStore(
	cqQuizClient: CQQuizClient,
	uploadQueue: UploadQueue,
): UseStore<QuizState> {
	return create<QuizState>(immer(devtools((set, get) => {
		cqQuizClient.on('socket-disconnected', async () => {
			logger.log('socket disconnected');

			set((state) => {
				const { compilingQuestionId, attemptQuestionData } = state;
				if (compilingQuestionId === attemptQuestionData?._id) {
					const questionData = attemptQuestionData.questionTypeCoding
						|| attemptQuestionData.questionTypeWeb;

					questionData?.testCases.forEach((testCase: any) => {
						// eslint-disable-next-line no-param-reassign
						testCase.status = TestCaseStatus.NotExecuted;
					});
				}

				// eslint-disable-next-line no-param-reassign
				state.compiling = false;
				// eslint-disable-next-line no-param-reassign
				state.compilingQuestionId = null;
			}, false, QuizAction.SocketDisconnected);
		});

		cqQuizClient.on('quiz-data-received', async (data) => {
			// logger.log('quiz data ===> ', data);

			set({
				quizData: data,
				timeLeft: data.quizTime * 60,	// from mins to secs
				lastUpdatedAt: data.updatedAt,
			}, false, QuizAction.QuizDataReceived);
		});

		cqQuizClient.on('quiz-started', async (data) => {
			logger.info('quiz started');
			logger.log('get remaing time...', data);
			set({
				hasStarted: true,
				timeLeft: data.remainingTime,
			}, false, QuizAction.QuizStarted);
		});

		cqQuizClient.on('quiz-remaining-time-updated', async (time) => {
			logger.info('quiz remaining time');

			set({
				timeLeft: time,
			}, false, QuizAction.QuizStarted);
		});

		cqQuizClient.on('quiz-suspicious-updated', async (data:any) => {
			console.log('quizzz data', data);
			set({
				tabSwitchCount: data?.tabSwitchCount || 0,
				tabSwitchInCount: data?.tabSwitchInCount || 0,
				fullScreenInCount: data?.fullScreenInCount || 0,
				fullScreenOutCount: data?.fullScreenOutCount || 0,
			}, false, QuizAction.QuizStarted);
		});

		cqQuizClient.on('quiz-updated', async (updatedAt) => {
			set((state) => {
				let { lastUpdatedAt } = state;

				if (lastUpdatedAt && new Date(lastUpdatedAt) < new Date(updatedAt)) {
					logger.info('quiz updated');
					state.getQuizDashboardData(1);
				}

				lastUpdatedAt = updatedAt;
			}, false, QuizAction.QuizUpdated);
		});

		cqQuizClient.on('app-config-change', (data) => {
			const quiz = get().quizData;
			if (quiz) {
				if (data.stopRecording !== undefined) {
					const { stopRecording } = data;
					set((state) => {
						// eslint-disable-next-line no-param-reassign
						state.isRecordingEnabledFromConfig = !stopRecording;
					});
				}
				if (data.stopAiProctoring !== undefined) {
					const { stopAiProctoring } = data;
					set((state) => {
						// eslint-disable-next-line no-param-reassign
						state.isAiProctorEnabled = !stopAiProctoring;
					});
				}
			}
			console.log('isAiProctoring Enabled', get().isAiProctorEnabled);
		});

		cqQuizClient.on('quiz-dashboard-data-received', async (data) => {
			// logger.log('quiz dashboard data ===> ', data);

			get().getRemainingTime();

			set((state) => {
				const { attemptQuestionData, quizData } = state;

				if (quizData) {
					quizData.revisitAllowed = data.quiz.revisitAllowed;
					quizData.isFullScreen = data.quiz.isFullScreen;
				}

				if (attemptQuestionData) {
					const index = data.dashboardData.questionIds.findIndex(
						(qId: string) => qId === attemptQuestionData._id,
					);

					// if (index < 0) {
					attemptQuestionData.hasBeenInvalidated = true;

					// eslint-disable-next-line no-param-reassign
					state.nextLink = `/test/${state.quizName}/dashboard`;
					// eslint-disable-next-line no-param-reassign
					state.previousLink = `/test/${state.quizName}/dashboard`;
					// }
				}

				// eslint-disable-next-line no-param-reassign
				state.quizDashboardData = data.dashboardData;
				// eslint-disable-next-line no-param-reassign
				state.lastUpdatedAt = data.quiz.updatedAt;
				// eslint-disable-next-line no-param-reassign
				state.submittedQuestions = data.submittedQuestions;
				// eslint-disable-next-line no-param-reassign
				state.hasStarted = data.hasQuizStarted;

				if (quizData) {
					quizData.title = data.quiz.title;
					quizData.questions = data.quiz.totalQuestions;
					quizData.quizUserDetails = data.quiz.quizUserDetails;
					quizData.copyPasteAllowed = data.quiz.copyPasteAllowed;
					quizData.tabSwitchAllowed = data.quiz.tabSwitchAllowed;
					quizData.isWebCamAllowed = !!data.quiz.isWebCamAllowed;
					quizData.isLiveStreamEnabled = (!!data.quiz.isWebCamAllowed)
					&& (!!data.quiz.isLiveStreamEnabled);
					quizData.isSubmitScreenShotEnabled = true;
					quizData.allowClose = data.quiz.allowClose ? (data.quiz.quizallowClose === 'true' || data.quiz.allowClose === true) : false;
				}
			}, false, QuizAction.QuizDashboardDataReceived);
		});

		cqQuizClient.on('quiz-segment-submitted', async (segmentIndex) => {
			// logger.log('quiz segment submitted, segment index ===> ', segmentIndex);

			set((state) => {
				const segment = state.quizDashboardData?.segments[segmentIndex];
				if (!segment) {
					return;
				}

				segment.isSubmitted = true;

				const nextSegment = state.quizDashboardData?.segments[segmentIndex + 1];
				if (!nextSegment) {
					return;
				}

				nextSegment.isDisabled = false;
			}, false, QuizAction.QuizSegmentSubmitted);
		});

		cqQuizClient.on('section-update', async () => {
			get().getQuizDashboardData(1);
		});

		cqQuizClient.on('quiz-submitted', async () => {
			logger.log('quiz submitted');

			set({
				isSubmitted: true,
			}, false, QuizAction.QuizDashboardDataReceived);
		});

		cqQuizClient.on('attempt-question-data-received', async (data) => {
			// logger.log('attempt question data ===> ', data);

			set((state) => {
				// eslint-disable-next-line no-param-reassign
				state.attemptQuestionData = data;

				let segIndex = -1;
				let questionIndex = -1;
				const segments = state.quizDashboardData?.segments;
				if (!segments) {
					return;
				}

				for (let i = 0; i < segments.length; i += 1) {
					const qIndex = segments[i].questions.findIndex((q) => q._id === data._id);
					if (qIndex >= 0) {
						segIndex = i;
						questionIndex = qIndex;
						break;
					}
				}

				if (!state.quizData?.revisitAllowed) {
					if (segments[segIndex]?.isSubmitted) {
						// eslint-disable-next-line no-param-reassign
						state.attemptQuestionData.isSegmentSubmitted = true;
						// eslint-disable-next-line no-param-reassign
						state.nextLink = `/test/${state.quizName}/dashboard`;
						// eslint-disable-next-line no-param-reassign
						state.previousLink = `/test/${state.quizName}/dashboard`;

						return;
					}
				}

				const nextQuestion = segments[segIndex]?.questions[questionIndex + 1];
				if (nextQuestion) {
					// eslint-disable-next-line no-param-reassign
					state.nextLink = `/test/${state.quizName}/attempt/${nextQuestion._id}`;
				} else {
					// eslint-disable-next-line no-param-reassign
					state.nextLink = `/test/${state.quizName}/dashboard`;
				}

				const prevQuestion = segments[segIndex]?.questions[questionIndex - 1];
				if (prevQuestion) {
					// eslint-disable-next-line no-param-reassign
					state.previousLink = `/test/${state.quizName}/attempt/${prevQuestion._id}`;
				} else {
					// eslint-disable-next-line no-param-reassign
					state.previousLink = `/test/${state.quizName}/dashboard`;
				}

				if (+state.attemptQuestionData.type === QuestionType.Web) {
					// escape script tag
					const questionData = state.attemptQuestionData;

					// HTML
					if (questionData.questionTypeWeb?.lastSubmitted?.html) {
						questionData.questionTypeWeb.lastSubmitted.html = questionData.questionTypeWeb.lastSubmitted.html.replace(/<\\\/script>/ig, '</script>');
					} else if (questionData.questionTypeWeb?.html) {
						questionData.questionTypeWeb.html = questionData.questionTypeWeb.html.replace(/<\\\/script>/ig, '</script>');
					}

					// CSS
					if (questionData.questionTypeWeb?.lastSubmitted?.css) {
						questionData.questionTypeWeb.lastSubmitted.css = questionData.questionTypeWeb.lastSubmitted.css.replace(/<\\\/script>/ig, '</script>');
					} else if (questionData.questionTypeWeb?.css) {
						questionData.questionTypeWeb.css = questionData.questionTypeWeb.css.replace(/<\\\/script>/ig, '</script>');
					}

					// JS
					if (questionData.questionTypeWeb?.lastSubmitted?.js) {
						questionData.questionTypeWeb.lastSubmitted.js = questionData.questionTypeWeb.lastSubmitted.js.replace(/<\\\/script>/ig, '</script>');
					} else if (questionData.questionTypeWeb?.js) {
						questionData.questionTypeWeb.js = questionData.questionTypeWeb.js.replace(/<\\\/script>/ig, '</script>');
					}

					// eslint-disable-next-line no-param-reassign
					state.attemptQuestionData = questionData;
				}
			}, false, QuizAction.AttemptQuestionDataReceived);
		});

		cqQuizClient.on('compile-result', async (data) => {
			logger.log('compile data ===> ', data);

			set((state) => {
				const {
					attemptQuestionData, quizDashboardData, compilingQuestionId,
					compilingCodeId,
				} = state;

				logger.log('CodeId:', compilingCodeId, 'data-CodeId:', data.codeId, 'compile match...', data.codeId === compilingCodeId);
				if (compilingCodeId && data.codeId !== compilingCodeId) {
					return;
				}
				if (compilingQuestionId === attemptQuestionData?._id) {
					if (attemptQuestionData?.questionTypeWeb) {
						const questionData = attemptQuestionData.questionTypeWeb;
						data.outputArray?.forEach((result: boolean, index: number) => {
							const testCase = questionData.testCases[index];
							if (testCase) {
								testCase.status = result
									? TestCaseStatus.Passed : TestCaseStatus.Failed;
							}
						});
					} else if (attemptQuestionData?.questionTypeCoding) {
						const questionData = attemptQuestionData.questionTypeCoding;
						if (data.outputArray && data.testCase) {
							data.testCase.forEach((tc: any, index: number) => {
								const testCase = questionData.testCases.find((t) => t._id === tc._id);
								if (testCase) {
									testCase.userOutput = data.outputArray[index].userOutput?.replace(/^\n|\n$/g, '');
									testCase.status = data.outputArray[index].testCasePassed
										? TestCaseStatus.Passed : TestCaseStatus.Failed;
								}
							});
						} else if (
							data.outputArray
							&& +attemptQuestionData.questionTypeCoding.executionType
							=== QuestionExecutionType.CompileOnly
						) {
							if (Array.isArray(data.outputArray) && data.outputArray.length) {
								questionData.consoleOutput = {
									output: data.output,
								};
								questionData.testCases.forEach((testCase) => {
									// eslint-disable-next-line no-param-reassign
									testCase.status = TestCaseStatus.Unknown;
								});
							} else {
								questionData.consoleOutput = {
									output: data.outputArray,
								};
								questionData.testCases.forEach((testCase) => {
									// eslint-disable-next-line no-param-reassign
									testCase.status = TestCaseStatus.Unknown;
								});
							}
						} else {
							questionData.consoleOutput = {
								error: data.errors,
								output: data.output,
							};
							questionData.testCases.forEach((testCase) => {
								// eslint-disable-next-line no-param-reassign
								testCase.status = TestCaseStatus.NotExecuted;
							});
						}
					}

					if (state.submittingQuestion) {
						// eslint-disable-next-line no-param-reassign
						state.submittingQuestion = false;

						quizDashboardData?.segments.forEach((segment) => {
							const question = segment.questions.find(
								(el) => el._id === attemptQuestionData?._id,
							);
							if (question) {
								question.status = QuestionStatus.Submitted;
							}
						});
					}
				}
				// eslint-disable-next-line no-param-reassign
				state.compilingCodeId = null;
				// eslint-disable-next-line no-param-reassign
				state.compiling = false;
				// eslint-disable-next-line no-param-reassign
				state.compilingQuestionId = null;
			}, false);
		});

		cqQuizClient.on('question-submitted', async (questionId) => {
			logger.log('question submission success!', questionId);

			set((state) => {
				const { quizDashboardData } = state;

				quizDashboardData?.segments.forEach((segment) => {
					const question = segment.questions.find(
						(el) => el._id === questionId,
					);
					if (question) {
						question.status = QuestionStatus.Submitted;
					}
				});

				// eslint-disable-next-line no-param-reassign
				state.submittingQuestion = false;
			});
		});

		cqQuizClient.on('server-time', async (date:any) => {
			const serverDateObj = new Date(date);
			set({
				serverClientTimeOffset: serverDateObj.getTime() - new Date().getTime(),
				serverTime: date,
			});
		});

		cqQuizClient.on('force-submit', async (type?: number) => {
			try {
				set((state) => {
					// eslint-disable-next-line no-param-reassign
					state.isQuizSubmitted = true;
				});
				const redirectLink = await get().submitQuiz({ isForceSubmit: true });
				const quizId = get().quizData?.quizId;
				if (quizId) {
					if (get().recording) {
						cqQuizClient.allowLogout(false);
						await get().stopRecording({ forceUpload: true }, quizId);
						cqQuizClient.allowLogout(true);
					}
				}
				if (redirectLink) {
					cqQuizClient.redirectToQuizClient(redirectLink);
				}
			} catch (error) {
				console.log(error);
			}
		});

		cqQuizClient.on('change-room', async (roomName?: string) => {
			// ? Not ussing roomName here.
			try {
				const { quizData } = get();
				if (quizData && quizData.isLiveStreamEnabled && quizData.isWebCamAllowed) {
					await get().getLiveStreamRoom();
				}
			} catch (error) {
				console.error(error);
			}
		});

		cqQuizClient.on('logout-user', async (config) => {
			try {
				set((state) => {
					// eslint-disable-next-line no-param-reassign
					state.isQuizSubmitted = true;
				});
				if (get().recording) {
					cqQuizClient.allowLogout(false);
					const quizId = get().quizData?.quizId;
					if (quizId) {
						await get().stopRecording({ forceUpload: true }, quizId);
					}
					cqQuizClient.allowLogout(true);
				}
				if (config.redirect) {
					window.location.href = config.redirect;
				} else if (config.reload) {
					window.location.reload();
				}
			} catch (error) {
				console.error(error);
			}
		});

		window.addEventListener('offline', () => {
			set({
				submittingQuestion: false,
				compiling: false,
				compilingQuestionId: null,
				compilingCodeId: null,
			});
		});

		return {
			...initialState,

			setLogoutMessage: (msg) => {
				if (msg) {
					set({
						logoutMessage: msg,
					});
				}
			},

			setQuizTimeLeft: async (time: number): Promise<void> => {
				set({
					timeLeft: time,
				}, false, QuizAction.SetQuizTimeLeft);
			},

			getQuizData: async (quizName: string): Promise<void> => {
				if (!quizName) {
					throw new Error('quiz name not present.');
				}

				set({
					quizName,
				});

				logger.info('getting quiz data...');
				await cqQuizClient.getQuizData(quizName);
			},

			getQuizDashboardData: async (isUpdate?: number): Promise<void> => {
				const { quizData } = get();

				if (!quizData?.quizId) {
					throw new Error('quiz id not present.');
				}

				logger.info('getting quiz dashboard data...');
				await cqQuizClient.getQuizDashboardData(quizData.quizId, isUpdate);
			},

			getAttemptQuestionData: async (questionId: string): Promise<void> => {
				const { quizData } = get();

				if (!questionId) {
					throw new Error('question id not present.');
				}

				if (!quizData?.quizId) {
					throw new Error('quiz id not present.');
				}

				logger.info('getting attempt question data...');
				await cqQuizClient.getAttemptQuestionData(quizData.quizId, questionId);
			},

			removeAttemptQuestionData: (): void => {
				logger.info('removing attempt question data...');

				set({
					attemptQuestionData: null,
					nextLink: null,
					previousLink: null,
					compiling: false,
				}, false, QuizAction.AttemptQuestionDataRemoved);
			},

			startQuiz: async (quizUserDetails?: any): Promise<void> => {
				const { quizData } = get();

				if (!quizData?.quizId) {
					throw new Error('quiz id not present.');
				}

				logger.info('starting test...');
				await cqQuizClient.startQuiz(quizData.quizId, quizUserDetails);
			},

			submitQuizSegment: async (segmentIndex: number, userDetails: {
				userId: string,
				sessionId: string,
			}, image: string): Promise<void> => {
				const { quizData, quizDashboardData } = get();

				if (!quizData?.quizId) {
					throw new Error('quiz id not present.');
				}

				if (!quizDashboardData?.segments[segmentIndex]) {
					throw new Error('quiz segment not present.');
				}

				let imageURL : string | null = null;

				if (image) {
					imageURL = await uploadQueue.upload({
						userId: userDetails.userId,
						quizId: quizData.quizId,
						image,
					});
				}

				logger.info('submitting quiz segment...');
				await cqQuizClient.submitQuizSegment(
					quizData.quizId,
					quizDashboardData.segments[segmentIndex].questions[0]?._id || 'placeholder-qId-for-empty-segment',
					segmentIndex,
					imageURL,
				);
			},

			submitQuiz: async (config): Promise<string | void> => {
				const { quizData } = get();

				if (!quizData?.quizId) {
					throw new Error('quiz id not present.');
				}
				let imageURL : string | undefined;
				if (config && 'submissionImage' in config && config.submissionImage) {
					const uploadedImageURL = await uploadQueue.uploadSync({
						userId: config.userDetails.userId,
						quizId: quizData.quizId,
						sessionId: config.userDetails.sessionId,
						image: config.submissionImage,
					});
					if (uploadedImageURL) {
						imageURL = uploadedImageURL;
					}
				}

				logger.info('submitting quiz...');
				const isAutoSubmit = ('isAutoSubmit' in config) ? config.isAutoSubmit : false;
				const isForceSubmit = ('isForceSubmit' in config) ? config.isForceSubmit : false;
				const redirectLink = await cqQuizClient.submitQuiz(
					quizData.quizId, isAutoSubmit, isForceSubmit, imageURL,
				);
				set((state) => {
					// eslint-disable-next-line no-param-reassign
					state.isQuizSubmitted = true;
				});
				if (get().recording) {
					cqQuizClient.allowLogout(false);
					await get().stopRecording({ forceUpload: true }, quizData.quizId);
					cqQuizClient.allowLogout(true);
				}
				logger.info('quiz submitted...');
				return redirectLink;
			},

			getRemainingTime: async (): Promise<void> => {
				const { quizData } = get();

				if (get().isQuizSubmitted) {
					return;
				}
				if (!quizData?.quizId) {
					throw new Error('quiz id not present.');
				}

				logger.info('getting quiz remaining time...');
				try {
					await cqQuizClient.getRemainingTime(quizData.quizId);
				} catch (e) {
					logger.error('could not get remaining time.');
				}
			},

			submitQuestion: async (data: any): Promise<void> => {
				const { quizData, attemptQuestionData } = get();

				if (!attemptQuestionData) {
					throw new Error('attempt question data not present.');
				}

				if (!quizData?.quizId) {
					throw new Error('quiz id not present.');
				}

				logger.info('submitting question...');
				set({
					submittingQuestion: true,
				});

				try {
					await cqQuizClient.submitQuestion(quizData.quizId, attemptQuestionData._id, data);

					set((state) => {
						const { submittedQuestions } = state;

						const index = submittedQuestions.findIndex((qId) => qId === attemptQuestionData._id);
						if (index < 0) {
							submittedQuestions.push(attemptQuestionData._id);
						}
					});
				} catch (e) {
					set({
						submittingQuestion: false,
					});

					throw e;
				}
			},

			compileCode: async (data: any): Promise<void> => {
				const {
					quizData, attemptQuestionData, compilingQuestionId, compiling, submittingQuestion,
				} = get();

				if (submittingQuestion) {
					throw new Error('question submission request sent already.');
				}

				if (!attemptQuestionData) {
					throw new Error('question data not present.');
				}

				if (compiling && compilingQuestionId === attemptQuestionData._id) {
					throw new Error('compile request sent already.');
				}

				if (!quizData?.quizId) {
					throw new Error('quiz id not present.');
				}

				const compileRequest: CodeCompilationRequest = {
					sid: data.sid,
					quizId: quizData.quizId,
					questionId: attemptQuestionData._id,
					executionType: attemptQuestionData.questionTypeCoding?.executionType,
					code: data.code,
					codeId: data.codeId,
					language: data.language,
					isCustomInput: data.isCustomInput,
					stdin: data.stdin,
					runSampleTC: data.runSampleTC,
					html: data.html,
					css: data.css,
					js: data.js,
					questionSubmission: data.questionSubmission,
				};

				logger.info('compiling code...');

				set((state) => {
					const { attemptQuestionData: questionData } = state;
					delete questionData?.questionTypeCoding?.consoleOutput;

					if (data.questionSubmission) {
						// eslint-disable-next-line no-param-reassign
						state.submittingQuestion = true;
					}
					if (data.codeId) {
						console.log('compilingCodeId...', data.codeId);
						// eslint-disable-next-line no-param-reassign
						state.compilingCodeId = data.codeId;
					}
					// eslint-disable-next-line no-param-reassign
					state.compiling = true;

					// eslint-disable-next-line no-param-reassign
					state.compilingQuestionId = attemptQuestionData._id;
				});
				try {
					await cqQuizClient.compileCode(compileRequest);

					if (data.questionSubmission) {
						set((state) => {
							const { submittedQuestions } = state;

							// eslint-disable-next-line no-param-reassign
							state.submittingQuestion = false;

							const index = submittedQuestions.findIndex((qId) => qId === attemptQuestionData._id);
							if (index < 0) {
								submittedQuestions.push(attemptQuestionData._id);
							}
						});
					}
				} catch (e) {
					set({
						submittingQuestion: false,
						compiling: false,
						compilingQuestionId: null,
						compilingCodeId: null,
					});

					throw e;
				}
			},

			setQuestionTestCaseStatus: async (
				testCaseIndex: number, status: TestCaseStatus,
			): Promise<void> => {
				set((state) => {
					const { attemptQuestionData } = state;
					if (attemptQuestionData?.questionTypeWeb?.testCases.length) {
						attemptQuestionData.questionTypeWeb.testCases[testCaseIndex].status = status;
					} else if (attemptQuestionData?.questionTypeCoding?.testCases.length) {
						attemptQuestionData.questionTypeCoding.testCases[testCaseIndex].status = status;
						if (
							[
								TestCaseStatus.Running,
								TestCaseStatus.Unknown,
								TestCaseStatus.NotExecuted,
							].includes(status)
						) {
							attemptQuestionData.questionTypeCoding.testCases[testCaseIndex].userOutput = '';
						}
					}
				}, false, QuizAction.TestCaseStatusUpdated);
			},

			sendImageData: async (
				userId: string, sessionId: string, imageData: unknown,
			): Promise<void> => {
				const { quizData } = get();
				if (!quizData?.quizId) {
					logger.error('invigilator error: quiz ID not found.');
					return;
				}

				await cqQuizClient.sendImageData(userId, quizData.quizId, sessionId, imageData);
			},

			sendCheatingDetails: async (
				cheatingType: number,
				tabSwitchType?: number,
				timeStamp?: number,
			): Promise<void> => {
				try {
					if (get().isQuizSubmitted) {
						return;
					}
					await cqQuizClient.sendCheatingDetails(cheatingType, tabSwitchType, timeStamp);
				} catch (e) {
					logger.error(e);
				}
			},

			getServerTime: async ():Promise<void> => {
				try {
					await cqQuizClient.getServerTime();
				} catch (err) {
					logger.error(err);
					// console.log('error');
				}
			},

			incrementByOneSecServerTime: ():void => {
				set((state) => {
					if (state.serverTime) {
						// eslint-disable-next-line no-param-reassign
						state.serverTime += 1000;
					}
				});
			},

			validateToken: (token): Promise<boolean> => cqQuizClient.validateToken(token),

			incrementLocalTabCount: (): void => {
				set((state) => {
					if (state.tabSwitchCountChangeLocal !== undefined) {
						// eslint-disable-next-line no-param-reassign
						state.tabSwitchCountChangeLocal += 1;
					}
				});
			},

			getLiveStreamRoom: async () => {
				const roomName = await cqQuizClient.getLiveStreamRoom();
				set((state) => {
					// eslint-disable-next-line no-param-reassign
					state.liveStreamRoomName = roomName;
				});
				return roomName;
			},

			getJitsiCredentials: async (): Promise<void> => {
				try {
					localStorage.removeItem('id');
					localStorage.removeItem('callStatsUserName');
					localStorage.removeItem('jitsiMeetId');
					const result = await cqQuizClient.getJitsiCredentials();
					if (result) {
						Object.entries(result).forEach(([key, value]) => localStorage.setItem(key, value));
					}
				} catch (error) {
					console.error(error);
				}
			},

			updateJitsiCredentials: async () => {
				const data: JitsiCredentials = {
					id: '',
					callStatsUserName: localStorage.getItem('callStatsUserName') ?? '',
					jitsiMeetId: localStorage.getItem('jitsiMeetId') ?? '',
				};
				console.log('JITSI CONFIG:\t', JSON.stringify(data));
				cqQuizClient.updateJitsiCredentials(data);
			},

			validateEmail: async (data): Promise<void> => {
				await cqQuizClient.validateEmail(data);
			},

			sendButtonSubmissionEvent: (data) => {
				cqQuizClient.sendSubmissionLog(data);
			},
			uploadErrorEvent: async (data): Promise<void> => {
				cqQuizClient.uploadEvent(data);
			},

			uploadEvent: async (data): Promise<void> => {
				cqQuizClient.uploadEvent(data);
			},

			startRecording(quizId, userId) {
				if (
					'api' in window && window.api
					&& typeof window.api === 'object'
					&& 'record' in window.api
					&& typeof window.api.record === 'function'
				) {
					set((state) => {
						// eslint-disable-next-line no-param-reassign
						state.recording = true;
					});
					window.api.record(quizId, userId);
				}
			},

			async stopRecording(config, quizId, userId) {
				if (
					'api' in window && window.api
					&& typeof window.api === 'object'
					&& 'stopRecording' in window.api
					&& typeof window.api.stopRecording === 'function'
				) {
					if (!get().recording) {
						return;
					}
					if (config.forceUpload) {
						set((state) => {
							// eslint-disable-next-line no-param-reassign
							state.recording = false;
							// eslint-disable-next-line no-param-reassign
							state.uploadingAllRecording = true;
						});
					}
					const quiz = get().quizData;
					if (quiz?.isRecordingUploadBlockingEnabled) {
						const maxWait = quiz.maxWaitForRecordingToUpload;
						try {
							await new Promise((resolve, reject) => {
								let isResolved = false;
								const resolverFunction = (func, data) => {
									if (!isResolved) {
										isResolved = true;
										func(data);
									}
								};
								if (
									'api' in window && typeof window.api === 'object' && window.api
									&& 'stopRecording' in window.api && typeof window.api.stopRecording === 'function'
								) {
									window.api?.stopRecording(quizId, userId, config).then((() => {
										resolverFunction(resolve, null);
									})).catch((err) => {
										resolverFunction(reject, err);
									});
								} else {
									resolve(null);
									return;
								}
								setTimeout(() => {
									if (!isResolved) {
										reject(new Error('Timout Happened'));
										isResolved = true;
									}
								}, maxWait);
							});
						} catch (error) {
							console.error(error);
						}
					}
					set((state) => {
						// eslint-disable-next-line no-param-reassign
						state.uploadingAllRecording = false;
					});
				}
			},

			userRemovedFromRoom(data) {
				cqQuizClient.userRemovedFromRoom(data);
			},

			setScreenShareStream(stream) {
				get().screenShareStream?.stop();
				set({
					screenShareStream: stream,
				});
			},
		};
	}, 'QuizStore')));
}
