import { Button, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import React from 'react';
import './attempt-question-not-loaded.scope.css';

const { Title, Text } = Typography;

interface AttemptQuestionNotLoadedProps {
	errorMessage?: string;
	retryFunc: () => void;
}

export function AttemptQuestionNotLoaded(props: AttemptQuestionNotLoadedProps) {
	const { retryFunc } = props;
	return (
		<div className="attempt-question-error-main">
			<div>
				<Title
					level={2}
					style={{ marginBottom: '24px' }}
				>
					Oops! Something went wrong.
				</Title>
				<Text
					type="secondary"
					style={{ fontSize: '16px', marginBottom: '16px' }}
				>
					We couldn&apos;t load the question details. This may be due to:
				</Text>
				<ul className="attempt-question-eror-list">
					<li>Network issues</li>
					<li>Request timeout</li>
					{/* <li>Others</li> */}
				</ul>
				<div className="retry-button-container">
					<Button
						type="primary"
						onClick={retryFunc}
						size="large"
						style={{ width: 'fit-content', marginTop: '8px' }}
						icon={<ReloadOutlined />}
					>
						Retry
					</Button>
				</div>
			</div>
		</div>
	);
}
