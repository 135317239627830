import React from 'react';

import { APP_CONFIG, isElectronApp } from '../../../config';

import { ReactComponent as Error404 } from '../../../assets/img/404.svg';
import ChitkaraLogo from '../../../assets/img/chitkara_wide.png';
import './not-found.scoped.css';

const goBackURL = isElectronApp ? `${APP_CONFIG.QuizServerURL}/login` : APP_CONFIG.QuizServerURL;
export const NotFoundScreen: React.FunctionComponent = () => (
	<div className="not-found-wrapper">
		<div className="header">
			<div className="cq-logo">
				{!APP_CONFIG.isChitkara
					&& (
						<a href={APP_CONFIG.QuizServerURL}>
							<span>code</span>
							<span>quotient</span>
						</a>
					)}
				{APP_CONFIG.isChitkara
					&& (
						<a href={APP_CONFIG.QuizServerURL}>
							<img src={ChitkaraLogo} alt="Logo" height={50} />
						</a>
					)}
			</div>
		</div>
		<div className="not-found-container">
			<div className="left-pane">
				<div className="circle-artwork">
					<div className="circle-1" />
					<div className="circle-2" />
					<div className="circle-3" />
				</div>
				<div className="text-container">
					<h1>Uh oh!</h1>
					<p>
						Sorry, it seems we can’t find the page you’re looking for :(
					</p>
					<a href={goBackURL}>Go back to home</a>
				</div>
			</div>
			<div className="right-pane">
				<Error404 style={{ maxWidth: '100%' }} />
			</div>
		</div>
	</div>
);
