import { EventEmitter } from 'events';
import TypedEventEmitter from 'typed-emitter';

interface SpeedEvent {
	'result' : (data: NetworkStats | null) => void,
}

export default abstract class SpeedTest extends (
	EventEmitter as new () => TypedEventEmitter<SpeedEvent>
) {
	private freq: number;

	constructor(freq: number) {
		super();
		this.freq = freq * 1000;
	}

	abstract start(): void;

	abstract pause(): void;

	abstract networkStateChanged(status: number): void;

	abstract getCurrentNetworkStats(): NetworkStats | false;

	get frequncy() {
		return this.freq;
	}
}
