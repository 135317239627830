import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { AIProctor, State } from '../../libs/object-dection/object-detection';
import { immer } from '../middleware';
import { CQQuizClient } from '../../clients';
import { AiProctorCheatingSubType, CheatingType, APP_CONFIG } from '../../config';
import { UploadQueue } from '../uploadHander';

interface userDetails {
	'userId': string,
	'quizId': string,
	'sessionId': string,
}

const aiProctorConstants = {
	nextEventOffsetTimeInSecond: 30,
	maxWaitTimeForEventInSecond: 60 * 10,
	noUserEventOffsetTimeInSecond: 5 * 60,
};

export interface ProctorState {
	isProctoringStarted: boolean,
	noUserPresent: boolean,
	latestTimeNoUserEventSent?: number,
	aiProctoring: null | AIProctor,
	multipleUserFound: boolean,
	sendNextMultipleRequestTime: {
		multipleUser: number,
		objectDetection: number,
	},
	countOfContiguousNoUserPresentEvent: number,
	userDetails: userDetails,
	backPropagationCount: {
		multipleUser: number,
		objectDetection: number,
	},
	startProctoring: () => void,
	uploadImage: (base64: string) => Promise<string>,
	setAiProctoring: (proctor: AIProctor | null) => void
	setNoUserPresent: (result: boolean) => boolean
	handleAiProctoringResult: (result: DetectionResult) => Promise<void>,
	drawBoundariesInImage: (data: DetectionResult) => Promise<string>,
}

const initalState: ProctorState = {
	isProctoringStarted: false,
	noUserPresent: false,
	aiProctoring: null,
	multipleUserFound: true,
	countOfContiguousNoUserPresentEvent: 0,
	userDetails: { userId: '', quizId: '', sessionId: '' },
	sendNextMultipleRequestTime: {
		multipleUser: Date.now(),
		objectDetection: Date.now(),
	},
	backPropagationCount: {
		multipleUser: 1,
		objectDetection: 1,
	},
	startProctoring: () => {},
	uploadImage: async () => '',
	setNoUserPresent: (result: boolean) => false,
	setAiProctoring: (proctor: AIProctor | null) => {},
	handleAiProctoringResult: async (result: DetectionResult) => {},
	drawBoundariesInImage: async (data) => '',
};

interface BaseProctoringEvent {
	time: number,
	imageUrl: string,
}

interface ObjectDetectionEvent extends BaseProctoringEvent {
	objectsDetected: [string]
}

const illegalObjects = ['cell phone', 'mobile'];

export function createProctorStore(cqQuizClient: CQQuizClient, uploadQueue: UploadQueue) {
	return create<ProctorState>(immer(devtools((set, get) => {
		cqQuizClient.on('auth', async (data) => {
			set((state) => {
				// eslint-disable-next-line no-param-reassign
				state.userDetails.userId = data.userId;
				// eslint-disable-next-line no-param-reassign
				state.userDetails.sessionId = data.sessionId;
			});
		});

		cqQuizClient.on('quiz-data-received', async (quizData) => {
			set((state) => {
				// eslint-disable-next-line no-param-reassign
				state.userDetails.quizId = quizData.quizId;
			});
		});
		return {
			...initalState,
			startProctoring: () => {
				set((state) => {
					// eslint-disable-next-line no-param-reassign
					state.isProctoringStarted = true;
				});
			},
			uploadImage: async (base64: string) => {
				try {
					const userData = get().userDetails;
					if (!userData.userId && !userData.quizId) {
						return '';
					}
					const url = await uploadQueue.upload(
						{
							userId: userData.userId,
							quizId: userData.quizId,
							sessionId: userData.sessionId,
							image: base64,
						},
					);
					return url ?? '';
				} catch (error) {
					console.error(error);
					return '';
				}
			},

			setNoUserPresent(result: boolean) {
				let changeHappen = false;
				const previousResult = get().noUserPresent;
				if (previousResult !== result) changeHappen = true;

				if (changeHappen) {
					set((state) => {
						// eslint-disable-next-line no-param-reassign
						state.noUserPresent = result;
					});
				}
				return changeHappen;
			},
			setAiProctoring(proctor) {
				if (!proctor) {
					get().aiProctoring?.off('result', get().handleAiProctoringResult);
					get().aiProctoring?.destory();
					set((state) => {
						// eslint-disable-next-line no-param-reassign
						state.aiProctoring = null;
					});
					return;
				}
				set((state) => {
					// eslint-disable-next-line no-param-reassign
					state.aiProctoring = proctor;
					// eslint-disable-next-line no-param-reassign
					proctor.on('result', get().handleAiProctoringResult);
				});
			},

			async drawBoundariesInImage(data) {
				if (!data.imageBitMap) {
					return '';
				}
				const userDetectionLineColor = 'green';
				const objectDetectionLineColor = 'red';
				const canvas = new OffscreenCanvas(1080, 720);
				const context = canvas.getContext('2d');
				if (!context || !('drawImage' in context)) {
					return '';
				}
				context.drawImage(data.imageBitMap, 0, 0, 1080, 720);
				context.lineWidth = 10;
				if (APP_CONFIG.EnableProctorNotation) {
					if (data.faceDetection?.detections.length) {
						context.strokeStyle = userDetectionLineColor;
						data.faceDetection.detections.forEach((detection) => {
							if (detection.boundingBox) {
								context.strokeRect(
									detection.boundingBox.originX,
									detection.boundingBox.originY,
									detection.boundingBox.width,
									detection.boundingBox.height,
								);
							}
						});
					}
					if (data.objectDetection?.detections.length) {
						context.strokeStyle = objectDetectionLineColor;
						data.objectDetection.detections.forEach((detection) => {
							if (detection.boundingBox) {
								context.strokeRect(
									detection.boundingBox.originX,
									detection.boundingBox.originY,
									detection.boundingBox.width,
									detection.boundingBox.height,
								);
							}
						});
					}
				}
				if (!('convertToBlob' in canvas)) {
					return '';
				}
				if (typeof canvas.convertToBlob !== 'function') {
					return '';
				}
				const blob = await (canvas?.convertToBlob() as Promise<Blob>);
				const base64:string = await new Promise((resolve, reject) => {
					const fileReader = new FileReader();
					fileReader.readAsDataURL(blob);
					fileReader.addEventListener('error', (error) => {
						reject(error);
					});
					fileReader.addEventListener('loadend', () => {
						resolve((fileReader.result as string));
					});
				});
				if (!base64) {
					throw new Error('Unable To create base64 image');
				}
				return base64;
			},

			async handleAiProctoringResult(data) {
				try {
					if (!get().isProctoringStarted) {
						return;
					}
					const previousCountOfNoUserPresent = get().countOfContiguousNoUserPresentEvent;
					let uploadImage = false;
					let userDetected = false;
					let changeInUserDetectionState = false;
					let multipleUserFound = false;
					let illegalObjectFound: string[] = [];
					const { isUserPresent } = data;
					if (!isUserPresent) {
						if (previousCountOfNoUserPresent > 2) {
							changeInUserDetectionState = get().setNoUserPresent(true);
							if (changeInUserDetectionState) {
								uploadImage = true;
							}
						}
						const { latestTimeNoUserEventSent } = get();
						if (
							latestTimeNoUserEventSent
							&& (
								latestTimeNoUserEventSent
								+ aiProctorConstants.noUserEventOffsetTimeInSecond * 1000
							) < Date.now()
						) {
							uploadImage = true;
						}
						set((state) => {
							// eslint-disable-next-line no-param-reassign
							state.countOfContiguousNoUserPresentEvent += 1;
						});
					} else {
						set((state) => {
							// eslint-disable-next-line no-param-reassign
							state.countOfContiguousNoUserPresentEvent = 0;
						});
						changeInUserDetectionState = get().setNoUserPresent(false);
						if (changeInUserDetectionState) {
							uploadImage = true;
						}
						userDetected = true;
					}
					if ((data.faceDetection?.detections.length ?? 0) >= 2) {
						const lastMultipleUserFoundTime = get().sendNextMultipleRequestTime.multipleUser;
						if (lastMultipleUserFoundTime < Date.now()) {
							uploadImage = true;
							multipleUserFound = true;
							set((state) => {
								// eslint-disable-next-line no-param-reassign
								state.multipleUserFound = true;
								// eslint-disable-next-line no-param-reassign
								state.sendNextMultipleRequestTime.multipleUser = Math.min(
									Date.now()
										+ (state.backPropagationCount.multipleUser * 2)
										* aiProctorConstants.nextEventOffsetTimeInSecond * 1000,
									Date.now()
										+ aiProctorConstants.maxWaitTimeForEventInSecond * 1000,
								);
								// eslint-disable-next-line no-param-reassign
								state.backPropagationCount.multipleUser += 1;
							});
						}
					} else {
						set((state) => {
							// eslint-disable-next-line no-param-reassign
							state.backPropagationCount.multipleUser = 1;
						});
					}
					if (data.objectDetection?.detections) {
						data.objectDetection.detections.forEach((detection) => {
							detection.categories.forEach((category) => {
								if (category.score >= 0.7) {
									if (illegalObjects.includes(category.categoryName)) {
										illegalObjectFound.push(category.categoryName);
									}
								}
							});
						});
					}
					if (illegalObjectFound.length) {
						const lastObjectDetectionFoundTime = get().sendNextMultipleRequestTime.objectDetection;
						if (lastObjectDetectionFoundTime < Date.now()) {
							uploadImage = true;
							set((state) => {
								// eslint-disable-next-line no-param-reassign
								state.sendNextMultipleRequestTime.objectDetection = Math.min(
									Date.now()
										+ (state.backPropagationCount.objectDetection * 2)
										* aiProctorConstants.nextEventOffsetTimeInSecond * 1000,
									Date.now()
										+ aiProctorConstants.maxWaitTimeForEventInSecond * 1000,
								);
								// eslint-disable-next-line no-param-reassign
								state.backPropagationCount.objectDetection += 1;
							});
						} else {
							illegalObjectFound = [];
						}
					} else {
						set((state) => {
							// eslint-disable-next-line no-param-reassign
							state.backPropagationCount.objectDetection = 1;
						});
					}
					let uploadUrl: string | null = null;
					if (data.imageBitMap && uploadImage) {
						if (!data.drawnImage) {
							// eslint-disable-next-line no-param-reassign
							data.drawnImage = await get().drawBoundariesInImage(data);
						}
						const url = await get().uploadImage(data.drawnImage);
						if (url) {
							uploadUrl = url;
						}
						if (!url) {
							return;
						}
						if (userDetected && changeInUserDetectionState) {
							cqQuizClient.sendAiProctoringResult(CheatingType.AiProctor, {
								image: uploadUrl,
								subType: AiProctorCheatingSubType.userEnter,
							});
						}

						if (!userDetected && changeInUserDetectionState) {
							set((state) => {
								// eslint-disable-next-line no-param-reassign
								state.latestTimeNoUserEventSent = Date.now();
							});
							cqQuizClient.sendAiProctoringResult(CheatingType.AiProctor, {
								image: uploadUrl,
								subType: AiProctorCheatingSubType.userExit,
							});
						}

						if (multipleUserFound) {
							cqQuizClient.sendAiProctoringResult(CheatingType.AiProctor, {
								image: uploadUrl,
								subType: AiProctorCheatingSubType.multipleUserDetected,
							});
						}

						if (illegalObjectFound.length) {
							cqQuizClient.sendAiProctoringResult(CheatingType.AiProctor, {
								image: uploadUrl,
								illegalObjects: illegalObjectFound,
								subType: AiProctorCheatingSubType.illegalObject,
							});
						}
					}
				} catch (error) {
					console.trace(error);
				}
			},
		};
	})));
}
