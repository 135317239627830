import React, {
	ChangeEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import {
	Button, Input, message, Popconfirm, Select, Tabs, Tooltip,
} from 'antd';
import AceEditor from 'react-ace';
import Split from 'react-split';
import uniqid from 'uniqid';

import './attempt-coding.scoped.css';
import {
	CaretDownFilled, CaretUpFilled, CheckOutlined, CloseOutlined,
	FullscreenExitOutlined,
	FullscreenOutlined, MinusCircleFilled, UndoOutlined, WarningFilled,
} from '@ant-design/icons';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import {
	EditorLanguageModes, QuestionExecutionType, SupportedLanguages, TestCaseStatus,
} from '../../config';
import { Editor } from '../editor';
import { logger } from '../../libs/utils/logger';

const { Option } = Select;
const { TabPane } = Tabs;

enum ConsoleTabKeys {
	TestCases = 'test-cases',
	ConsoleScreen = 'console-screen',
	CustomInput = 'custom-input',
	SampleTestCases = 'sample-test-cases',
}

interface AttemptCodingProps {
	questionData: AttemptQuestionData
	compiling: boolean
	submitting: boolean
	disableCopyPaste?: boolean
	compileCode: (data: any) => Promise<void>
	setTestCaseStatus: (testCaseIndex: number, status: TestCaseStatus) => Promise<void>
	setAttempt: (attemptData: unknown) => void
	getAttempt: () => any | null
	sendPasteNotification: () => void
}

interface pasteNotifcationIdInterface {
	id: NodeJS.Timeout | null;
}

export const AttemptCoding: React.FunctionComponent<AttemptCodingProps> = (props) => {
	const {
		questionData, compiling, submitting, disableCopyPaste,
		compileCode, setTestCaseStatus, setAttempt, getAttempt,
		sendPasteNotification,
	} = props;

	const editorWrapperRef: React.Ref<HTMLDivElement> = useRef(null);
	const editorRefMain: React.Ref<AceEditor> = useRef(null);

	const [consoleVisible, setConsoleVisible] = useState<boolean>(false);
	const [fullscreenEditor, setFullscreenEditor] = useState<boolean>(false);
	const [isSuggestionMoved, setIsSuggestionMoves] = useState<boolean>(false);

	const [headEditorValue, setHeadEditorValue] = useState<string>('');
	const [mainEditorValue, setMainEditorValue] = useState<string>('');
	const [tailEditorValue, setTailEditorValue] = useState<string>('');

	const [selectedLanguage, setSelectedLanguage] = useState<string>('');

	const [customInputAllowed, setCustomInputAllowed] = useState<boolean>(false);
	const [customInput, setCustomInput] = useState<string>('');

	const [consoleActiveTab, setConsoleActiveTab] = useState<string>(ConsoleTabKeys.ConsoleScreen);

	const [selectedTestCase, setSelectedTestCase] = useState<CodingTestCaseMeta | undefined>();

	const [langDropdownOpen, setLangDropdownOpen] = useState<boolean>(false);

	const [textCopiedInCustomInput, setTextCopiedInCustomInput] = useState<string | null>(null);

	const minTimeStampForCodeSave = useRef<number | null>(null);

	const [clipBoardCopyKey, setClipBoardCopyKey] = useState<string | null>(null);

	const [
		selectedSampleTestCase, setSelectedSampleTestCase,
	] = useState<CodingTestCaseMeta | undefined>();

	const customInputRef = useRef<TextAreaRef>(null);

	const pasteNotificationId = useRef<pasteNotifcationIdInterface>({ id: null });

	useEffect(() => {
		if (questionData.questionTypeCoding?.consoleOutput
			|| (
				questionData.questionTypeCoding?.executionType
				&& +questionData.questionTypeCoding?.executionType
				=== QuestionExecutionType.CompileOnly
			)) {
			setConsoleActiveTab(ConsoleTabKeys.ConsoleScreen);
		}
	}, [questionData]);

	const toggleEditorFullscreen = useCallback(() => {
		setFullscreenEditor(!fullscreenEditor);
	}, [fullscreenEditor]);

	const onEditorFullscreenError = useCallback(() => message.error('Error while going to the full-screen editor mode.'), []);

	useEffect(() => {
		const editor = editorWrapperRef.current;

		editor?.addEventListener('fullscreenchange', toggleEditorFullscreen);
		editor?.addEventListener('fullscreenerror', onEditorFullscreenError);

		return () => {
			editor?.removeEventListener('fullscreenchange', toggleEditorFullscreen);
			editor?.removeEventListener('fullscreenerror', onEditorFullscreenError);
		};
	}, [toggleEditorFullscreen, onEditorFullscreenError]);

	const saveAttempt = useCallback(() => {
		const data = getAttempt() ?? [];
		const index = data.findIndex((el: any) => el.language === selectedLanguage);

		if (index >= 0) {
			data[index] = {
				code: mainEditorValue,
				language: selectedLanguage,
			};
		} else {
			data.push({
				code: mainEditorValue,
				language: selectedLanguage,
			});
		}
		setAttempt(data);
	}, [mainEditorValue, selectedLanguage, setAttempt, getAttempt]);

	useEffect(() => {
		const intervalId = setInterval(saveAttempt, 1000);
		const currentTimeStamp = Date.now();
		if (!minTimeStampForCodeSave.current) {
			minTimeStampForCodeSave.current = Date.now() + 5000;
		}
		if (minTimeStampForCodeSave.current < currentTimeStamp) {
			clearInterval(intervalId);
			minTimeStampForCodeSave.current = null;
			saveAttempt();
		}
		return () => {
			clearInterval(intervalId);
			minTimeStampForCodeSave.current = null;
		};
	}, [saveAttempt]);

	const pasteHandler = useCallback((e: any) => {
		// console.log('changeeeee', e);
		if (e.action === 'insert') {
			let isInvalid = e?.lines?.length > 3;
			e.lines.map((line: any) => {
				if (!isInvalid && line?.trim()?.split(' ').length > 1) {
					isInvalid = true;
				}
				return true;
			});
			if (isInvalid) {
				// alert('invalid');
				// console.log('=================INVALIIDDD LOGOUT=============');
				message.error('Attention: Please do not use copy paste as it may result in disqualification.');
				sendPasteNotification?.();
				// e.action = 'remove';
				// editorRefMain.current?.editor.getSession().getDocument().applyDelta(e);
				editorRefMain.current?.editor.session.getUndoManager()?.undo(
					editorRefMain.current?.editor.getSession(),
				);
				editorRefMain.current?.editor.session.getUndoManager().reset();
			}
		}
	}, [sendPasteNotification]);

	const handleLanguageChange = useCallback((language: string) => {
		const editor = editorRefMain?.current?.editor;
		if (disableCopyPaste) {
			editor?.off('change', pasteHandler);
		}
		setSelectedLanguage(language);

		const languageData = questionData.questionTypeCoding?.codeLanguages?.find(
			(el) => el.language === language,
		);
		if (languageData) {
			const { head, body, tail } = languageData.codeComponents;

			const attemptData = getAttempt();

			setHeadEditorValue(head);
			console.log('AttemptData: ', attemptData);
			console.log('Last Attempt:', questionData.questionTypeCoding?.lastSubmitted?.language === language);
			setMainEditorValue(
				((Array.isArray(attemptData) && attemptData) || [])?.find(
					(el: any) => el.language === language,
				)?.code
				?? (
					questionData.questionTypeCoding?.lastSubmitted?.language === language
						? questionData.questionTypeCoding?.lastSubmitted?.code
						: null
				) ?? body,
			);
			setTailEditorValue(tail);
		} else {
			console.log('this language data not found');
			if (questionData.questionTypeCoding?.codeLanguages?.[0].language) {
				handleLanguageChange(questionData.questionTypeCoding?.codeLanguages?.[0].language);
			}
		}

		/* TODO: need to change logic. only for setTimeout.
		 we have to find something that runs after lang fully changed */
		if (disableCopyPaste) {
			setTimeout(() => {
				editor?.on('change', pasteHandler);
			}, 1000);
		}
	}, [
		disableCopyPaste, questionData.questionTypeCoding?.codeLanguages,
		questionData.questionTypeCoding?.lastSubmitted?.language,
		questionData.questionTypeCoding?.lastSubmitted?.code, pasteHandler, getAttempt,
	]);

	const resetCode = useCallback(() => {
		if (disableCopyPaste) {
			editorRefMain?.current?.editor?.off('change', pasteHandler);
		}
		const languageData = questionData.questionTypeCoding?.codeLanguages?.find(
			(el) => el.language === selectedLanguage,
		);
		if (languageData) {
			const { head, body, tail } = languageData.codeComponents;

			setHeadEditorValue(head);
			setMainEditorValue(
				body,
			);
			setTailEditorValue(tail);
		}
		if (disableCopyPaste) {
			setTimeout(() => {
				editorRefMain?.current?.editor?.on('change', pasteHandler);
			}, 1000);
		}
	}, [selectedLanguage, questionData, disableCopyPaste, pasteHandler]);

	useEffect(() => {
		let currentEditorLang = window.sessionStorage.getItem(`currentEditorLang:${questionData._id}`) || '';
		if (currentEditorLang) {
			let checkLang = false;
			questionData.questionTypeCoding?.codeLanguages?.forEach((language) => {
				if (language.language === currentEditorLang) checkLang = true;
			});

			if (!checkLang) currentEditorLang = '';
		}
		const language = questionData.questionTypeCoding?.lastSubmitted?.language
			|| currentEditorLang
			|| questionData.questionTypeCoding?.codeLanguages?.[0].language;

		if (language) {
			handleLanguageChange(language);
		}
	}, [
		questionData._id,
		questionData.questionTypeCoding?.lastSubmitted?.language,
		questionData.questionTypeCoding?.codeLanguages, handleLanguageChange,
	]);

	const toggleConsoleVisible = useCallback(() => {
		setConsoleVisible(!consoleVisible);
	}, [consoleVisible, setConsoleVisible]);

	const handleRunCode = useCallback(async () => {
		if (!navigator.onLine) {
			message.error('Please check your internet connection.');
			return;
		}
		setSelectedTestCase(undefined);
		setSelectedSampleTestCase(undefined);

		const runSampleTests = consoleActiveTab === ConsoleTabKeys.SampleTestCases;

		if (!customInputAllowed) {
			questionData.questionTypeCoding?.testCases.forEach(async (testCase, index) => {
				if (runSampleTests) {
					if (testCase.sampleTest) {
						setTestCaseStatus(index, TestCaseStatus.Running);
					}
				} else {
					setTestCaseStatus(index, TestCaseStatus.Running);
				}
			});
			if (!runSampleTests) {
				setConsoleActiveTab(ConsoleTabKeys.TestCases);
			}
		}

		setConsoleVisible(true);
		// current language set during RunCode
		if (selectedLanguage) {
			window.sessionStorage.setItem(`currentEditorLang:${questionData._id}`, selectedLanguage);
		}

		try {
			await compileCode({
				code: mainEditorValue,
				codeId: uniqid(),
				isCustomInput: customInputAllowed,
				language: selectedLanguage,
				stdin: customInput,
				runSampleTC: runSampleTests,
			});
		} catch (e: any) {
			logger.error(e);

			message.error(e.message);
		}
	}, [
		questionData, selectedLanguage, customInput,
		consoleActiveTab, mainEditorValue, customInputAllowed,
		setTestCaseStatus, compileCode,
	]);

	const handleSubmitCode = useCallback(async () => {
		if (!navigator.onLine) {
			message.error('Please check your internet connection.');
			return;
		}
		questionData.questionTypeCoding?.testCases.forEach(async (testCase, index) => {
			setTestCaseStatus(index, TestCaseStatus.Running);
		});

		setCustomInputAllowed(false);

		if (
			questionData.questionTypeCoding?.executionType
			&& +questionData.questionTypeCoding?.executionType !== QuestionExecutionType.CompileOnly
		) {
			setConsoleActiveTab(ConsoleTabKeys.TestCases);
		} else {
			setConsoleActiveTab(ConsoleTabKeys.ConsoleScreen);
		}
		setConsoleVisible(true);

		try {
			await compileCode({
				questionSubmission: true,
				code: mainEditorValue,
				codeId: uniqid(),
				isCustomInput: false,
				language: selectedLanguage,
				stdin: '',
			});
		} catch (e: any) {
			logger.error(e);

			message.error(e.message);
		}
	}, [
		questionData.questionTypeCoding, selectedLanguage, mainEditorValue,
		compileCode, setTestCaseStatus,
	]);

	const handleCustomInputAllowed = useCallback((e: CheckboxChangeEvent) => {
		if (e.target.checked) {
			setConsoleVisible(true);
			setConsoleActiveTab(ConsoleTabKeys.CustomInput);
		} else if (
			questionData.questionTypeCoding?.executionType
			&& +questionData.questionTypeCoding?.executionType
			=== QuestionExecutionType.CompileOnly
		) {
			setConsoleActiveTab(ConsoleTabKeys.ConsoleScreen);
		} else {
			setConsoleActiveTab(ConsoleTabKeys.TestCases);
		}
		setCustomInputAllowed(e.target.checked);
	}, [questionData.questionTypeCoding?.executionType]);

	const handleConsoleTabChanged = useCallback((activeKey: string) => {
		if (
			(
				consoleActiveTab === ConsoleTabKeys.TestCases
				&& activeKey === ConsoleTabKeys.SampleTestCases
			) || (
				consoleActiveTab === ConsoleTabKeys.SampleTestCases
				&& activeKey === ConsoleTabKeys.TestCases
			) || (
				1 // reseting all execution on tab switch
			)
		) {
			questionData.questionTypeCoding?.testCases.forEach(async (testCase, index) => {
				setTestCaseStatus(index, TestCaseStatus.NotExecuted);
			});
		}

		setConsoleActiveTab(activeKey);

		if (activeKey === ConsoleTabKeys.TestCases && !selectedTestCase) {
			const testCase = questionData.questionTypeCoding?.testCases[0];
			if (testCase) {
				setSelectedTestCase(testCase);
			}
		} else if (activeKey === ConsoleTabKeys.SampleTestCases && !selectedSampleTestCase) {
			const testCase = questionData.questionTypeCoding?.testCases.filter((el) => el.sampleTest)[0];
			if (testCase) {
				setSelectedSampleTestCase(testCase);
			}
		}
	}, [
		questionData.questionTypeCoding?.testCases, selectedTestCase,
		selectedSampleTestCase, consoleActiveTab, setTestCaseStatus,
	]);

	const getMinLines = useCallback((isFullScreen: boolean) => {
		let minLines = 0;
		if (isFullScreen) {
			// minLines = total screen height - something -
			// lang select header divide by per line height of editor
			minLines = ((window.innerHeight - 12 - 40) / 17);
		} else {
			// minLines = total screen height - something - header fix height -
			// footer fix height - lang select header divide by per line height of editor
			minLines = ((window.innerHeight - 12 - 55 - 50 - 40) / 17);
		}

		return minLines;
	}, []);

	useEffect(() => {
		const testCase = questionData.questionTypeCoding?.testCases[0];
		if (testCase) {
			setSelectedTestCase(testCase);
		}

		const sampleTestCase = questionData.questionTypeCoding?.testCases
			.filter((el) => el.sampleTest)[0];
		if (sampleTestCase) {
			setSelectedSampleTestCase(sampleTestCase);
		}
	}, [questionData.questionTypeCoding?.testCases]);

	const countWords = useCallback((str: string) => {
		// Remove leading and trailing white spaces
		// eslint-disable-next-line no-param-reassign
		str = str.trim();

		// Split the string into an array of words
		const words = str.split(/\s+/);

		// Return the number of words
		return words.length;
	}, []);

	const handleCustomInput = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
		if (disableCopyPaste) {
			const currentValue = e.target.value;
			const addedText = currentValue.substring(customInput.length);
			const addedWordCount = addedText.length;
			if (addedWordCount > 4) {
				message.error('Attention: Please do not use copy paste as it may result in disqualification.');
			} else {
				setCustomInput(e.target.value);
			}
		} else {
			setCustomInput(e.target.value);
		}
	}, [customInput, disableCopyPaste]);

	const handleCopyInCustomInput = useCallback((ev) => {
		const { target } = ev;
		const element = target as HTMLTextAreaElement;
		const value = element.value.substring(
			element.selectionStart, element.selectionEnd,
		);
		setTextCopiedInCustomInput(value);
		ev.preventDefault();
		const key = crypto.randomUUID();
		setClipBoardCopyKey(key);
		navigator.clipboard.writeText(key);
	}, []);

	const moveSuggestion = useCallback(() => {
		if (document.getElementsByClassName('ace_autocomplete')[0] && !isSuggestionMoved) {
			editorWrapperRef.current?.appendChild(document.getElementsByClassName('ace_autocomplete')[0]);
			setIsSuggestionMoves(true);
		}
	}, [isSuggestionMoved]);

	const stopTestCase = useCallback(() => {
		const runSampleTests = consoleActiveTab === ConsoleTabKeys.SampleTestCases;
		if (!customInputAllowed) {
			questionData.questionTypeCoding?.testCases.forEach(async (testCase, index) => {
				if (runSampleTests) {
					if (testCase.sampleTest) {
						setTestCaseStatus(index, TestCaseStatus.NotExecuted);
					}
				} else {
					setTestCaseStatus(index, TestCaseStatus.NotExecuted);
				}
			});
		}
	}, [consoleActiveTab, customInputAllowed, questionData.questionTypeCoding, setTestCaseStatus]);

	useEffect(() => {
		const els = document.getElementsByClassName('test-case-preTag');
		const containers = document.getElementsByClassName('test-case-details-container');

		Array.prototype.forEach.call(els, (el: any) => {
			// eslint-disable-next-line no-param-reassign
			el.scrollLeft = 0;
		});

		Array.prototype.forEach.call(containers, (el: any) => {
			// eslint-disable-next-line no-param-reassign
			el.scrollTop = 0;
		});
	}, [selectedTestCase]);

	useEffect(() => {
		window.addEventListener('offline', stopTestCase);

		return () => {
			window.removeEventListener('offline', stopTestCase);
		};
	}, [stopTestCase]);

	const debouncedNotification = useCallback(() => {
		if (pasteNotificationId.current.id) {
			clearInterval(pasteNotificationId.current.id);
		}
		pasteNotificationId.current.id = setTimeout(sendPasteNotification, 505);
	}, [sendPasteNotification]);

	return (
		<div className="attempt-coding-container">
			<Split
				className="main"
				sizes={consoleVisible ? [65, 35] : [100, 0]}
				minSize={consoleVisible ? 150 : 0}
				gutterSize={consoleVisible ? 8 : 0}
				gutterStyle={(dimension, gutterSize) => {
					if (!consoleVisible) {
						return {
							display: 'none',
						};
					}

					return {
						display: 'flex',
						[dimension]: `${gutterSize}px`,
					};
				}}
				direction="vertical"
				style={{ maxHeight: 'calc(100vh - 106px)' }}
			>
				<div className="code-editor-wrapper" ref={editorWrapperRef}>
					<div className="editor-header">
						<div className="language-selector-container" style={{ position: 'relative' }}>
							<Select
								size="small"
								onDropdownVisibleChange={(el) => setLangDropdownOpen(el)}
								suffixIcon={langDropdownOpen ? <CaretUpFilled style={{ color: '#818181' }} /> : <CaretDownFilled style={{ color: '#818181' }} />}
								style={{ minWidth: 75, maxWidth: 200 }}
								value={selectedLanguage}
								disabled={compiling || submitting}
								onChange={handleLanguageChange}
								getPopupContainer={(node) => node?.parentElement?.parentElement ?? document.body}
							>
								{
									questionData.questionTypeCoding?.codeLanguages?.map((language) => (
										<Option value={language.language} key={language._id}>
											{SupportedLanguages[+language.language]}
										</Option>
									))
								}
							</Select>
						</div>

						<div className="editor-actions" style={{ position: 'relative' }}>
							{
								fullscreenEditor ? (
									<Tooltip
										title="exit fullscreen"
										placement="left"
										getPopupContainer={(node) => node?.parentElement ?? document.body}
									>
										<Button
											type="text"
											size="small"
											icon={<FullscreenExitOutlined />}
											onClick={() => document.exitFullscreen()}
										/>
									</Tooltip>
								) : (
									<Tooltip
										title="go to fullscreen"
										placement="left"
										getPopupContainer={(node) => node?.parentElement ?? document.body}
									>
										<Button
											type="text"
											size="small"
											icon={<FullscreenOutlined />}
											onClick={() => editorWrapperRef.current?.requestFullscreen()}
										/>
									</Tooltip>
								)
							}
						</div>
					</div>
					<div className="code-editor-container">
						{
							questionData.questionTypeCoding?.showHead
								&& headEditorValue?.trim()
								? (
									<div className="editor-head">
										<Editor
											disableCopyPaste={disableCopyPaste}
											mode={selectedLanguage ? EditorLanguageModes[+selectedLanguage] : 'text'}
											name="head"
											value={headEditorValue}
											readOnly
											maxLines={Infinity}
										/>
									</div>
								) : null
						}

						<div className="editor-main">
							<Editor
								disableCopyPaste={disableCopyPaste}
								style={{ flex: 'auto' }}
								name="main"
								mode={selectedLanguage ? EditorLanguageModes[+selectedLanguage] : 'text'}
								value={mainEditorValue}
								showLineNumbers
								minLines={getMinLines(fullscreenEditor)}
								maxLines={Infinity}
								notifyOnPaste
								onChange={(e) => {
									setMainEditorValue(e);
									moveSuggestion();
								}}
								readOnly={compiling || submitting}
								sendPasteNotification={sendPasteNotification}
								editorRefMain={editorRefMain}
								pasteHandler={pasteHandler}
								initalFocus
							/>
						</div>

						{
							questionData.questionTypeCoding?.showTail
								&& tailEditorValue?.trim()
								? (
									<div className="editor-tail">
										<Editor
											disableCopyPaste={disableCopyPaste}
											mode={selectedLanguage ? EditorLanguageModes[+selectedLanguage] : 'text'}
											name="tail"
											value={tailEditorValue}
											readOnly
											maxLines={Infinity}
										/>
									</div>
								) : null
						}
					</div>
				</div>
				{
					consoleVisible
						? (
							<div className="console-container">
								<Tabs
									type="card"
									size="small"
									activeKey={consoleActiveTab}
									onChange={handleConsoleTabChanged}
									tabBarExtraContent={{
										right: (
											<Button
												type="text"
												shape="circle"
												icon={<MinusCircleFilled style={{ color: 'var(--primary-color)', opacity: 0.75 }} />}
												onClick={toggleConsoleVisible}
											/>
										),
									}}
								>

									{
										questionData.questionTypeCoding?.executionType
										&& +questionData.questionTypeCoding?.executionType
										!== QuestionExecutionType.CompileOnly && (
											<TabPane
												tab={
													(() => {
														if (customInputAllowed) {
															return (
																<Tooltip
																	title="Disable custom input before running test cases."
																>
																	Test Cases
																</Tooltip>
															);
														}

														if (compiling) {
															return (
																<Tooltip title="Disabled during compiling">
																	Test Cases
																</Tooltip>
															);
														}

														return 'Test Cases';
													})()
													// customInputAllowed
													// 	? (
													// 		<Tooltip
													// 			title="Disable custom input before running test cases."
													// 		>
													// 			Test Cases
													// 		</Tooltip>
													// 	) : 'Test Cases'
												}
												disabled={customInputAllowed || compiling}
												key={ConsoleTabKeys.TestCases}
											>
												{
													(() => {
														// 	if (
														// 		questionData.questionTypeCoding?.executionType
														// && +questionData.questionTypeCoding?.executionType
														// === QuestionExecutionType.CompileOnly
														// 	) {
														// 		return (
														// 			<div className="test-cases-message-container">
														// 				<div style={{ padding: '1rem' }}>
														// 					<span style={{
														// 						padding: '1rem',
														// 						backgroundColor: '#f5f5f5',
														// 					}}
														// 					>
														// 						Compile Only.
														// 					</span>
														// 				</div>
														// 			</div>
														// 		);
														// 	}
														if (
															questionData.questionTypeCoding?.executionType
															&& +questionData.questionTypeCoding?.executionType
															=== QuestionExecutionType.HiddenTestCases
														) {
															return (
																<div className="hidden-test-cases-wrapper">
																	<div>
																		<Button className="hidden-test-case-btn" type="link" loading={compiling}>Hidden Test Cases</Button>
																		<span>
																			{
																				(() => {
																					if (compiling) {
																						return '';
																					}

																					let passed = 0;
																					let failed = 0;
																					questionData.questionTypeCoding.testCases
																						.forEach((testCase) => {
																							if (testCase.status === TestCaseStatus.Passed) {
																								passed += 1;
																							} else if (
																								testCase.status === TestCaseStatus.Failed
																							) {
																								failed += 1;
																							}
																						});

																					return `${passed} passed, ${failed} failed`;
																				})()
																			}
																		</span>
																	</div>
																	<div className="hidden-tests-explainer">
																		{/* <span>
																		Hidden test cases are the ones which can only be seen by&nbsp;
																		the students after successfully submitting the test
																	</span> */}
																		<span>
																			Test cases for this question are hidden and&nbsp;
																			can not be seen by the candidate.
																		</span>
																	</div>
																</div>
															);
														}

														if (
															questionData.questionTypeCoding?.executionType
															&& +questionData.questionTypeCoding?.executionType
															=== QuestionExecutionType.TestCasesWithResults
														) {
															return (
																<div className="test-cases-wrapper">
																	<div className="test-cases-container">
																		{
																			questionData.questionTypeCoding.testCases
																				.map((testCase, index) => (
																					<Button
																						className={selectedTestCase?._id === testCase._id ? 'active' : ''}
																						key={testCase._id}
																						type="text"
																						size="large"
																						icon={(() => {
																							if (testCase.status === TestCaseStatus.NotExecuted) {
																								return <WarningFilled style={{ color: '#fed639', fontSize: 18 }} />;
																							} if (testCase.status === TestCaseStatus.Passed) {
																								return <CheckOutlined style={{ color: 'green', fontSize: 18 }} />;
																							} if (testCase.status === TestCaseStatus.Failed) {
																								return <CloseOutlined style={{ color: 'red', fontSize: 18 }} />;
																							}
																							return null;
																						})()}
																						loading={testCase.status === TestCaseStatus.Running}
																						onClick={() => setSelectedTestCase(testCase)}
																					>
																						Test Case
																						{' '}
																						{index + 1}
																					</Button>
																				))
																		}
																	</div>
																	<div className="test-case-details-container">
																		<div>
																			{
																				selectedTestCase && (
																					<>
																						<span>Input Parameters:</span>
																						<pre className="test-case-preTag">
																							{selectedTestCase.input}
																						</pre>
																						<span>Expected Output:</span>
																						<pre className="test-case-preTag">
																							{selectedTestCase.expectedOutput}
																						</pre>
																						<span>Your Output:</span>
																						<pre className="test-case-preTag">
																							{selectedTestCase.userOutput}
																						</pre>
																					</>
																				)
																			}
																		</div>
																	</div>
																</div>
															);
														}

														return null;
													})()
												}
											</TabPane>
										)
									}

									<TabPane
										tab={
											(() => {
												if (compiling) {
													return (
														<Tooltip title="Disabled during compiling">
															Console
														</Tooltip>
													);
												}
												return 'Console';
											})()
										}
										key={ConsoleTabKeys.ConsoleScreen}
										disabled={compiling}
									>
										<div className="console-screen">
											<pre>
												{
													questionData.questionTypeCoding?.consoleOutput?.error
													|| questionData.questionTypeCoding?.consoleOutput?.output
												}
											</pre>
										</div>
									</TabPane>

									{
										customInputAllowed && (
											<TabPane tab="Custom Input" key={ConsoleTabKeys.CustomInput}>
												<div className="custom-input-container">
													<Input.TextArea
														ref={customInputRef}
														onChange={handleCustomInput}
														value={customInput}
														onCut={(ev) => {
															if (disableCopyPaste) {
																handleCopyInCustomInput(ev);
																const { target } = ev;
																const element = target as HTMLTextAreaElement;
																const newValue = customInput.substring(0, element.selectionStart)
																	+ customInput.substring(element.selectionEnd);
																setCustomInput(newValue);
															}
														}}
														onCopy={(ev) => {
															if (disableCopyPaste) {
																handleCopyInCustomInput(ev);
															}
														}}
														onPaste={(ev) => {
															if (disableCopyPaste) {
																const key = ev.clipboardData.getData('Text');
																const { target } = ev;
																const element = target as HTMLTextAreaElement;
																let value = customInput;
																if (key === clipBoardCopyKey) {
																	// Paste Text
																	value = value.substring(0, element.selectionStart)
																		+ (textCopiedInCustomInput ?? '') + value.substring(element.selectionEnd);
																	setCustomInput(value);
																} else {
																	// Send Notificaiton
																	message.error('Attention: Please do not use copy paste as it may result in disqualification.');
																	debouncedNotification();
																}
																ev.preventDefault();
															}
														}}
													/>
												</div>
											</TabPane>
										)
									}

									{
										questionData.questionTypeCoding?.testCases
											.filter((el) => el.sampleTest).length
											&& questionData.questionTypeCoding?.executionType
											&& +questionData.questionTypeCoding?.executionType
											!== QuestionExecutionType.CompileOnly
											? (
												<TabPane
													tab={
														(() => {
															if (customInputAllowed) {
																return (
																	<Tooltip
																		title="Disable custom input before running sample test cases."
																	>
																		Run Sample Test Cases
																	</Tooltip>
																);
															}
															if (compiling) {
																return (
																	<Tooltip title="Disabled during compiling">
																		Run Sample Test Cases
																	</Tooltip>
																);
															}
															return 'Run Sample Test Cases';
														})()
													}
													disabled={customInputAllowed || compiling}
													key={ConsoleTabKeys.SampleTestCases}
												>
													{/* {
													(() => {
														if (
															questionData.questionTypeCoding?.executionType
															&& +questionData.questionTypeCoding?.executionType
															=== QuestionExecutionType.TestCasesWithResults
														) {
															return (
																<div className="test-cases-wrapper">
																	<div className="test-cases-container">
																		{
																			questionData.questionTypeCoding.testCases
																				.filter((el) => el.sampleTest)
																				.map((testCase, index) => (
																					<Button
																						className={
																							selectedSampleTestCase?._id === testCase._id
																								? 'active'
																								: ''
																						}
																						key={testCase._id}
																						type="text"
																						size="large"
																						icon={(() => {
																							if (testCase.status === TestCaseStatus.NotExecuted) {
																								return (
																									<WarningFilled
																										style={{ color: '#fed639', fontSize: 18 }}
																									/>
																								);
																							} if (testCase.status === TestCaseStatus.Passed) {
																								return (
																									<CheckOutlined
																										style={{ color: 'green', fontSize: 18 }}
																									/>
																								);
																							} if (testCase.status === TestCaseStatus.Failed) {
																								return (
																									<CloseOutlined
																										style={{ color: 'red', fontSize: 18 }}
																									/>
																								);
																							}
																							return null;
																						})()}
																						loading={testCase.status === TestCaseStatus.Running}
																						onClick={() => setSelectedSampleTestCase(testCase)}
																					>
																						Test Case
																						{' '}
																						{index + 1}
																					</Button>
																				))
																		}
																	</div>
																	<div className="test-case-details-container">
																		{
																			selectedSampleTestCase && (
																				<>
																					<span>Expected Output:</span>
																					<pre>
																						{selectedSampleTestCase.expectedOutput}
																					</pre>
																					<span>Your Output:</span>
																					<pre>
																						{selectedSampleTestCase.userOutput}
																					</pre>
																				</>
																			)
																		}
																	</div>
																</div>
															);
														}

														return null;
													})()
												} */}
													<div className="test-cases-wrapper">
														<div className="test-cases-container">
															{
																questionData.questionTypeCoding.testCases
																	.filter((el) => el.sampleTest)
																	.map((testCase, index) => (
																		<Button
																			className={selectedSampleTestCase?._id === testCase._id ? 'active' : ''}
																			key={testCase._id}
																			type="text"
																			size="large"
																			icon={(() => {
																				if (testCase.status === TestCaseStatus.NotExecuted) {
																					return <WarningFilled style={{ color: '#fed639', fontSize: 18 }} />;
																				} if (testCase.status === TestCaseStatus.Passed) {
																					return <CheckOutlined style={{ color: 'green', fontSize: 18 }} />;
																				} if (testCase.status === TestCaseStatus.Failed) {
																					return <CloseOutlined style={{ color: 'red', fontSize: 18 }} />;
																				}
																				return null;
																			})()}
																			loading={testCase.status === TestCaseStatus.Running}
																			onClick={() => setSelectedSampleTestCase(testCase)}
																		>
																			Test Case
																			{' '}
																			{index + 1}
																		</Button>
																	))
															}
														</div>
														<div className="test-case-details-container">
															<div>
																{
																	selectedSampleTestCase && (
																		<>
																			<span>Input Parameters:</span>
																			<pre className="test-case-preTag">
																				{selectedSampleTestCase.input}
																			</pre>
																			<span>Expected Output:</span>
																			<pre className="test-case-preTag">
																				{selectedSampleTestCase.expectedOutput}
																			</pre>
																			<span>Your Output:</span>
																			<pre className="test-case-preTag">
																				{selectedSampleTestCase.userOutput}
																			</pre>
																		</>
																	)
																}
															</div>
														</div>
													</div>
												</TabPane>
											) : null
									}
								</Tabs>
							</div>
						) : (
							<div />
						)
				}
			</Split>
			<div className="footer">
				<div>
					<Button className={consoleVisible ? 'consoleBtn consoleOpened' : 'consoleBtn'} type="ghost" onClick={toggleConsoleVisible}>
						<span>Console</span>
						{
							consoleVisible
								? (
									<CaretDownFilled />
								) : (
									<CaretUpFilled />
								)
						}
					</Button>

					{
						questionData.questionTypeCoding?.showCustomInput && (
							<div style={{
								display: 'inline-flex',
								marginLeft: '1rem',
								fontWeight: 600,
								fontFamily: 'Hind, sans-serif',
							}}
							>
								<Checkbox
									checked={customInputAllowed}
									onChange={handleCustomInputAllowed}
									disabled={compiling}
								>
									{compiling ? (
										<Tooltip title="Disabled during compiling">
											custom input
										</Tooltip>
									) : 'custom input'}
								</Checkbox>
							</div>
						)
					}
				</div>
				<div>
					<Tooltip title="reset code" placement="left">
						<Popconfirm
							title="Do you really want to reset your code?"
							okText="yes"
							cancelText="no"
							onConfirm={resetCode}
							getPopupContainer={(trigger) => trigger.parentElement || document.body}
							disabled={compiling}
						>
							<Button
								type="text"
								shape="circle"
								disabled={compiling || submitting}
								icon={(
									<UndoOutlined />
								)}
							/>
						</Popconfirm>
					</Tooltip>
					<Button
						type="ghost"
						disabled={submitting}
						loading={compiling}
						onClick={handleRunCode}
					>
						run code
					</Button>
					<Button
						disabled={compiling}
						loading={submitting}
						type="primary"
						onClick={handleSubmitCode}
					>
						submit
					</Button>
				</div>
			</div>
		</div>
	);
};
