import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, message, Popconfirm } from 'antd';
import { useQuizStore } from '../../../store';

import './quiz-dashboard.scoped.css';
import { DashboardSegment } from '../../../components/dashboard-segment';
import { logger } from '../../../libs/utils/logger';
import { APP_CONFIG } from '../../../config';

const quizStoreSelector = (state: QuizState) => ({
	quizData: state.quizData,
	quizDashboardData: state.quizDashboardData,
	submitQuizSegment: state.submitQuizSegment,
	submitQuiz: state.submitQuiz,
	sendButtonSubmissionEvent: state.sendButtonSubmissionEvent,
});

interface QuizDashboardScreenProps {
	quizName: string
}

export const QuizDashboardScreen: React.FunctionComponent<QuizDashboardScreenProps> = (props) => {
	const { quizName } = props;
	const history = useHistory();

	const {
		quizData, quizDashboardData, submitQuiz, submitQuizSegment, sendButtonSubmissionEvent,
	} = useQuizStore(quizStoreSelector);

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const [showSectionSubmit, setShowSectionSubmit] = useState<boolean>(true);

	const handleSubmitQuiz = useCallback(async () => {
		try {
			setIsSubmitting(true);
			const redirectLink = await submitQuiz({});

			if (redirectLink) {
				history.push({
					state: { referrer: '' },
				});
				window.location.replace(`${APP_CONFIG.QuizServerURL}${redirectLink}`);
			}
		} catch (e: any) {
			logger.error(e);
			setIsSubmitting(false);

			message.error(e.message);
		}
	}, [history, submitQuiz]);

	useEffect(() => {
		if (quizData?.revisitAllowed) {
			setShowSectionSubmit(false);
		} else if (quizDashboardData && quizDashboardData?.segments.length <= 1) {
			setShowSectionSubmit(false);
		} else {
			setShowSectionSubmit(true);
		}
	}, [quizData, quizDashboardData]);

	return (
		<div className="quiz-dashboard-wrapper">
			{
				quizDashboardData?.segments.map((segment, index, segments) => (
					<DashboardSegment
						key={segment._id}
						quizName={quizName}
						segmentData={segment}
						segmentIndex={index}
						sendSubmitButtonEvent={sendButtonSubmissionEvent}
						onSubmit={submitQuizSegment}
						showSubmit={showSectionSubmit && index !== segments.length - 1}
					/>
				))
			}

			<div style={{ position: 'relative' }}>
				<Popconfirm
					title="Do you really want to submit the test?"
					onConfirm={handleSubmitQuiz}
					okText="yes"
					cancelText="no"
					getPopupContainer={(trigger) => trigger.parentElement || document.body}
				>
					<Button
						type="primary"
						size="large"
						loading={isSubmitting}
					>
						Submit Test
					</Button>
				</Popconfirm>
			</div>
		</div>
	);
};
