import React, { useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import styles from './font-change-slider.module.css';

interface FSProps {
	value: number,
	min: number,
	max: number,
	setValueOfSlider: (value: number) => void;
	style?: {
		[key: string]: string
	}
}

export const FontChangeSlider: React.FunctionComponent<FSProps> = (props: FSProps) => {
	const {
		min, max, setValueOfSlider, value,
	} = props;

	const handleChange = useCallback((inputValue: number) => {
		const timeout = setTimeout(() => {
			setValueOfSlider(inputValue);
		}, 20);
		return () => {
			clearTimeout(timeout);
		};
	}, [setValueOfSlider]);

	return (
		<div style={{ display: 'flex', flexGrow: '1', height: '100%' }}>
			<Tooltip title={`${value + 100}%`} trigger="hover">
				<input
					onMouseUp={(ev) => handleChange(parseInt(ev.currentTarget.value, 10))}
					// onInput={(event) => setValueOfSlider(parseInt(event.currentTarget.value, 10))}
					// value={value}
					type="range"
					min={min}
					max={max}
					className={styles.slider}
				/>
			</Tooltip>
		</div>
	);
};
