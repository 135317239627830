import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import html2canvas from 'html2canvas-pro';
import { Button, message, Popconfirm } from 'antd';
import { useAppStore, useQuizStore, useSessionStore } from '../../../store';

import './quiz-dashboard.scoped.css';
import { DashboardSegment } from '../../../components/dashboard-segment';
import { logger } from '../../../libs/utils/logger';
import { APP_CONFIG } from '../../../config';

const quizStoreSelector = (state: QuizState) => ({
	quizData: state.quizData,
	quizDashboardData: state.quizDashboardData,
	screenShareStream: state.screenShareStream,
	submitQuizSegment: state.submitQuizSegment,
	submitQuiz: state.submitQuiz,
	sendButtonSubmissionEvent: state.sendButtonSubmissionEvent,
});

const appStoreSelector = (state: AppState) => ({
	videoElement: state.videoElement,
});

const sessionStoreSelector = (state: SessionState) => ({
	sessionData: state.sessionData,
});

interface QuizDashboardScreenProps {
	quizName: string
}

export const QuizDashboardScreen: React.FunctionComponent<QuizDashboardScreenProps> = (props) => {
	const { quizName } = props;
	const history = useHistory();

	const {
		quizData, quizDashboardData, screenShareStream,
		submitQuiz, submitQuizSegment, sendButtonSubmissionEvent,
	} = useQuizStore(quizStoreSelector);

	const {
		sessionData,
	} = useSessionStore(sessionStoreSelector);

	const {
		videoElement,
	} = useAppStore(appStoreSelector);

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const [showSectionSubmit, setShowSectionSubmit] = useState<boolean>(true);

	const handleSubmitSection = useCallback(async (segmentIndex: number) => {
		let submissionImage = '';
		if (
			!sessionData?.userId
			|| !sessionData?.sessionId
		) {
			return;
		}
		try {
			console.log('Taking Screen Shot');
			const canvas = await html2canvas(document.getElementById('root') ?? document.body);
			submissionImage = canvas.toDataURL('image/png', 0.5);
			await submitQuizSegment(segmentIndex, {
				userId: sessionData?.userId,
				sessionId: sessionData?.sessionId,
			}, submissionImage);
		} catch (error) {
			console.error(error);
		}
	}, [sessionData?.sessionId, sessionData?.userId, submitQuizSegment]);

	const handleSubmitQuiz = useCallback(async () => {
		let submissionImage = '';
		if (!sessionData?.userId || !sessionData?.sessionId) {
			return;
		}
		try {
			setIsSubmitting(true);
			const canvas = await html2canvas(document.getElementById('root') ?? document.body, {
			});

			submissionImage = canvas.toDataURL('image/png');
			const redirectLink = await submitQuiz({
				submissionImage,
				userDetails: {
					userId: sessionData?.userId,
					sessionId: sessionData?.sessionId,
				},
			});
			if (redirectLink) {
				history.push({
					state: { referrer: '' },
				});
				window.location.replace(`${APP_CONFIG.QuizServerURL}${redirectLink}`);
			}
		} catch (e: any) {
			logger.error(e);
			setIsSubmitting(false);

			message.error(e.message);
		}
	}, [history, submitQuiz, sessionData?.userId, sessionData?.sessionId]);

	useEffect(() => {
		if (quizData?.revisitAllowed) {
			setShowSectionSubmit(false);
		} else if (quizDashboardData && quizDashboardData?.segments.length <= 1) {
			setShowSectionSubmit(false);
		} else {
			setShowSectionSubmit(true);
		}
	}, [quizData, quizDashboardData]);

	return (
		<div className="quiz-dashboard-wrapper">
			{
				quizDashboardData?.segments.map((segment, index, segments) => (
					<DashboardSegment
						key={segment._id}
						quizName={quizName}
						segmentData={segment}
						segmentIndex={index}
						sendSubmitButtonEvent={sendButtonSubmissionEvent}
						onSubmit={handleSubmitSection}
						showSubmit={showSectionSubmit && index !== segments.length - 1}
					/>
				))
			}

			<div style={{ position: 'relative' }}>
				<Popconfirm
					title="Do you really want to submit the test?"
					onConfirm={handleSubmitQuiz}
					okText="yes"
					cancelText="no"
					getPopupContainer={(trigger) => trigger.parentElement || document.body}
				>
					<Button
						type="primary"
						size="large"
						loading={isSubmitting}
					>
						Submit Test
					</Button>
				</Popconfirm>
			</div>
		</div>
	);
};
