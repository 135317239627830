import JitsiTrack from '../../@types/jitsi/hand-crafted/modules/RTC/JitsiTrack';
import { logger } from '../../libs/utils/logger';
import { LiveStream } from './live-stream';

interface UserDetails {
	displayName: string,
	email: string,
	enrollmentId?: string,
}

interface JaaSConfig {
	token: string,
	apiKey: string,
	webCamStream?: JitsiTrack,
	audioCamStream?: JitsiTrack,
	userDetails: UserDetails,
}

export class JaaS extends LiveStream {
	private roomObj?: any;

	private token: string;

	private apiKey: string;

	private roomName: string;

	private connectionObj?: any;

	private webCamStream: JitsiTrack | null;

	private audioCamStream: JitsiTrack | null;

	private remoteTracks: Array<any>;

	private userData: UserDetails;

	constructor(roomName: string, config: JaaSConfig) {
		super();
		this.remoteTracks = [];
		this.roomName = roomName;
		this.token = config.token;
		this.apiKey = config.apiKey;
		this.userData = config.userDetails;
		this.webCamStream = config.webCamStream ?? null;
		this.audioCamStream = config.audioCamStream ?? null;
		this.createConnection();
	}

	get room(): string {
		return this.roomName;
	}

	private get options() {
		const stage = '';
		const subdomain = '';
		const releaseVersion = '';
		const options = {
			hosts: {
				domain: `${stage}8x8.vc`,
				muc: `conference.${this.apiKey}.${stage}8x8.vc`,
				focus: `focus.${stage}8x8.vc`,
			},
			serviceUrl: `wss://${subdomain}8x8.vc/${this.apiKey}/xmpp-websocket?room=${this.roomName}${releaseVersion}`,
			websocketKeepAliveUrl: `https://${subdomain}8x8.vc/${this.apiKey}/_unlock?room=${this.roomName}`,
			constraints: {
				video: {
					height: {
						ideal: 480,
						max: 480,
						min: 180,
					},
					width: {
						ideal: 768,
						max: 768,
						min: 320,
					},
				},
			},
			channelLastN: 25,
			p2p: {
				enabled: true,
			},
			confID: `https://${stage}8x8.vc/${this.apiKey}/${this.roomName}`,
			siteID: this.apiKey,
			applicationName: 'Test App',
			logging: {
				defaultLogLevel: 'trace',
				'modules/RTC/TraceablePeerConnection.js': 'info',
				'modules/statistics/CallStats.js': 'info',
				'modules/xmpp/strophe.util.js': 'log',
			},
		};
		return options;
	}

	private onConferenceJoined() {
		this.emit('streamStatusChange', true);
	}

	private messageReceived(message: Message) {
		if (!this.roomObj) {
			return;
		}
		const senderData = this.roomObj.getParticipantById(message.senderId);
		if (senderData && senderData.getRole() === 'moderator') {
			this.emit('messageReceieved', {
				byCurrentUser: false,
				message: message.message,
				messageId: message.messageId,
				sentAt: message.sentAt,
				sentBy: senderData.email,
			});
		}
	}

	private unmuteUser() {
		if (this.audioCamStream) {
			(this.audioCamStream as any).unmute();
			this.emit('micStatusChanged', true);
		}
		// this.setAudioStream(this.audioCamStream);
	}

	async disconnectCall(): Promise<boolean> {
		try {
			if (!this.roomObj) {
				throw new Error('RoomObj is empty');
			}
			if (this.roomObj) {
				await this.roomObj.leave();
				await this.connectionObj.disconnect();
			}
			return true;
		} catch (error) {
			logger.error(error);
			return false;
		}
	}

	static cleanupDOM(id: string): void {
		const element = document.getElementById(id);
		if (element) {
			element.remove();
		}
	}

	private handleTrackChange(track: any) {
		if (track?.isLocal()) {
			if (track.isMuted()) {
				this.emit('micStatusChanged', false);
				return;
			}
		}
		const participantId = track.getParticipantId();
		const part = this.roomObj?.getParticipantById(participantId) ?? {};
		if (part?._role !== 'moderator') {
			return;
		}
		const participant = participantId + this.roomName;
		if (!this.remoteTracks[participant]) {
			this.remoteTracks[participant] = [];
		}
		const idx = this.remoteTracks[participant].push(track);
		const id = participant + track.getType() + idx;
		if (track.getType() === 'video') {
			if (!track.isLocal()) {
				track.dispose();
			}
		}
		if (track.getType() === 'audio') {
			const audioId = `${participant}audio${idx}`;
			JaaS.cleanupDOM(audioId);
			if (!track.isMuted()) {
				const audioNode = document.createElement('audio');
				audioNode.id = audioId;
				audioNode.autoplay = true;
				document.body.appendChild(audioNode);
				const remoteTrack = document.getElementById(id);
				track.attach(remoteTrack);
			}
		}
	}

	private async onConnectionSuccess() {
		const connections = this.connectionObj;
		if (!connections) {
			return;
		}
		logger.info('Going to join room');
		try {
			const currentRoom = connections.initJitsiConference(this.roomName, this.options);
			currentRoom.setLastN(2);
			if (this.webCamStream) {
				try {
					await currentRoom.addTrack(this.webCamStream);
				} catch (error) {
					console.error(error);
				}
			}
			if (this.audioCamStream) {
				try {
					await (this.audioCamStream as any).mute();
					await currentRoom.addTrack(this.audioCamStream);
				} catch (error) {
					console.error(error);
				}
			}
			currentRoom.on(
				JitsiMeetJS.events.conference.CONFERENCE_JOINED,
				() => {
					this.onConferenceJoined();
				},
			);
			currentRoom.on(
				JitsiMeetJS.events.conference.TRACK_ADDED,
				this.handleTrackChange.bind(this),
			);
			currentRoom.on(
				JitsiMeetJS.events.conference.KICKED,
				() => {
					this.emit('userKicked');
				},
			);
			currentRoom.addEventListener(
				JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
				this.handleTrackChange.bind(this),
			);
			currentRoom.addEventListener(
				JitsiMeetJS.events.conference.AV_MODERATION_APPROVED,
				async () => {
					this.unmuteUser();
					// setAudioTrackStatus(true);
					// ADD UNMUTE LOGIC HERE
				},
			);
			currentRoom.on(
				JitsiMeetJS.events.conference.PRIVATE_MESSAGE_RECEIVED,
				(senderId: string, text: string, sentAt: Date, somValue: unknown, messageId: string) => {
					this.messageReceived({
						messageId,
						senderId,
						sentAt: sentAt ?? new Date(),
						message: text,
					});
				},
			);
			currentRoom.on(
				JitsiMeetJS.events.conference.MESSAGE_RECEIVED,
				(senderId: string, text: string, sentAt: Date, somValue: unknown, messageId: string) => {
					this.messageReceived({
						messageId,
						senderId,
						sentAt: sentAt ?? new Date(),
						message: text,
					});
				},
			);
			currentRoom.join();
			const displayName = `${this.userData.displayName} (${this.userData.enrollmentId ?? this.userData.email})`;
			currentRoom.setDisplayName(displayName);
			this.roomObj = currentRoom;
		} catch (error) {
			logger.error(error);
		}
	}

	private connectionFailed() {
		this.emit('streamStatusChange', false);
	}

	private setConnectionObj(connections: any) {
		this.connectionObj = connections;
		connections.addEventListener(
			JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
			this.onConnectionSuccess.bind(this),
		);
		connections.addEventListener(
			JitsiMeetJS.events.connection.CONNECTION_FAILED,
			this.connectionFailed.bind(this),
		);
		connections.addEventListener(
			JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
			this.connectionFailed.bind(this),
		);
		connections.connect();
	}

	private createConnection() {
		try {
			const optionsForCon: { [key: string]: any } = this.options;
			JitsiMeetJS.init(optionsForCon);
			// JitsiMeetJS.setLogLevel(this.options.logging.defaultLogLevel);
			// // eslint-disable-next-line no-restricted-syntax
			// for (const [loggerId, level] of Object.entries(this.options.logging)) {
			// 	if (loggerId !== 'defaultLogLevel') {
			// 		JitsiMeetJS.setLogLevelById(level, loggerId);
			// 	}
			// }
			const connections = new JitsiMeetJS.JitsiConnection(null, this.token, this.options);
			this.setConnectionObj(connections);
		} catch (error) {
			logger.error(error);
		}
	}

	private async setWebCam(stream: JitsiTrack | null) {
		this.webCamStream = stream;
		const room = this.roomObj;
		if (room) {
			const videoTrack = room.getLocalVideoTrack();
			if (videoTrack) {
				await room.removeTrack(videoTrack);
			}
			await room.addTrack(stream);
		}
	}

	private async setAudioTrack(stream: JitsiTrack | null) {
		this.audioCamStream = stream;
		const room = this.roomObj;
		if (room) {
			const audioTrack = room.getLocalAudioTrack();
			if (audioTrack) {
				await room.removeTrack(audioTrack);
			}
			await room.addTrack(stream);
		}
	}

	async setAudioStream(stream: MediaStreamTrack | null): Promise<void> {
		try {
			if (stream) {
				const tracks = await JitsiMeetJS.createLocalTracks({
					devices: ['audio'],
				});
				const audioTrack = tracks?.[0];
				if (audioTrack) {
					audioTrack.mute();
				}
				this.setAudioTrack(audioTrack);
			}
		} catch (error) {
			logger.error(error);
		}
	}

	async setWebCamStream(stream: MediaStreamTrack | null): Promise<void> {
		try {
			if (stream) {
				const mediaSetting = stream.getSettings() ?? {};
				const tracks = await JitsiMeetJS.createLocalTracks({
					devices: ['video'],
					resolution: mediaSetting.height?.toString() ?? '360',
				}) as Array<any>;
				await this.setWebCam(tracks?.[0] ?? null);
			}
		} catch (error) {
			logger.error(error);
		}
	}

	changeAudioStatus(value: boolean): void {
		this.emit('micStatusChanged', value);
	}

	changeVideoStatus(value: boolean): void {
		this.emit('webCamStatusChange', value);
	}

	async sendMessage(message: string): Promise<boolean> {
		try {
			if (!this.roomObj) {
				return false;
			}
			await this.roomObj.sendMessage(message);
			return true;
		} catch (error) {
			console.error(error);
			return false;
		}
	}
}
