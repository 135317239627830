import {
	Button, Form, Input, message,
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import React, { useCallback, useRef, useState } from 'react';
import './email-verification-form.scoped.css';
import { APP_CONFIG } from '../../config';

interface EmailVerificationProp {
	token: string,
	onFinish: (formData: { email: string, token: string }) => Promise<void>
}

export const VerifyEmail = (props: EmailVerificationProp) => {
	const { token, onFinish } = props;
	const formRef = useRef<FormInstance | null>(null);
	const [formLoading, setLoading] = useState<boolean>(false);
	const handleFormSubmit = useCallback(async (formData: any) => {
		try {
			setLoading(true);
			await onFinish({ email: formData?.email, token });
		} catch (error: any) {
			console.error(error);
			setLoading(false);
			message.error(error?.message ?? error ?? 'Something went wrong.');
		}
	}, [token, setLoading, onFinish]);

	return (
		<div>
			<Form
				layout="vertical"
				ref={formRef}
				onFinish={handleFormSubmit}
			>
				<Form.Item
					label={APP_CONFIG.isChitkara ? 'Email / Roll Number' : 'Email'}
					name="email"
					rules={[
						{ required: true, whitespace: true, message: 'Please enter email!' },
					]}
					normalize={(value, prevVluae, allValue) => value.trim()}
				>
					<Input id="emailId" type="text" placeholder="email" />
				</Form.Item>
				<Form.Item>
					<div className="form-actions">
						<Button
							type="primary"
							size="large"
							htmlType="submit"
							loading={formLoading}
						>
							submit
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
};
