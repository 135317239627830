import React, {
	useCallback, useEffect, useRef, useState,
} from 'react';
import {
	Button, Input, message, Popconfirm, Tabs, Tooltip,
} from 'antd';
import Split from 'react-split';
import AceEditor from 'react-ace';
import uniqid from 'uniqid';

import './attempt-web.scoped.css';
import {
	CaretDownFilled, CaretRightFilled, CaretUpFilled, CheckOutlined, CloseOutlined,
	MinusCircleFilled, ReloadOutlined, UndoOutlined, WarningFilled,
} from '@ant-design/icons';
import { BrowserIframe } from '../browser-iframe';
import { Editor } from '../editor';
import { TestCaseStatus } from '../../config';
import { webTestsRunner } from '../../libs/utils/web-tests-runner';
import { logger } from '../../libs/utils/logger';

const { TabPane } = Tabs;

async function runSequentially(series: any[]): Promise<void> {
	// eslint-disable-next-line no-restricted-syntax
	for (const method of series) {
		// eslint-disable-next-line no-await-in-loop
		await method();
	}
}

function getIframeTemplate(
	html: string, css: string, js: string, reactRendererCode: string, isReactQuestion: boolean,
): string {
	return (
		`<!DOCTYPE html>
			<html>
				<head>
					<meta charset="utf-8">
					<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
					<meta http-equiv="X-UA-Compatible" content="IE=edge">
					<script src="https://unpkg.com/@babel/standalone/babel.min.js"></script>
		${isReactQuestion
			? `<script crossorigin src="https://unpkg.com/react@17/umd/react.production.min.js"></script>
									<script crossorigin src="https://unpkg.com/react-dom@17/umd/react-dom.production.min.js"></script>`
			: ''
		}
					<title></title>
					<style>${css}</style>
					<script></script>
				</head>
				<body>
					${html}
					<script></script>
					<script>
						var __iframeParentRef = window.parent;
					</script>
					<script type="text/babel">
						${js.replace(/<\/script>/ig, '<\\/script>')}
					</script>
					<script></script>
					<script>
						window.addEventListener('error', (event) => {
								if(event && event.message && event.message == "Script error."){
									console.log('error in script');
									__iframeParentRef.postMessage(JSON.stringify({ loaded: true }));
								}
						});
					</script>
					<script type="text/babel">
						let intervalId;

						function checkIframeLoaded() {
							if (document.readyState === 'complete') {
								clearInterval(intervalId);
						
								__iframeParentRef.postMessage(JSON.stringify({ loaded: true }));
							}
						}
						intervalId = setInterval(checkIframeLoaded, 100);
					</script>
					<script type="text/babel">
						${isReactQuestion ? reactRendererCode : ''}
					</script>
					<script></script>
				</body>
			</html>`
	);
}

interface AttemptWebProps {
	questionData: AttemptQuestionData
	compiling: boolean
	submitting: boolean
	disableCopyPaste?: boolean
	compileCode: (data: any) => Promise<void>
	onSubmit: (data: any) => Promise<void>
	setTestCaseStatus: (testCaseIndex: number, status: TestCaseStatus) => Promise<void>
	setAttempt: (attemptData: unknown) => void
	getAttempt: () => any | null
	sendPasteNotification: () => void
}

export const AttemptWeb: React.FunctionComponent<AttemptWebProps> = (props) => {
	const {
		questionData, compiling, submitting, disableCopyPaste,
		compileCode, setTestCaseStatus, onSubmit, setAttempt, getAttempt,
		sendPasteNotification,
	} = props;

	const iframeBrowserRef: React.Ref<HTMLIFrameElement> = useRef(null);
	const editorRefMainHTML: React.Ref<AceEditor> = useRef(null);
	const editorRefMainCSS: React.Ref<AceEditor> = useRef(null);
	const editorRefMainJS: React.Ref<AceEditor> = useRef(null);

	const [consoleVisible, setConsoleVisible] = useState<boolean>(false);
	const [previewBrowserHTML, setPreviewBrowserHTML] = useState<string | undefined>(`
		<p style="margin: 20px 10px; text-align: center;">click on the ${questionData.questionTypeWeb?.testCases.length ? 'run code/' : ''}preview button to see the preview.</p>
	`);

	const [runningTests, setRunningTests] = useState<boolean>(false);
	const [hasRunTests, setHasRunTests] = useState<boolean>(false);
	const [webTestsSubmitClicked, setWebTestsSubmitClicked] = useState<boolean>(false);

	useEffect(() => {
		setRunningTests(compiling);
	}, [compiling]);

	const [selectedTestCase, setSelectedTestCase] = useState<WebTestCaseMeta | undefined>();

	const [htmlEditorValue, setHTMLEditorValue] = useState<string>(
		getAttempt()?.html
		?? questionData.questionTypeWeb?.lastSubmitted?.html
		?? questionData.questionTypeWeb?.html
		?? '',
	);
	const [cssEditorValue, setCSSEditorValue] = useState<string>(
		getAttempt()?.css
		?? questionData.questionTypeWeb?.lastSubmitted?.css
		?? questionData.questionTypeWeb?.css
		?? '',
	);
	const [jsEditorValue, setJSEditorValue] = useState<string>(
		getAttempt()?.js
		?? questionData.questionTypeWeb?.lastSubmitted?.js
		?? questionData.questionTypeWeb?.js
		?? '',
	);
	const pasteHandler = useCallback((e: any) => {
		// console.log('changeeeee', e);
		if (e.action === 'insert') {
			let isInvalid = e?.lines?.length > 3;
			e.lines.map((line: any) => {
				if (!isInvalid && line?.trim()?.split(' ').length > 2) {
					isInvalid = true;
				}
				return true;
			});
			if (isInvalid) {
				// alert('invalid');
				// console.log('=================INVALIIDDD LOGOUT=============');
				message.error('Attention: Please do not use copy paste as it may result in disqualification.');
				sendPasteNotification?.();
				// for HTML
				editorRefMainHTML.current?.editor.session.getUndoManager()?.undo(
					editorRefMainHTML.current?.editor.getSession(),
				);
				editorRefMainHTML.current?.editor.session.getUndoManager().reset();

				// for css
				editorRefMainCSS.current?.editor.session.getUndoManager()?.undo(
					editorRefMainCSS.current?.editor.getSession(),
				);
				editorRefMainCSS.current?.editor.session.getUndoManager().reset();

				// for JS
				editorRefMainJS.current?.editor.session.getUndoManager()?.undo(
					editorRefMainJS.current?.editor.getSession(),
				);
				editorRefMainJS.current?.editor.session.getUndoManager().reset();
			}
		}
	}, [sendPasteNotification]);

	const resetCode = useCallback(() => {
		if (disableCopyPaste) {
			editorRefMainHTML?.current?.editor?.off('change', pasteHandler);
			editorRefMainCSS?.current?.editor?.off('change', pasteHandler);
			editorRefMainJS?.current?.editor?.off('change', pasteHandler);
		}
		setHTMLEditorValue(
			questionData.questionTypeWeb?.lastSubmitted?.html
			?? questionData.questionTypeWeb?.html
			?? '',
		);

		setCSSEditorValue(
			questionData.questionTypeWeb?.lastSubmitted?.css
			?? questionData.questionTypeWeb?.css
			?? '',
		);

		setJSEditorValue(
			questionData.questionTypeWeb?.lastSubmitted?.js
			?? questionData.questionTypeWeb?.js
			?? '',
		);
		if (disableCopyPaste) {
			setTimeout(() => {
				editorRefMainHTML?.current?.editor?.on('change', pasteHandler);
				editorRefMainCSS?.current?.editor?.on('change', pasteHandler);
				editorRefMainJS?.current?.editor?.on('change', pasteHandler);
			}, 1000);
		}
	}, [questionData, disableCopyPaste, pasteHandler]);

	useEffect(() => {
		const testCase = questionData.questionTypeWeb?.testCases[0];
		if (testCase) {
			setSelectedTestCase(testCase);
		}
	}, [questionData.questionTypeWeb?.testCases]);

	const saveAttempt = useCallback(() => {
		setAttempt({
			html: htmlEditorValue,
			css: cssEditorValue,
			js: jsEditorValue,
		});
	}, [htmlEditorValue, cssEditorValue, jsEditorValue, setAttempt]);

	useEffect(() => {
		const intervalId = setInterval(saveAttempt, 2000);

		return () => clearInterval(intervalId);
	}, [saveAttempt]);

	const toggleConsoleVisible = useCallback(() => {
		setConsoleVisible(!consoleVisible);
	}, [consoleVisible, setConsoleVisible]);

	const handlePreview = useCallback(() => {
		if (!navigator.onLine) {
			message.error('Please check your internet connection.');
			return;
		}
		let isReactQuestion = false;
		let reactRendererCode = '';
		if (questionData.questionTypeWeb) {
			reactRendererCode = questionData.questionTypeWeb.reactRendererCode;
			isReactQuestion = questionData.questionTypeWeb.isReactQuestion;
		}

		const template = getIframeTemplate(
			htmlEditorValue, cssEditorValue, jsEditorValue, reactRendererCode, isReactQuestion,
		);

		setPreviewBrowserHTML('');
		setTimeout(() => setPreviewBrowserHTML(template), 0);
	}, [htmlEditorValue, cssEditorValue, jsEditorValue, questionData]);

	const runWebTests = useCallback(async (e: MessageEvent<string>) => {
		try {
			if (typeof e.data !== 'string') {
				return;
			}

			const data = JSON.parse(e.data);
			if (data.loaded) {
				logger.log('iframe loaded');
				window.removeEventListener('message', runWebTests);

				if (iframeBrowserRef.current?.contentWindow) {
					const iframeContentWindow = iframeBrowserRef.current?.contentWindow;
					const testRunners = questionData.questionTypeWeb?.testCases.map(
						(testCase, index) => async () => (
							(async () => {
								const result = await webTestsRunner(
									iframeContentWindow, testCase.evaluator,
								);

								setTestCaseStatus(index, result ? TestCaseStatus.Passed : TestCaseStatus.Failed);
							})()),
					);

					if (testRunners) {
						await runSequentially(testRunners);

						setRunningTests(false);
						setHasRunTests(true);
					}
				}
			}
		} catch (error) {
			logger.error(error);
		}
	}, [questionData, setTestCaseStatus]);

	const handleRunWebTests = useCallback(async () => {
		if (!navigator.onLine) {
			message.error('Please check your internet connection.');
			return;
		}
		saveAttempt();

		setHasRunTests(false);
		setRunningTests(true);

		questionData.questionTypeWeb?.testCases.forEach(async (testCase, index) => {
			setTestCaseStatus(index, TestCaseStatus.Running);
		});

		if (questionData.questionTypeWeb?.testCases.length) {
			setConsoleVisible(true);
		}
		handlePreview();

		window.addEventListener('message', runWebTests, false);
	}, [questionData, setTestCaseStatus, handlePreview, runWebTests, saveAttempt]);

	const handleRunReactQuestionTests = useCallback(async () => {
		if (!navigator.onLine) {
			message.error('Please check your internet connection.');
			return;
		}
		questionData.questionTypeWeb?.testCases.forEach(async (testCase, index) => {
			setTestCaseStatus(index, TestCaseStatus.Running);
		});

		setConsoleVisible(true);
		handlePreview();

		try {
			await compileCode({
				language: '2',
				html: htmlEditorValue,
				css: cssEditorValue,
				js: jsEditorValue,
				codeId: uniqid(),
			});
		} catch (e: any) {
			logger.error(e);

			message.error(e.message);
		}
	}, [
		questionData, htmlEditorValue, cssEditorValue, jsEditorValue,
		setTestCaseStatus, handlePreview, compileCode,
	]);

	const handleSubmit = useCallback(async () => {
		if (!navigator.onLine) {
			message.error('Please check your internet connection.');
			return;
		}
		if (questionData.questionTypeWeb?.isReactQuestion) {
			saveAttempt();

			await onSubmit({
				html: htmlEditorValue,
				css: cssEditorValue,
				js: jsEditorValue,
				language: 2,
				scores: questionData.questionTypeWeb?.testCases.map((testCase) => testCase.score) || [],
			});
		} else {
			handleRunWebTests();
			setWebTestsSubmitClicked(true);
		}
	}, [
		questionData, htmlEditorValue, cssEditorValue,
		jsEditorValue, handleRunWebTests, onSubmit, saveAttempt,
	]);

	const stopTestCaseOffline = useCallback(() => {
		setWebTestsSubmitClicked(false);
		setRunningTests(false);
		questionData.questionTypeWeb?.testCases.forEach(async (testCase, index) => {
			setTestCaseStatus(index, TestCaseStatus.NotExecuted);
		});
	}, [questionData.questionTypeWeb, setRunningTests, setWebTestsSubmitClicked, setTestCaseStatus]);

	useEffect(() => {
		window.addEventListener('offline', stopTestCaseOffline);

		return () => {
			window.removeEventListener('offline', stopTestCaseOffline);
		};
	}, [stopTestCaseOffline]);

	useEffect(() => {
		(async () => {
			if (hasRunTests && webTestsSubmitClicked) {
				await onSubmit({
					html: htmlEditorValue,
					css: cssEditorValue,
					js: jsEditorValue,
					webTestCaseStatus: questionData.questionTypeWeb?.testCases.map(
						(testCase) => testCase.status === TestCaseStatus.Passed,
					) || [],
					scores: questionData.questionTypeWeb?.testCases.map((testCase) => testCase.score) || [],
				});

				setWebTestsSubmitClicked(false);
			}
		})();
	}, [
		questionData, htmlEditorValue, cssEditorValue,
		jsEditorValue, hasRunTests, webTestsSubmitClicked, onSubmit,
		setTestCaseStatus,
	]);

	return (
		<div className="attempt-web-container">
			<Split
				className="main"
				sizes={consoleVisible ? [65, 35] : [100, 0]}
				minSize={consoleVisible ? 150 : 0}
				gutterSize={consoleVisible ? 8 : 0}
				direction="vertical"
				style={{ maxHeight: 'calc(100vh - 106px)' }}
			>
				<div className="attempt-area-container">
					<Split
						style={{ display: 'flex', flex: '1 1 100%', overflow: 'hidden' }}
						sizes={[50, 50]}
						minSize={0}
						gutterSize={8}
					>
						<div className="code-editors-container">
							<Tabs
								type="card"
								size="small"
								tabBarExtraContent={{
									right: (
										<Button
											type="primary"
											size="small"
											onClick={handlePreview}
											disabled={runningTests || submitting}
											style={{
												marginRight: '0.5rem',
												fontSize: 12,
												fontFamily: 'Hind, sans-serif',
												fontWeight: 500,
											}}
										>
											preview
											<CaretRightFilled style={{ position: 'relative', top: 1 }} />
										</Button>
									),
								}}
							>
								{
									questionData.questionTypeWeb?.isHtmlAllowed && (
										<TabPane tab="index.html" key="index.html" forceRender>
											<div className="html-editor">
												<Editor
													name="html"
													mode="html"
													disableCopyPaste={disableCopyPaste}
													showLineNumbers
													notifyOnPaste
													readOnly={runningTests || submitting}
													value={htmlEditorValue}
													onChange={setHTMLEditorValue}
													sendPasteNotification={sendPasteNotification}
													editorRefMain={editorRefMainHTML}
													pasteHandler={pasteHandler}
												/>
											</div>
										</TabPane>
									)
								}
								{
									questionData.questionTypeWeb?.isCssAllowed && (
										<TabPane tab="index.css" key="index.css" forceRender>
											<div className="css-editor">
												<Editor
													name="css"
													mode="css"
													disableCopyPaste={disableCopyPaste}
													showLineNumbers
													notifyOnPaste
													readOnly={runningTests || submitting}
													value={cssEditorValue}
													onChange={setCSSEditorValue}
													sendPasteNotification={sendPasteNotification}
													editorRefMain={editorRefMainCSS}
													pasteHandler={pasteHandler}
												/>
											</div>
										</TabPane>
									)
								}
								{
									questionData.questionTypeWeb?.isJsAllowed && (
										<TabPane tab="index.js" key="index.js" forceRender>
											<div className="js-editor">
												<Editor
													name="js"
													disableCopyPaste={disableCopyPaste}
													mode={questionData.questionTypeWeb.isReactQuestion ? 'jsx' : 'javascript'}
													showLineNumbers
													notifyOnPaste
													readOnly={runningTests || submitting}
													value={jsEditorValue}
													onChange={setJSEditorValue}
													sendPasteNotification={sendPasteNotification}
													editorRefMain={editorRefMainJS}
													pasteHandler={pasteHandler}
												/>
											</div>
										</TabPane>
									)
								}
							</Tabs>
						</div>
						{/* <div className="editors-browser-divider" /> */}
						<div className="preview-browser-container">
							<div className="preview-browser-header">
								<div className="action-icon">
									<Tooltip title="reload" placement="bottom">
										<Button type="text" icon={<ReloadOutlined />} onClick={handlePreview} disabled={runningTests || submitting} />
									</Tooltip>
								</div>
								<div className="address-bar">
									<Input defaultValue="https://localhost" disabled />
								</div>
							</div>
							<BrowserIframe browserRef={iframeBrowserRef} srcDoc={previewBrowserHTML} />
						</div>
					</Split>
				</div>
				{
					consoleVisible
						? (
							<div className="console-container">
								<Tabs
									type="card"
									defaultActiveKey="test-cases"
									size="small"
									tabBarExtraContent={{
										right: (
											<Button
												type="text"
												shape="circle"
												icon={(
													<MinusCircleFilled style={{
														color: 'var(--primary-color)',
														opacity: 0.75,
													}}
													/>
												)}
												onClick={toggleConsoleVisible}
											/>
										),
									}}
								>
									<TabPane tab="Test Cases" key="test-cases">
										<div className="test-cases-wrapper">
											<div className="test-cases-container">
												{
													questionData.questionTypeWeb?.testCases.map((testCase, index) => (
														<Button
															className={selectedTestCase?._id === testCase._id ? 'active' : ''}
															key={testCase._id}
															type="text"
															size="large"
															icon={(() => {
																if (testCase.status === TestCaseStatus.NotExecuted) {
																	return <WarningFilled style={{ color: '#fed639', fontSize: 18 }} />;
																} if (testCase.status === TestCaseStatus.Passed) {
																	return <CheckOutlined style={{ color: 'green', fontSize: 18 }} />;
																} if (testCase.status === TestCaseStatus.Failed) {
																	return <CloseOutlined style={{ color: 'red', fontSize: 18 }} />;
																}
																return null;
															})()}
															loading={testCase.status === TestCaseStatus.Running}
															onClick={() => setSelectedTestCase(testCase)}
														>
															Test Case
															{' '}
															{index + 1}
														</Button>
													))
												}
											</div>
											<div className="test-case-details-container">
												{
													selectedTestCase && (
														<>
															<span>Description:</span>
															<pre>
																{selectedTestCase.description}
															</pre>
														</>
													)
												}
											</div>
										</div>
									</TabPane>
								</Tabs>
							</div>
						) : (
							<div />
						)
				}
			</Split>
			<div className="footer">
				<div>
					{
						questionData.questionTypeWeb?.testCases.length
							? (
								<Button className={consoleVisible ? 'consoleBtn consoleOpened' : 'consoleBtn'} type="ghost" onClick={toggleConsoleVisible}>
									<span>Test cases</span>
									{
										consoleVisible
											? (
												<CaretDownFilled />
											) : (
												<CaretUpFilled />
											)
									}
								</Button>
							) : null
					}
				</div>
				<div>
					<Tooltip title="reset code" placement="left">
						<Popconfirm
							title="Do you really want to reset your code?"
							okText="yes"
							cancelText="no"
							onConfirm={resetCode}
							getPopupContainer={(trigger) => trigger.parentElement || document.body}
							disabled={runningTests || submitting}
						>
							<Button
								type="text"
								shape="circle"
								disabled={runningTests || submitting}
								icon={(
									<UndoOutlined />
								)}
							/>
						</Popconfirm>
					</Tooltip>
					{
						questionData.questionTypeWeb?.testCases.length
							? (
								<Button
									type="ghost"
									disabled={submitting}
									loading={runningTests}
									onClick={
										questionData.questionTypeWeb?.isReactQuestion
											? handleRunReactQuestionTests : handleRunWebTests
									}
								>
									run code
								</Button>
							) : null
					}
					<Button
						disabled={runningTests}
						loading={submitting || webTestsSubmitClicked}
						type="primary"
						onClick={handleSubmit}
					>
						submit
					</Button>
				</div>
			</div>
		</div>
	);
};
