import { WifiOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React, { useMemo } from 'react';
import './network-stats.css';
import { getConnectionStringFromNumber } from '../../libs/utils';

interface NetworkStatsProps {
	network: NetworkStats | null
}

export const NetworkStats = (props: NetworkStatsProps) => {
	const { network } = props;

	const wifiColor = useMemo(() => {
		if (!network) {
			return 'grey';
		}
		if (network.upload.speed > 0.6) {
			return 'green';
		}
		if (network.upload.speed >= 0.3) {
			return '#faad14';
		}
		return 'red';
	}, [network]);

	if (!network) {
		return (
			<div style={{ width: '18px', height: '18px', display: 'flex' }}>
				<img alt="loading network stats" style={{ display: 'inline-block' }} src="/wifi-loading.gif" />
			</div>
		);
	}

	return (
		<div style={{ color: wifiColor, fontSize: '20px' }}>
			<Popover
				placement="rightBottom"
				content={(
					<div id="upload-stats-pop-up-content">
						<p style={{ margin: '0px' }}>
							<span>Upload Speed: </span>
							<span>
								{getConnectionStringFromNumber(network?.upload?.speed ?? 0)}
							</span>
						</p>
						<p style={{ margin: '0px' }}>
							<span>Upload Latency: </span>
							<span>{network.upload.latency}</span>
						</p>
					</div>
				)}
			>
				<WifiOutlined size={20} />
			</Popover>
		</div>
	);
};
