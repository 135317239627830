import { EventEmitter } from 'events';
import TypedEmitter from 'typed-emitter';
import JitsiTrack from '../../@types/jitsi/hand-crafted/modules/RTC/JitsiTrack';

interface MessageEventPayload {
	message: string,
	messageId: string,
	sentAt: Date,
	sentBy: string,
	byCurrentUser: boolean,
}

interface SupportedEvents {
	'streamStatusChange': (value: boolean) => void,
	'micStatusChanged': (value: boolean) => void,
	'webCamStatusChange': (value: boolean) => void,
	'userKicked': () => void,
	'messageReceieved': (data: MessageEventPayload) => void,
}

export abstract class LiveStream extends (
	EventEmitter as new () => TypedEmitter<SupportedEvents>
) {
	abstract get room(): string;
	abstract setAudioStream(stream: MediaStreamTrack | null): Promise<void>;
	abstract setWebCamStream(stream: MediaStreamTrack | null): void;
	abstract changeAudioStatus(value: boolean): void;
	abstract changeVideoStatus(value: boolean): void;
	abstract sendMessage(value: string): Promise<boolean>;
	abstract disconnectCall(): Promise<boolean>;
}
