/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/object-curly-spacing */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/indent */
import React, {
	useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { WechatOutlined } from '@ant-design/icons';
import {
	Badge, Button, Input, InputRef, Popover, message, notification,
} from 'antd';

import { APP_CONFIG, ScreenShareState, WebcamStateKind } from '../../config';
import './jaas.scoped.css';
import { useQuizStore } from '../../store';
import { ParticipantsJitsi } from '../../@types/jitsi';

interface JaasParams {
	token: string,
	displayName: string,
	roomName: string,
	isStage?: boolean,
	// room?: any,
	webCamStream: any,
	// setRoom: (room: any) => void,
	setScreenShare: (value: ScreenShareState) => void,
	onUserKicked?: (roomName: string) => void,
}

const quizSelector = (state: QuizState) => ({
	jitsiCredentials: state.getJitsiCredentials,
	updateJitsiCredentials: state.updateJitsiCredentials,
});

const apiKey = APP_CONFIG.LiveStreamPublicApiKey;
export const Jaas = (params: JaasParams): React.ReactElement => {
	const {
		token, roomName, isStage, webCamStream, displayName, onUserKicked, setScreenShare,
	} = params;

	const [room, setRoom] = useState<any>(null);
	const { jitsiCredentials, updateJitsiCredentials } = useQuizStore(quizSelector);
	const [releaseVersion, setReleaseVersion] = useState('');

	const [options, setOptions] = useState<{ [key: string]: any } | null>(null);
	const [connection, setConnection] = useState<null | any>(null);
	const [error, setError] = useState<null | string>(null);

	const [newMessage, setNewMessage] = useState<boolean>(false);
	const [openMessageContainer, setOpenMessageContainer] = useState<boolean>(false);
	const [messageArray, setMessageArray] = useState<Array<{ userId: string, message: string }>>([]);
	const [messageInputValue, setMessageInputValue] = useState<string>('');
	const messagesArrayRef = useRef<Array<{ userId: string, message: string }>>([]);
	const messageInputRef = useRef<InputRef | null>(null);
	const localTracks = useRef<any[]>([]);
	const lastElementRef = useRef<HTMLDivElement | null>(null);
	const sendButtonRef = useRef<HTMLButtonElement | null>(null);
	const popupRef = useRef<any>(null);
	const participantsMap = useRef<Map<string, ParticipantsJitsi>>(new Map());

	const sendMessage = useCallback(() => {
		try {
			const content = messageInputRef.current?.input?.value;
			if (!content) {
				return;
			}
			if (messageInputRef.current?.input) {
				setMessageInputValue('');
			}
			if (room) {
				const currentUserId = room.myUserId();
				messagesArrayRef.current.push({
					userId: currentUserId,
					message: content,
				});
				room.sendMessage(content);
				setMessageArray([...messagesArrayRef.current]);
			}
		} catch (err) {
			console.log(err);
		}
	}, [room, setMessageArray, setMessageInputValue]);

	const messagePopupContent = useMemo(() => {
		if (!room) return <div style={{ width: '400px' }} />;
		const result: Array<React.ReactElement> = [];
		const currentUserId = room.myUserId();
		messageArray.forEach((singleMessage) => {
			const currentUserMessage = (singleMessage.userId === currentUserId);
			result.push(
				<div ref={lastElementRef} className={`messages ${currentUserMessage ? 'user-message-container' : 'received-message-container'}`}>
					<p className={currentUserMessage ? 'user-message' : 'received-message'}>{singleMessage.message}</p>
				</div>,
			);
		});

		setTimeout(() => {
			if (lastElementRef.current) {
				lastElementRef.current.scrollIntoView();
			}
		}, 300);
		return (
			<>
				<div className="message-container">
					{result}
				</div>
			</>
		);
	}, [messageArray, room]);

	const onUserJoined = (id, data) => {
		const currentUserData = {
			id: data._id ?? '',
			name: data._displayName ?? '',
			role: data._role,
		};
		participantsMap.current.set(id, currentUserData);
		console.log('user joined!', id, data, currentUserData);
	};

	const onUserLeft = (id) => {
		console.log('user left!');
	};

	const updateParticipants = useCallback((rooms) => {
		try {
			participantsMap.current.clear();
			const currentParticipants = rooms.getParticipants();
			console.log(currentParticipants);
			currentParticipants.forEach((element) => (
				participantsMap.current.set(element._id ?? '', {
					id: element._id ?? '',
					name: element._displayName ?? '',
					role: element._role,
				})
			));
		} catch (err) {
			console.error(err);
		}
	}, []);

	const onConferenceJoined = useCallback((rooms) => {
		console.log('conference joined!');
		updateJitsiCredentials();
		updateParticipants(rooms);
		rooms.setLocalParticipantProperty('requestingTranscription', true);
	}, [updateJitsiCredentials, updateParticipants]);

	const conferenceLeft = useCallback(() => {
		console.log('Conference Left');
	}, []);

	const onUserKickedFromConference = useCallback((rooms) => {
		if (onUserKicked) {
			onUserKicked(roomName);
		}
	}, [onUserKicked, roomName]);

	const publicMessageReceived = useCallback((id: string, text: string, ts: number) => {
		const senderData = participantsMap.current.get(id);
		if (senderData && senderData.role === 'moderator') {
			setNewMessage(true);
			messagesArrayRef.current.push({ userId: id, message: text });
			setMessageArray([...messagesArrayRef.current]);
		}
	}, [setNewMessage]);

	const messageReceived = useCallback((id: string, text: string, ts: number) => {
		setNewMessage(true);
		console.log(id, text);
		messagesArrayRef.current.push({ userId: id, message: text });
		setMessageArray([...messagesArrayRef.current]);
	}, [setNewMessage]);

	const updateUserRole = useCallback((id: string, role: string) => {
		const userData = participantsMap.current.get(id);
		if (userData) {
			userData.role = role;
		}
	}, []);

	const onConnectionSuccess = useCallback(async (
		connections: any, option: { [key: string]: any }, roomTitle: string,
	) => {
		let currentRoom: any;
		try {
			currentRoom = connections.initJitsiConference(roomTitle, option);
			// setScreenShare(ScreenShareState.Loading);
			// Add local tracks before joining
			for (let i = 0; i < localTracks.current.length; i += 1) {
				currentRoom.addTrack(localTracks.current[i]);
			}
			currentRoom.on(
				JitsiMeetJS.events.conference.CONFERENCE_JOINED,
				() => {
					onConferenceJoined(currentRoom);
				},
			);
			currentRoom.on(
				JitsiMeetJS.events.conference.CONFERENCE_LEFT,
				conferenceLeft,
			);
			currentRoom.on(
				JitsiMeetJS.events.conference.USER_JOINED,
				onUserJoined,
			);
			currentRoom.on(
				JitsiMeetJS.events.conference.USER_LEFT,
				onUserLeft,
			);
			currentRoom.on(
				JitsiMeetJS.events.conference.MESSAGE_RECEIVED,
				publicMessageReceived,
			);
			currentRoom.addEventListener(
				JitsiMeetJS.events.conference.PRIVATE_MESSAGE_RECEIVED,
				messageReceived,
			);
			currentRoom.addEventListener(
				JitsiMeetJS.events.conference.USER_ROLE_CHANGED,
				updateUserRole,
			);
			currentRoom.addEventListener(
				JitsiMeetJS.events.conference.KICKED,
				onUserKickedFromConference,
			);

			currentRoom.on(JitsiMeetJS.events.conference.ENDPOINT_MESSAGE_RECEIVED, (...args) => { console.log('RECEIVED ENDPOINT MESSAGE', args); });

			// Join
			currentRoom.join();
			currentRoom.setSenderVideoConstraint(720); // Send at most 720p
			currentRoom.setReceiverVideoConstraint(360); // Receive at most 360p for each participant
			setRoom(currentRoom);
		} catch (err) {
			console.error(err);
		}

		return () => {
			currentRoom.leave().then(() => console.log('Confrence Leaved')).catch((err) => {
				console.error(err);
			});
		};
	}, [
		conferenceLeft, setRoom, messageReceived, onUserKickedFromConference,
		onConferenceJoined, publicMessageReceived, updateUserRole,
	]);

	const createConnection = useCallback(async (option: { [key: string]: any }, roomTitle) => {
		try {
			await jitsiCredentials();
			JitsiMeetJS.init(option);
			JitsiMeetJS.setLogLevel(option.logging.defaultLogLevel);
			for (const [loggerId, level] of Object.entries(option.logging)) {
				if (loggerId !== 'defaultLogLevel') {
					JitsiMeetJS.setLogLevelById(level, loggerId);
				}
			}
			const connections = new JitsiMeetJS.JitsiConnection(null, token, option);
			connections.addEventListener(
				JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
				() => {
					onConnectionSuccess(connections, option, roomTitle);
				},
			);
			connections.addEventListener(
				JitsiMeetJS.events.connection.CONNECTION_FAILED, () => {
					console.error('Unable to connect to jitsi');
				},
			);
			connections.addEventListener(
				JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, () => {
					console.log('Connectoin Disconnect');
				},
			);

			connections.connect();
			setConnection(connections);
		} catch (err) {
			console.log(err);
		}
	}, [onConnectionSuccess, token, jitsiCredentials]);

	useEffect(() => {
		const stage = isStage ? 'stage' : '';
		const subdomain = isStage ? stage : '';
		const option = {
			hosts: {
				domain: `${stage}8x8.vc`,
				muc: `conference.${apiKey}.${stage}8x8.vc`,
				focus: `focus.${stage}8x8.vc`,
			},
			serviceUrl: `wss://${subdomain}8x8.vc/${apiKey}/xmpp-websocket?room=${roomName}${releaseVersion}`,
			websocketKeepAliveUrl: `https://${subdomain}8x8.vc/${apiKey}/_unlock?room=${roomName}`,
			constraints: {
				video: {
					height: {
						ideal: 720,
						max: 720,
						min: 180,
					},
					width: {
						ideal: 1280,
						max: 1280,
						min: 320,
					},
				},
			},
			channelLastN: 25,
			p2p: {
				enabled: true,
			},
			confID: `https://${stage}8x8.vc/${apiKey}/${roomName}`,
			siteID: apiKey,
			applicationName: 'Test App',
			logging: {
				defaultLogLevel: 'trace',
				'modules/RTC/TraceablePeerConnection.js': 'info',
				'modules/statistics/CallStats.js': 'info',
				'modules/xmpp/strophe.util.js': 'log',
			},
		};

		setOptions(option);
	}, [roomName, setOptions, releaseVersion, isStage]);

	useEffect(() => {
		if (options && roomName) {
			createConnection(options, roomName);
		} else {
			conferenceLeft();
		}
		return conferenceLeft;
	}, [conferenceLeft, createConnection, options, roomName]);

	useEffect(() => (() => {
		if (room) {
			room.leave().then(() => console.log('Confrence Leaved')).catch((err) => {
				console.error(err);
			});
		}
	}), [roomName, room]);

	useEffect(() => {
		if (webCamStream && room) {
			room.addTrack(webCamStream);
		}
	}, [webCamStream, room]);

	useEffect(() => {
		if (room) {
			room.setDisplayName(displayName);
		}
	}, [room, displayName]);

	useEffect(() => {
		if (newMessage) {
			if (!openMessageContainer && popupRef.current?.triggerRef?.current) {
				popupRef.current.triggerRef.current.click();
				setNewMessage(false);
			}
		}
	}, [newMessage, setNewMessage, openMessageContainer]);

	return (
		<div id="room-chat">
			<Badge status="error" size="default" dot={newMessage} offset={[-10, 10]}>
				<Popover
					id="message-popover"
					onOpenChange={setOpenMessageContainer}
					placement="topRight"
					showArrow={false}
					title="Messages"
					trigger="click"
					ref={popupRef}
					content={
						(
							<div>
								{messagePopupContent}
								<div style={{ display: 'flex', gap: '10px', padding: '5px 20px' }}>
									<Input
										onKeyDown={(ev) => {
											if (ev.key === 'Enter') {
												console.log('Please Send The Message');
												if (sendButtonRef.current) {
													sendButtonRef.current.click();
												}
											}
										}}
										onChange={(ev) => setMessageInputValue(ev.target.value)}
										value={messageInputValue}
										style={{ borderRadius: '5px' }}
										ref={messageInputRef}
										placeholder="Type your messages here.."
									/>
									<Button ref={sendButtonRef} style={{ borderRadius: '5px' }} onClick={sendMessage} type="primary">Send</Button>
								</div>
							</div>
						)
					}
				>
					<Button
						id="room-chat-button"
						type="primary"
						size="large"
						icon={<WechatOutlined size={23} />}
					/>
				</Popover>
			</Badge>
		</div>
	);
};
