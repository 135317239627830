import React, {
	useCallback, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import html2canvas from 'html2canvas-pro';
import {
	Button, message, Popconfirm, Modal,
	Input,
	InputRef,
} from 'antd';
import { useAppStore, useQuizStore, useSessionStore } from '../../../store';

import './quiz-dashboard.scoped.css';
import { DashboardSegment } from '../../../components/dashboard-segment';
import { logger } from '../../../libs/utils/logger';
import { APP_CONFIG } from '../../../config';
import { generateOtp } from '../../../libs/utils';

const quizStoreSelector = (state: QuizState) => ({
	quizData: state.quizData,
	quizDashboardData: state.quizDashboardData,
	screenShareStream: state.screenShareStream,
	submitQuizSegment: state.submitQuizSegment,
	submitQuiz: state.submitQuiz,
	sendButtonSubmissionEvent: state.sendButtonSubmissionEvent,
});

const appStoreSelector = (state: AppState) => ({
	videoElement: state.videoElement,
});

const sessionStoreSelector = (state: SessionState) => ({
	sessionData: state.sessionData,
});

interface QuizDashboardScreenProps {
	quizName: string
}

export const QuizDashboardScreen: React.FunctionComponent<QuizDashboardScreenProps> = (props) => {
	const { quizName } = props;
	const history = useHistory();

	const {
		quizData, quizDashboardData, screenShareStream,
		submitQuiz, submitQuizSegment, sendButtonSubmissionEvent,
	} = useQuizStore(quizStoreSelector);
	const [showPopup, setShowPopup] = useState<boolean>(false);

	const {
		sessionData,
	} = useSessionStore(sessionStoreSelector);

	const {
		videoElement,
	} = useAppStore(appStoreSelector);

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [modal, contextHolder] = Modal.useModal();
	const [showSectionSubmit, setShowSectionSubmit] = useState<boolean>(true);

	const otpInputRef = useRef<HTMLInputElement | null>(null);

	const handleSubmitSection = useCallback(async (segmentIndex: number, otp?: string) => {
		let submissionImage = '';
		if (
			!sessionData?.userId
			|| !sessionData?.sessionId
		) {
			return;
		}
		try {
			console.log('Taking Screen Shot');
			const canvas = await html2canvas(document.body);
			submissionImage = canvas.toDataURL('image/png', 0.5);
			await submitQuizSegment(segmentIndex, {
				userId: sessionData?.userId,
				sessionId: sessionData?.sessionId,
			}, {
				image: submissionImage,
				otp,
			});
		} catch (error) {
			console.error(error);
		}
	}, [sessionData?.sessionId, sessionData?.userId, submitQuizSegment]);

	const handleSubmitQuiz = useCallback(async (otp?: string) => {
		let submissionImage = '';
		if (!sessionData?.userId || !sessionData?.sessionId) {
			return;
		}
		try {
			setIsSubmitting(true);
			const canvas = await html2canvas(document.body);

			submissionImage = canvas.toDataURL('image/png');
			const redirectLink = await submitQuiz({
				userDetails: {
					userId: sessionData?.userId,
					sessionId: sessionData?.sessionId,
				},
				submissionConfig: {
					image: submissionImage,
					otp,
				},
			});
			if (redirectLink) {
				history.push({
					state: { referrer: '' },
				});
				window.location.replace(`${APP_CONFIG.QuizServerURL}${redirectLink}`);
			}
		} catch (e: any) {
			logger.error(e);
			setIsSubmitting(false);

			message.error(e.message, 10);
		}
	}, [history, submitQuiz, sessionData?.userId, sessionData?.sessionId]);

	const confirmQuizSubmission = useCallback(async () => {
		const otp = generateOtp(4);
		if (!sessionData?.userId || !sessionData?.sessionId) {
			return;
		}
		const popupMessage = (
			<p>
				Please use&nbsp;
				<strong>
					{otp}
				</strong>
				&nbsp;to submit test.
			</p>
		);
		const result = await new Promise((resolve, reject) => {
			const onOk = async () => {
				try {
					const input = otpInputRef.current;
					let inputValue = '';
					if (input) {
						inputValue = input.value;
						if (!inputValue) {
							message.error('Please enter otp to submit');
							return;
						}
						if (inputValue !== otp.toString()) {
							message.error('The otp provided in input does not match the given otp.');
							return;
						}
					}
					await handleSubmitQuiz(inputValue);
				} catch (error) {
					console.error(error);
				} finally {
					resolve(true);
				}
			};
			const onCancel = async () => {
				resolve(false);
			};
			modal.confirm({
				title: popupMessage,
				content: (
					<div className="otp-input-confirm">
						<input type="text" placeholder="Enter Otp" ref={otpInputRef} />
					</div>
				),
				okText: 'Submit',
				closable: true,
				cancelButtonProps: {
					style: {
						display: 'none',
					},
				},
				onOk,
				onCancel,
			});
		});
	}, [sessionData?.userId, sessionData?.sessionId, modal, handleSubmitQuiz]);

	const confirmSubmission = useCallback(async (segmentIndex: number) => {
		if (
			!sessionData?.userId
			|| !sessionData?.sessionId
		) {
			return;
		}
		const otp = generateOtp(4);
		const currentSegment = quizDashboardData?.segments?.[segmentIndex];
		const popupMessage = (!currentSegment)
			? (
				<p>
					Please use&nbsp;
					<strong>
						{otp}
					</strong>
					&nbsp;to submit this section.
				</p>
			) : (
				<p>
					Please use&nbsp;
					<strong>
						{otp}
					</strong>
					&nbsp;to submit
					&nbsp;
					{currentSegment.title}
					.
				</p>
			);
		const result = await new Promise((resolve, reject) => {
			const onOk = async () => {
				try {
					const input = otpInputRef.current;
					let inputValue = '';
					if (input) {
						inputValue = input.value;
						if (!inputValue) {
							message.error('Please enter otp to submit');
							return;
						}
						if (inputValue !== otp.toString()) {
							message.error('The otp provided in input does not match the given otp.');
							return;
						}
					}
					await handleSubmitSection(segmentIndex, inputValue);
				} catch (error) {
					console.error(error);
				} finally {
					resolve(true);
				}
			};
			const onCancel = async () => {
				resolve(false);
			};
			modal.confirm({
				title: popupMessage,
				content: (
					<div className="otp-input-confirm">
						<input type="text" placeholder="Enter Otp" ref={otpInputRef} />
					</div>
				),
				okText: 'Submit',
				closable: true,
				cancelButtonProps: {
					style: {
						display: 'none',
					},
				},
				onOk,
				onCancel,
			});
		});
	}, [
		sessionData?.userId, sessionData?.sessionId, quizDashboardData?.segments,
		modal, handleSubmitSection,
	]);

	useEffect(() => {
		if (quizData?.revisitAllowed) {
			setShowSectionSubmit(false);
		} else if (quizDashboardData && quizDashboardData?.segments.length <= 1) {
			setShowSectionSubmit(false);
		} else {
			setShowSectionSubmit(true);
		}
	}, [quizData, quizDashboardData]);

	return (
		<div className="quiz-dashboard-wrapper">
			{
				quizDashboardData?.segments.map((segment, index, segments) => (
					<DashboardSegment
						key={segment._id}
						quizName={quizName}
						segmentData={segment}
						segmentIndex={index}
						sendSubmitButtonEvent={sendButtonSubmissionEvent}
						onSubmit={confirmSubmission}
						showSubmit={showSectionSubmit && index !== segments.length - 1}
					/>
				))
			}
			{contextHolder}
			<div style={{ position: 'relative' }}>
				<Popconfirm
					title="Do you really want to submit the test?"
					onConfirm={confirmQuizSubmission}
					okText="yes"
					cancelText="no"
					getPopupContainer={(trigger) => trigger.parentElement || document.body}
				>
					<Button
						type="primary"
						size="large"
						loading={isSubmitting}
					>
						Submit Test
					</Button>
				</Popconfirm>
			</div>
		</div>
	);
};
