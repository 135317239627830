import { WechatOutlined } from '@ant-design/icons';
import {
	Badge, Button, Input,
	InputRef, message, Popover,
} from 'antd';
import React, {
	useEffect, useMemo,
	useRef, useState,
} from 'react';

import './live-chat.scope.css';

interface LiveChatProps {
	messages: Array<MessageObj>
	messageInputValue: string,
	setMessageInputValue: (data: string) => void
	sendMessage: (data: string) => void
	// setOpenMessageContainer: (data: boolean) => void
}

export const LiveChat = (props: LiveChatProps) => {
	const {
		messages, messageInputValue,
		sendMessage, setMessageInputValue,
	} = props;

	const popupRef = useRef();
	const sendButtonRef = useRef<HTMLButtonElement>(null);
	const messageInputRef = useRef<InputRef>(null);
	const [newMessage, setNewMessage] = useState<boolean>(false);

	const [openMessageCOntainer, setOpenMessageContainer] = useState<boolean>(false);
	const lastElementRef = useRef<HTMLDivElement>(null);
	const lastElementView = useRef<any>(null);
	const messagePopupContent = useMemo(() => {
		setTimeout(() => {
			lastElementRef.current?.scrollIntoView();
		}, 300);
		return (
			<>
				<div className="message-container">
					{messages.map((currentMessage, index) => (
						<div
							key={currentMessage.messageId}
							ref={lastElementRef}
							className={
								`messages ${currentMessage.currentUserMessage ? 'user-message-container' : 'received-message-container'}`
							}
						>
							<p className={currentMessage.currentUserMessage ? 'user-message' : 'received-message'}>{currentMessage.message}</p>
						</div>
					))}
				</div>
			</>
		);
	}, [messages]);

	useEffect(() => {
		const areAllMessageSeen = lastElementRef.current
			&& (lastElementRef.current === lastElementView.current);
		if (!areAllMessageSeen) {
			lastElementView.current = lastElementRef.current;
		}
		if (!openMessageCOntainer && !areAllMessageSeen && messages.length) {
			setNewMessage(true);
		}
	}, [messages, messagePopupContent, openMessageCOntainer]);

	useEffect(() => {
		if (openMessageCOntainer && newMessage) {
			lastElementView.current?.scrollIntoView();
		}
	}, [openMessageCOntainer, newMessage]);

	useEffect(() => {
		if (openMessageCOntainer) {
			setNewMessage(false);
		}
	}, [openMessageCOntainer]);

	return (
		<div id="room-chat">
			<Badge status="error" size="default" dot={newMessage} offset={[-10, 10]}>
				<Popover
					open={openMessageCOntainer}
					onOpenChange={setOpenMessageContainer}
					id="message-popover"
					placement="topRight"
					showArrow={false}
					title="Messages"
					trigger="click"
					ref={popupRef}
					content={
						(
							<div>
								{messagePopupContent}
								<div style={{ display: 'flex', gap: '10px', padding: '5px 20px' }}>
									<Input
										onKeyDown={(ev) => {
											if (ev.key === 'Enter') {
												console.log('Please Send The Message');
												if (sendButtonRef.current) {
													sendButtonRef.current.click();
												}
											}
										}}
										onChange={(ev) => setMessageInputValue(ev.target.value)}
										value={messageInputValue}
										style={{ borderRadius: '5px' }}
										ref={messageInputRef}
										placeholder="Type your messages here.."
									/>
									<Button
										ref={sendButtonRef}
										style={{ borderRadius: '5px' }}
										onClick={() => {
											sendMessage(messageInputValue);
											setMessageInputValue('');
										}}
										type="primary"
									>
										Send
									</Button>
								</div>
							</div>
						)
					}
				>
					<Button
						id="room-chat-button"
						type="primary"
						size="large"
						icon={<WechatOutlined size={23} />}
					/>
				</Popover>
			</Badge>
		</div>
	);
};
