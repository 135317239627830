import React, {
	useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { matchPath, useHistory, useRouteMatch } from 'react-router-dom';
import {
	message, Spin, Modal, Button,
	notification,
} from 'antd';
import { CheckOutlined, LeftCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { debounce, isElement } from 'lodash';
import './app.scoped.css';
import { MainScreen } from './screens';
import {
	ProctorState, useAiProctorStore, useAppStore, useQuizStore, useSessionStore,
} from './store';
import { logger } from './libs/utils/logger';

import { ReactComponent as Loader } from './assets/icons/loader.svg';
import { InvigilatorCam } from './components/invigilator-cam';
import {
	CheatingType, TabSwitchEventType, QuestionType, WebcamStateKind, APP_CONFIG, FullScreenEventType,
	isElectronApp, ScreenShareState, FileUploadEvent, localStorageKeyForVmDetection,
} from './config';
import { Jaas } from './components/jaas';
import { AIProctor } from './libs/object-dection/object-detection';

const aiProctorSelector = (state: ProctorState) => ({
	noUserPresent: state.noUserPresent,
	multipleUserFound: state.multipleUserFound,
	aiProctor: state.aiProctoring,
	setAiProctoring: state.setAiProctoring,
	startProctoring: state.startProctoring,
});

const appStoreSelector = (state: AppState) => ({
	loading: state.loading,
	loadingText: state.loadingText,
	openInApp: state.openInApp,
	appModalShown: state.appModalShown,
	screenShareState: state.screenShareState,
	videoElement: state.videoElement,

	setVideoElement: state.setVideoElement,
	setAppLoading: state.setAppLoading,
	openLinkInApp: state.openLinkInApp,
	setAppModalShown: state.setAppModalShown,
	setScreenShareState: state.setScreenShareState,
});

const sessionStoreSelector = (state: SessionState) => ({
	isAuthenticated: state.isAuthenticated,
	sessionData: state.sessionData,
	getSessionData: state.getSessionData,
});

const quizStoreSelector = (state: QuizState) => ({
	quizData: state.quizData,
	attemptQuestionData: state.attemptQuestionData,
	hasStarted: state.hasStarted,
	timeLeft: state.timeLeft,
	serverTime: state.serverTime,
	uploadingAllRecording: state.uploadingAllRecording,
	liveStreamRoomName: state.liveStreamRoomName,
	isAiProctorEnabled: state.isAiProctorEnabled,
	isRecordingEnabled: state.isRecordingEnabledFromConfig,
	screenShareStream: state.screenShareStream,

	setScreenShareStream: state.setScreenShareStream,
	userRemovedFromRoom: state.userRemovedFromRoom,
	setQuizTimeLeft: state.setQuizTimeLeft,
	submitQuiz: state.submitQuiz,
	getQuizData: state.getQuizData,
	getQuizDashboardData: state.getQuizDashboardData,
	sendImageData: state.sendImageData,
	sendCheatingDetails: state.sendCheatingDetails,
	getServerTime: state.getServerTime,
	incrementByOneSecServerTime: state.incrementByOneSecServerTime,
	setTimeLeft: state.setQuizTimeLeft,
	incrementLocalTabCount: state.incrementLocalTabCount,
	getLiveStreamRoom: state.getLiveStreamRoom,
	uploadEvent: state.uploadEvent,
	uploadEventError: state.uploadErrorEvent,
	stopRecording: state.stopRecording,
	startRecording: state.startRecording,
});

export default function App(): JSX.Element {
	const history = useHistory();
	const routeMatch = useRouteMatch();
	const {
		loading, loadingText, setAppLoading, openInApp, openLinkInApp, setAppModalShown,
		appModalShown, screenShareState, setScreenShareState, videoElement, setVideoElement,
	} = useAppStore(appStoreSelector);

	const {
		multipleUserFound, noUserPresent, setAiProctoring, aiProctor, startProctoring,
	} = useAiProctorStore(aiProctorSelector);

	const { isAuthenticated, sessionData, getSessionData } = useSessionStore(sessionStoreSelector);
	const {
		quizData, attemptQuestionData, hasStarted, timeLeft, uploadingAllRecording,
		liveStreamRoomName, isAiProctorEnabled, isRecordingEnabled, screenShareStream,
		setQuizTimeLeft, getQuizData, setScreenShareStream,
		getQuizDashboardData, sendImageData, sendCheatingDetails, submitQuiz, setTimeLeft,
		getServerTime, incrementByOneSecServerTime, incrementLocalTabCount, getLiveStreamRoom,
		serverTime, uploadEvent, uploadEventError, startRecording, stopRecording, userRemovedFromRoom,
	} = useQuizStore(quizStoreSelector);

	const enableCamera = useMemo(() => quizData?.isWebCamAllowed, [
		quizData?.isWebCamAllowed,
	]);

	const historyOnPopState = useCallback(() => {
		history.go(1);
	}, [history]);

	const quizMatchParams = useMemo(() => matchPath<{ quizName: string }>(history.location.pathname, {
		path: '/test/:quizName',
		strict: true,
	})?.params, [history]);

	const [invigilatorCamStream, setInvigilatorCamStream] = useState<MediaStream | null>(null);
	const [jitsiWebCanStream, setJitsiWebCamStream] = useState<any | null>(null);
	const [webcamState, setWebcamState] = useState<number>(
		enableCamera ? WebcamStateKind.Loading : WebcamStateKind.Disabled,
	);
	const [fullScreenModalVisible, setFullScreenModalVisible] = useState<boolean>(false);
	const [isSubjectiveFileQuestion, setIsSubjectiveFileQuestion] = useState<boolean>(false);
	const [appErrorModalVisible, setErrorModalVisible] = useState<boolean>(false);
	const [loadingMultipleScreenRetry, setLoadginMultipleScreenRetry] = useState<boolean>(false);
	// const [jaasRoom, setJaasRoom] = useState<any>(null);
	const [multipleCamera, setMultipleCamera] = useState<boolean>(false);
	const [webCamList, setWebCamList] = useState<Array<string>>([]);
	const tabSwitchAlertCount = useRef<number>(0);
	const [errorWhileUploading, setErrorWhileUploading] = useState<boolean>(false);

	useEffect(() => {
		let aiProctoringToSent: AIProctor | null = aiProctor;
		if (quizData?.isAppOnly && !isElectronApp) {
			setAiProctoring(null);
			return () => { }
		}
		if (videoElement && !aiProctor && isAiProctorEnabled && quizData?.isWebCamAllowed) {
			// Create AI Proctor Here
			aiProctoringToSent = new AIProctor(videoElement, {
				frequency: 2000,
				webWorker: true,
			});
			setAiProctoring(aiProctoringToSent);
		}
		if (
			aiProctoringToSent
			&& (!isAiProctorEnabled || !quizData?.isWebCamAllowed)
		) {
			setAiProctoring(null);
		}
		return () => {
			if (aiProctoringToSent && !isAiProctorEnabled) {
				setAiProctoring(null);
			}
		};
	}, [
		videoElement,
		quizData?.isAppOnly,
		sessionData?.userId,
		aiProctor, isAiProctorEnabled,
		quizData?.isWebCamAllowed,
		setAiProctoring,
	]);

	useEffect(() => {
		if (aiProctor) {
			aiProctor.setSessionData(sessionData);
		}
	}, [aiProctor, sessionData]);

	useEffect(() => {
		if (videoElement && aiProctor && sessionData?.userId) {
			startProctoring();
		}
	}, [videoElement, aiProctor, sessionData?.userId, startProctoring]);

	useEffect(() => {
		if (isElectronApp && 'api' in window) {
			(window.api as any).receiveFromMain('electron-strict-mode-lock', (data: boolean) => {
				setErrorModalVisible(data);
			});
		}
	}, [quizData?.allowClose, setErrorModalVisible]);

	useEffect(() => {
		if (isElectronApp && 'api' in window) {
			(window.api as any).receiveFromMain('electron-strict-mode-lock', (data: boolean) => {
				setErrorModalVisible(data);
			});
		}
	}, [quizData?.allowClose, setErrorModalVisible]);

	const refreshMonitorCheck = useCallback(async () => {
		setLoadginMultipleScreenRetry(true);
		if ('api' in window) {
			await (window.api as any).sendToMain('retryMultiScreen', true);
		}
		setLoadginMultipleScreenRetry(false);
	}, []);

	const sendTabSwitchNotification = useCallback(() => {
		logger.info('switching tabs...!');
		incrementLocalTabCount();
		if (hasStarted && !(!isElectronApp && quizData?.isAppOnly && quizData.isPrivate)
			&& !(attemptQuestionData?.type
				&& (+attemptQuestionData?.type
					=== QuestionType.Subjective || +attemptQuestionData?.type === QuestionType.Web))) {
			if (tabSwitchAlertCount.current < 3) {
				message.error('Tab switching not allowed!');

				tabSwitchAlertCount.current += 1;
			}
			console.log('Tab Switch Event');
			sendCheatingDetails(CheatingType.TabSwitch, TabSwitchEventType.Out);
		}
	}, [incrementLocalTabCount,
		hasStarted,
		quizData,
		attemptQuestionData?.type,
		sendCheatingDetails,
	]);

	const sendTabFocusNotification = debounce(useCallback(() => {
		logger.info('window focus....!', Date.now());
		if (hasStarted && !(!isElectronApp && quizData?.isAppOnly && quizData.isPrivate)
			&& !(attemptQuestionData?.type
				&& (+attemptQuestionData?.type
					=== QuestionType.Subjective || +attemptQuestionData?.type === QuestionType.Web))) {
			if (tabSwitchAlertCount.current < 3) {
				message.error('Tab switching not allowed!');

				tabSwitchAlertCount.current += 1;
			}
			sendCheatingDetails(CheatingType.TabSwitch, TabSwitchEventType.In);
		}
	}, [attemptQuestionData?.type,
		hasStarted, quizData, sendCheatingDetails]), 100);

	const sendLockScreenNotification = debounce(useCallback((date: number | undefined) => {
		sendCheatingDetails(CheatingType.ScreenLock, TabSwitchEventType.Out, date);
	}, [sendCheatingDetails]));

	const sendUnlockScreenNotification = debounce(useCallback(() => {
		sendCheatingDetails(CheatingType.ScreenLock, TabSwitchEventType.In);
	}, [sendCheatingDetails]));

	const handleFocusEvent = useCallback((event: FocusEvent) => {
		if (event.target !== window) return;
		sendTabFocusNotification();
	}, [sendTabFocusNotification]);

	const handleBlurEvent = useCallback((event: FocusEvent) => {
		if (event.target !== window) return;
		sendTabSwitchNotification();
	}, [sendTabSwitchNotification]);

	const sendWebcamBlockNotification = useCallback(() => {
		logger.info('blocking webcam...!');

		sendCheatingDetails(CheatingType.WebcamBlock);
	}, [sendCheatingDetails]);

	const checkForMultipleCamera = useCallback(async () => {
		try {
			const devicesAvailable = await navigator.mediaDevices.enumerateDevices();
			let countOfVideoDevices = 0;
			const cameraList: Array<string> = [];
			(devicesAvailable ?? []).forEach((element) => {
				if (element.kind === 'videoinput') {
					countOfVideoDevices += 1;
					cameraList.push(element.label);
				}
			});
			setWebCamList(cameraList);
			console.log('User Devices', devicesAvailable);
			if (countOfVideoDevices > 1) {
				setMultipleCamera(true);
				return;
			}
			setMultipleCamera(false);
		} catch (error) {
			console.error(error);
		}
	}, [setMultipleCamera, setWebCamList]);

	const onStreamStop = useCallback(() => {
		setWebcamState(WebcamStateKind.Blocked);

		sendWebcamBlockNotification();
		if ('api' in window) {
			const restartCamInterval = setInterval(async () => {
				try {
					setWebcamState(WebcamStateKind.Loading);
					const tracks = await JitsiMeetJS.createLocalTracks({
						devices: ['video'],
						resolution: quizData?.streamBitrate ?? '360',
					});
					if (!tracks.length) {
						throw new Error('Something Went Wrong');
					}
					const jitsiStream = tracks[0];

					// console.log(stream);
					let stream = new MediaStream();
					stream.addTrack(jitsiStream.getTrack());
					let streamForMedia: null | MediaStream = null;
					try {
						streamForMedia = await navigator.mediaDevices.getUserMedia({ video: true });
					} catch (error) {
						console.error(error);
					}
					stream = streamForMedia ?? stream;
					setWebcamState(WebcamStateKind.Streaming);
					setJitsiWebCamStream(jitsiStream);
					stream.addEventListener('inactive', onStreamStop, false);
					setInvigilatorCamStream(stream);
					clearInterval(restartCamInterval);
				} catch (error) {
					console.error(error);
				}
			}, 1000);
		}
	}, [quizData?.streamBitrate, sendWebcamBlockNotification]);

	// const handleScreenShare = useCallback(async () => {
	// 	try {
	// 		const stream = await navigator.mediaDevices.getDisplayMedia({
	// 			video: {
	// 				displaySurface: 'browser',
	// 			},
	// 		} as any);

	// 		const videoTrack = stream.getVideoTracks()[0];
	// 		const settings = videoTrack.getSettings();
	// 		if (!settings.displaySurface) {
	// 			console.log('Need to find a way to stop this behaviour');
	// 		} else if (settings.displaySurface !== 'browser') {
	// 			// stream.getTracks().forEach((track) => track.stop());
	// 			// throw new Error('Screen sharing must be restricted to the current tab!');
	// 		}

	// 		setScreenShareState(ScreenShareState.Active);
	// 		setScreenShareStream(videoTrack);
	// 	} catch (error) {
	// 		console.log(error);
	// 		setScreenShareState(ScreenShareState.Error);
	// 	}
	// }, [setScreenShareStream, setScreenShareState]);

	const handleScreenShareStreamStop = useCallback(() => {
		console.trace('Screen Share Stoped');
		setScreenShareState(ScreenShareState.Loading);
	}, [setScreenShareState]);

	useMemo(async () => {
		if (quizData && sessionData && quizData?.isLiveStreamEnabled) {
			await getLiveStreamRoom();
		}
	}, [quizData, sessionData, getLiveStreamRoom]);

	useEffect(() => {
		if (screenShareStream) {
			screenShareStream.addEventListener('ended', handleScreenShareStreamStop);
		}
		return () => {
			if (screenShareStream) {
				screenShareStream.removeEventListener('ended', handleScreenShareStreamStop);
			}
		};
	}, [screenShareStream, handleScreenShareStreamStop]);

	// ------------------------ Cheating Event Handler ---------------------------------------------//
	// Event Handler For Electron

	useEffect(() => {
		if (!isAuthenticated || !quizData?.quizId) {
			return () => { };
		}
		if (
			isElectronApp && 'api' in window && window.api
			&& typeof window.api === 'object' && 'receiveFromMain' in window.api
			&& window.api.receiveFromMain && typeof window.api.receiveFromMain === 'function'
		) {
			window.api.receiveFromMain('lock-screen-event', sendLockScreenNotification);
		}
		return () => {
			if (
				isElectronApp && 'api' in window && window.api
				&& typeof window.api === 'object' && 'removeListener' in window.api
				&& window.api.removeListener && typeof window.api.removeListener === 'function'
			) {
				window.api.removeListener('lock-screen-event');
			}
		};
	}, [isAuthenticated, quizData?.quizId, sendLockScreenNotification]);

	useEffect(() => {
		if (!isAuthenticated || !quizData?.quizId) {
			return () => { };
		}
		if (
			isElectronApp && 'api' in window && window.api
			&& typeof window.api === 'object' && 'receiveFromMain' in window.api
			&& window.api.receiveFromMain && typeof window.api.receiveFromMain === 'function'
		) {
			window.api.receiveFromMain('unlock-screen-event', sendUnlockScreenNotification);
		}
		return () => {
			if (
				isElectronApp && 'api' in window && window.api
				&& typeof window.api === 'object' && 'removeListener' in window.api
				&& window.api.removeListener && typeof window.api.removeListener === 'function'
			) {
				window.api.removeListener('unlock-screen-event');
			}
		};
	}, [isAuthenticated, quizData?.quizId, sendUnlockScreenNotification]);

	useEffect(() => {
		if (isElectronApp && 'api' in window && isAuthenticated) {
			console.log('quiz started');
			(window.api as any).sendToMain('quiz-status', true);
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (isElectronApp && 'api' in window && isAuthenticated && !quizData?.tabSwitchAllowed) {
			(window.api as any).receiveFromMain('tab-switched-in', sendTabFocusNotification);
			(window.api as any).receiveFromMain('tab-switched-out', sendTabSwitchNotification);
		}

		return () => {
			try {
				if (isElectronApp && 'api' in window) {
					if (isAuthenticated) {
						(window.api as any).removeListener('tab-switched-in');
						(window.api as any).removeListener('tab-switched-out');
					}
				}
			} catch (error) {
				// alert(error);
			}
		};
	}, [
		isAuthenticated,
		sendTabFocusNotification,
		sendTabSwitchNotification,
		quizData?.tabSwitchAllowed,
	]);

	// IF NOT ELECTRON
	useEffect(() => {
		if (isElectronApp) return;

		if (!quizData?.tabSwitchAllowed) {
			window.addEventListener('focus', handleFocusEvent, { capture: true });
		}

		// eslint-disable-next-line consistent-return
		return () => window.removeEventListener('focus', handleFocusEvent, { capture: true });
	}, [quizData?.tabSwitchAllowed, handleFocusEvent]);
	useEffect(() => {
		if (isElectronApp) return;
		let isWebCamLoaded = true;
		if (enableCamera && webcamState === WebcamStateKind.Loading) {
			isWebCamLoaded = false;
		} else {
			isWebCamLoaded = true;
		}
		if (!quizData?.tabSwitchAllowed && isWebCamLoaded) {
			window.addEventListener('blur', handleBlurEvent, { capture: true });
		}

		// eslint-disable-next-line consistent-return
		return () => window.removeEventListener('blur', handleBlurEvent, { capture: true });
	}, [
		quizData?.tabSwitchAllowed,
		enableCamera, webcamState, handleBlurEvent,
	]);

	// ------------------------------------ END -----------------------------------------------//
	// useEffect(() => {
	// 	window.addEventListener('contextmenu', (e: MouseEvent) => {
	// 		e.preventDefault();
	// 	});
	// }, []);

	useEffect(() => {
		(async () => {
			// await setAppLoading(true, 'authenticating...');
			await setAppLoading(true);

			try {
				if (quizMatchParams?.quizName) {
					await getQuizData(quizMatchParams.quizName);
				} else {
					logger.error('quiz name not found.');
					history.replace('/error/404');
				}
			} catch (e) {
				logger.error('error while getting quiz data ===> ', e);
				history.replace('/error/404');
			}

			try {
				await getSessionData();
			} catch (e) {
				logger.error('error while getting session data ===> ', e);

				setAppLoading(false);
			}
		})();
	}, [quizMatchParams, history, setAppLoading, getQuizData, getSessionData]);

	useEffect(() => {
		(async () => {
			if (isAuthenticated) {
				try {
					// await setAppLoading(true, 'fetching data, please wait...');
					await setAppLoading(true);

					await getQuizDashboardData();
				} finally {
					setAppLoading(false);
				}
			}
		})();
	}, [isAuthenticated, setAppLoading, getQuizDashboardData]);

	useEffect(() => {
		window.addEventListener('popstate', historyOnPopState);

		return () => window.removeEventListener('popstate', historyOnPopState);
	}, [historyOnPopState]);

	useEffect(() => {
		try {
			if (invigilatorCamStream) {
				checkForMultipleCamera();
			}
		} catch (err) {
			console.log(err);
		}
	}, [checkForMultipleCamera, invigilatorCamStream]);

	useEffect(() => {
		let stream: MediaStream;
		(async () => {
			if (quizData?.isWebCamAllowed) {
				if (quizData?.isAppOnly && !('api' in window)) return;
				try {
					setWebcamState(WebcamStateKind.Loading);
					if (openInApp) return;
					const tracks = await JitsiMeetJS.createLocalTracks({
						devices: ['video'],
						resolution: quizData?.streamBitrate ?? '360',
					});
					if (!tracks.length) {
						throw new Error('Something Went Wrong');
					}
					const jitsiStream = tracks[0];

					// console.log(stream);
					stream = new MediaStream();
					let streamForMedia: null | MediaStream = null;
					try {
						streamForMedia = await navigator.mediaDevices.getUserMedia({ video: true });
					} catch (error) {
						console.error(error);
					}
					stream = streamForMedia ?? stream;
					setWebcamState(WebcamStateKind.Streaming);
					setJitsiWebCamStream(jitsiStream);
					stream.addEventListener('inactive', onStreamStop, false);
					setInvigilatorCamStream(stream);
				} catch (e: any) {
					logger.error(e);
					logger.error(e.name, e.code);
					if (e.name === 'NotAllowedError') {
						setWebcamState(WebcamStateKind.Blocked);
					} else {
						setWebcamState(WebcamStateKind.Error);
					}
					sendWebcamBlockNotification();
				}
			}
		})();

		// eslint-disable-next-line consistent-return
		return () => {
			stream?.getTracks().forEach((track) => {
				track.stop();
			});

			stream?.removeEventListener('inactive', onStreamStop);
		};
	}, [
		quizData,
		openInApp,
		onStreamStop,
		setScreenShareState,
		sendWebcamBlockNotification]);

	useEffect(() => {
		let timeOutId: NodeJS.Timeout;
		if (isElectronApp && window.location.href.includes('login')) {
			timeOutId = setTimeout(() => {
				if ('api' in window) (window.api as any).sendToMain('change-closeable-state', true);
			}, 5000);
		}
		return () => {
			if (timeOutId) {
				clearTimeout(timeOutId);
			}
		};
	}, [routeMatch]);

	useEffect(() => {
		if (quizData && isElectronApp) {
			if ('api' in window) {
				(window.api as any).sendToMain('quizId', quizData.quizId);
			}
		}
		if (quizData && isAuthenticated && isElectronApp) {
			if ('api' in window) {
				setTimeout(() => {
					if ('api' in window) (window.api as any).sendToMain('change-closeable-state', quizData.allowClose);
				}, 1000);
			}
		}
		if (!isAuthenticated && quizData && isElectronApp) {
			if ('api' in window) {
				(window.api as any).sendToMain('pre-login-test', !quizData.allowClose);
			}
		}
		if (!isAuthenticated && quizData && isElectronApp) {
			if ('api' in window) {
				(window.api as any).sendToMain('pre-login-test', !quizData.allowClose);
			}
		}
	}, [isAuthenticated, quizData]);

	useEffect(() => {
		let interval: any;
		const Fun = async () => {
			await getServerTime();
			interval = setInterval(() => {
				incrementByOneSecServerTime();
			}, 1000);
		};
		Fun();
		return () => {
			clearInterval(interval);
		};
	}, [getServerTime, incrementByOneSecServerTime]);

	const uploadUserImage = useCallback(async (data: unknown) => {
		if (!sessionData?.sessionId || !sessionData.userId) {
			logger.error('invigilator error: userId/sessionId not found.');
			return;
		}

		await sendImageData(sessionData.userId, sessionData.sessionId, data);
	}, [sessionData, sendImageData]);

	const switchToFullScreen = useCallback(() => {
		if (isAuthenticated && quizData?.isFullScreen) {
			document.body.requestFullscreen();
			setFullScreenModalVisible(false);
		}
	}, [isAuthenticated, quizData?.isFullScreen]);

	const fullScreenChangeHandler = useCallback((e: any) => {
		if (e.matches) {
			setFullScreenModalVisible(false);

			// send event for full screen IN
			sendCheatingDetails(CheatingType.fullScreen, FullScreenEventType.In);
		} else {
			setFullScreenModalVisible(true);

			// send event for full screen OUT
			sendCheatingDetails(CheatingType.fullScreen, FullScreenEventType.Out);
		}
	}, [sendCheatingDetails, setFullScreenModalVisible]);

	// !TO REMOVE THIS AFTER THE OFFICIAL BUILD
	useEffect(() => {
		if (quizData?.quizId && sessionData?.userId) {
			if (
				'api' in window && window.api && typeof window.api === 'object'
				&& 'checkIfVM' in window.api && typeof window.api.checkIfVM === 'function'
			) {
				const funcToCall = window.api.checkIfVM;
				(async () => {
					try {
						const data = await funcToCall();
						if (!data || typeof data !== 'object') {
							return;
						}
						data.userId = sessionData.userId;
						data.quizId = quizData.quizId;
						let logs = '';
						if ('logs' in data) {
							logs = data.logs;
							try {
								const rowResponse = await fetch('https://app-user-logs.cqtestga.com', {
									method: 'POST',
									body: JSON.stringify(data),
									headers: {
										'Content-Type': 'application/json',
									},
								});
							} catch (err) {
								console.error(err);
							}
						}
						if ('isVM' in data && data.isVM) {
							const rowResponse = await fetch(`${APP_CONFIG.QuizServerURL}/vmdetected`, {
								method: 'POST',
								body: JSON.stringify({
									userId: sessionData?.userId,
									quizId: quizData.quizId,
									logs,
								}),
								headers: {
									'Content-Type': 'application/json',
								},
								credentials: 'include',
							});
							if (rowResponse.status === 401) {
								localStorage.setItem(localStorageKeyForVmDetection, JSON.stringify(Date.now()));
								window.location.reload();
							}
						}
					} catch (error: any) {
						console.log(error);
					}
				})();
			}
		}
	}, [quizData?.quizId, sessionData?.userId]);

	const handleVideoEvent = useCallback((
		quizId: string, userId: string, eventType: FileUploadEvent, payload: { [key: string]: string },
	) => {
		uploadEventError({
			quizId,
			userId,
			payload,
			eventType,
		});
	}, [uploadEventError]);

	const handleUserKickedFromConference = useCallback(async (roomName) => {
		console.log('User is Kicked From the Conference');
		userRemovedFromRoom({ roomName });
	}, [userRemovedFromRoom]);

	useEffect(() => {
		const query = matchMedia('(display-mode: fullscreen)');

		if (isAuthenticated && quizData?.isFullScreen && !(!isElectronApp && quizData.isPrivate)) {
			setFullScreenModalVisible(true);

			document.addEventListener('fullscreenchange', () => {
				if (!document.fullscreenElement) {
					setFullScreenModalVisible(true);
				}
			});

			fullScreenChangeHandler(query);
			query.addEventListener('change', fullScreenChangeHandler);
		} else {
			query.removeEventListener('change', fullScreenChangeHandler);
		}
	}, [isAuthenticated,
		quizData?.isFullScreen,
		quizData?.isPrivate,
		switchToFullScreen,
		fullScreenChangeHandler,
	]);

	useEffect(() => {
		let intervalId: NodeJS.Timeout;
		if (enableCamera && webcamState === WebcamStateKind.Blocked) {
			// console.log('timeLefttimeLefttimeLeft', timeLeft);
			(async () => {
				if (typeof timeLeft === 'number') {
					if (timeLeft > 0) {
						intervalId = setInterval(() => setQuizTimeLeft?.(timeLeft - 1), 1000);
					} else {
						const redirectLink = await submitQuiz?.({ isAutoSubmit: true });
						if (redirectLink) {
							window.location.replace(`${APP_CONFIG.QuizServerURL}${redirectLink}`);
						}
					}
				}
			})();
		}
		return () => clearInterval(intervalId);
	}, [quizData?.isWebCamAllowed, webcamState, timeLeft, setQuizTimeLeft, submitQuiz, enableCamera]);

	// disabling the keys on windows and mac to stop cheating
	useEffect(() => {
		document.addEventListener('contextmenu', (event) => event.preventDefault());
		document.onkeydown = (e) => {
			if (e.altKey && e.keyCode === 115 && isElectronApp) {
				if ('api' in window) {
					(window.api as any).sendToMain('close', true);
				}
			}
			// disable ctrl+shift, ctrl+shift+E, ctrl+T, ctrl+N, ctrl+P, ctrl+W, F11, F12, Esc, alt
			// ctrl+tab, f4, ctrl+pgdn, ctrl+pgup, ctrl+9, ctrl+U
			// command+shift, command+shift+E, command+T, command+N, command+P, command+W, command+9
			if ((e.ctrlKey && e.shiftKey) || (e.ctrlKey && e.shiftKey && e.keyCode === 69)
				|| (e.ctrlKey && e.keyCode === 84) || (e.ctrlKey && e.keyCode === 78)
				|| (e.ctrlKey && e.keyCode === 80) || (e.ctrlKey && e.keyCode === 87)
				|| (e.keyCode === 122) || (e.keyCode === 123) || (e.keyCode === 27)
				|| e.altKey || (e.ctrlKey && e.keyCode === 57) || (e.ctrlKey && e.keyCode === 105)
				|| (e.ctrlKey && e.keyCode === 9) || e.keyCode === 115
				|| (e.ctrlKey && e.keyCode === 34) || (e.ctrlKey && e.keyCode === 33)
				|| (e.ctrlKey && e.keyCode === 85)
				|| (e.metaKey && e.shiftKey) || (e.metaKey && e.shiftKey && e.keyCode === 69)
				|| (e.metaKey && e.keyCode === 84) || (e.metaKey && e.keyCode === 78)
				|| (e.metaKey && e.keyCode === 80) || (e.metaKey && e.keyCode === 87)
				|| (e.keyCode === 122) || (e.keyCode === 123) || (e.keyCode === 27)
				|| e.altKey || (e.metaKey && e.keyCode === 57) || (e.metaKey && e.keyCode === 105)) {
				return false;
			}

			// stop user to reload with ctrl+R and F5 only if the full screen mode is enableds
			// and fullscreen is allowed
			// or tabswitch not allowed
			if ((e.ctrlKey && e.keyCode === 82) || e.keyCode === 116) {
				if (isAuthenticated && quizData?.isFullScreen && !fullScreenModalVisible) {
					return false;
				}
			}

			return true;
		};
	}, [isAuthenticated, quizData?.isFullScreen, quizData?.tabSwitchAllowed, fullScreenModalVisible]);

	useEffect(() => {
		// added exception for full screen in subjective file upload question
		if (attemptQuestionData && attemptQuestionData.type === QuestionType.Subjective.toString()
			&& attemptQuestionData?.questionTypeSubjective?.isFileUpload
		) {
			setIsSubjectiveFileQuestion(true);
		} else {
			setIsSubjectiveFileQuestion(false);
		}
	}, [attemptQuestionData]);

	useEffect(() => {
		let intervalId: NodeJS.Timeout;
		if (sessionData) {
			(async () => {
				if (typeof timeLeft === 'number') {
					if (timeLeft > 0) {
						intervalId = setInterval(() => setTimeLeft?.(timeLeft - 1), 1000);
					} else {
						try {
							const redirectLink = await submitQuiz?.({ isAutoSubmit: true });
							if (redirectLink) {
								window.location.replace(`${APP_CONFIG.QuizServerURL}${redirectLink}`);
							}
						} catch (error: any) {
							message.error(error?.message ?? error);
						}
					}
				}
			})();
		}

		return () => clearInterval(intervalId);
	}, [timeLeft, submitQuiz, setTimeLeft, sessionData]);

	useEffect(() => {
		if (quizData && quizData.isAppOnly && !quizData.isPrivate && !isAuthenticated
			&& !isElectronApp && serverTime && !appModalShown) {
			const currentTime: any = new Date(serverTime);
			const startTime: any = new Date(quizData.startTime);
			if (currentTime >= startTime && (
				!quizData.endTime || currentTime.getTime() < new Date(quizData.endTime).getTime())) {
				let link = window.location.href;
				if (window.location.href.includes('/invite/')) {
					const token = window.location.href.split('/invite/')[1];
					if (token) {
						link = `${APP_CONFIG.QuizServerURL}/test/invite/${token}`;
					}
				}
				openLinkInApp(link);
				setAppModalShown(true);
			}
		}
	}, [quizData,
		isAuthenticated,
		serverTime,
		setAppModalShown,
		appModalShown,
		sessionData,
		openLinkInApp,
	]);

	useEffect(() => {
		const currentQuizId = quizData?.quizId;
		const currentUserId = sessionData?.userId;
		if (!currentUserId) {
			return () => { };
		}
		if (!currentQuizId) {
			return () => { };
		}

		if (!quizData?.isWebCamAllowed) {
			return () => { };
		}

		if ('api' in window) {
			stopRecording({ forceUpload: false }, currentQuizId, currentUserId);
		}

		if ('api' in window) {
			if (quizData.isRecordingEnabled && isRecordingEnabled) {
				startRecording(currentQuizId, currentUserId);
			}
			(window.api as any).receiveFromMain('upload-failed-file-not-present', (quizId: string, userId: string, payload: { [key: string]: string }) => {
				handleVideoEvent(quizId, userId, FileUploadEvent.FileNotPresent, payload);
			});
			(window.api as any).receiveFromMain('upload-failed-server', (quizId: string, userId: string, payload: { [key: string]: string }) => {
				handleVideoEvent(quizId, userId, FileUploadEvent.ServerError, payload);
			});
			(window.api as any).receiveFromMain('video-uploaded', (quizId: string, userId: string, payload: { [key: string]: string }) => {
				handleVideoEvent(quizId, userId, FileUploadEvent.FileUploaded, payload);
			});
		}
		return () => {
			if (!currentQuizId || !currentUserId) {
				return;
			}
			if ('api' in window) {
				stopRecording({ forceUpload: false }, currentQuizId, currentUserId);
				(window.api as any).removeListener('upload-failed-file-not-present');
				(window.api as any).removeListener('upload-failed-server');
				(window.api as any).removeListener('video-uploaded');
			}
		};
	}, [
		quizData?.quizId,
		sessionData?.userId,
		quizData?.isWebCamAllowed,
		quizData?.isRecordingEnabled,
		isRecordingEnabled,
		stopRecording,
		startRecording,
		setErrorWhileUploading,
		handleVideoEvent,
	]);

	useEffect(() => {
		if (noUserPresent) {
			notification.warning({
				message: 'No Face Detected',
				description: `We couldn't detect your face.
				Please adjust your camera to ensure your face is fully visible.
				This is important for maintaining the integrity of the assessment.`,
			});
		}
	}, [noUserPresent]);

	// useEffect(() => {
	// 	if (jaasRoom && sessionData) {
	// 		jaasRoom.setDisplayName(sessionData.displayName);
	// 	}
	// }, [jaasRoom, sessionData]);

	useEffect(() => {
		const handler = () => {
			console.log('Monitoring Is Not Working');
		};
		if (sessionData?.userId && quizData?.quizId) {
			if ('api' in window && window.api && typeof window.api === 'object') {
				(window.api as any).receiveFromMain('monitor-not-working', handler);
			}
		}
		return () => {
			if (sessionData?.userId && quizData?.quizId) {
				if ('api' in window && window.api && typeof window.api === 'object') {
					(window.api as any).removeListener('monitor-not-working');
				}
			}
		};
	}, [sessionData, quizData]);

	const renderer = useCallback(() => {
		const showWebCamModal = (enableCamera) ? [
			WebcamStateKind.Blocked,
			WebcamStateKind.Error,
			WebcamStateKind.Loading,
		].includes(webcamState) : false;
		const fullScreenModalOpen = (
			!isElectronApp
			&& (quizData?.isFullScreen ?? false)
			&& fullScreenModalVisible
		);
		const appErrorModalOpen = (
			isElectronApp && appErrorModalVisible
		);

		if (screenShareState === ScreenShareState.Error) {
			return (
				<div className="blocked-webcam-message-container">
					<Loader />
					<p>
						{
							(() => {
								// if (document.getElementsByClassName('ant-modal-root')
								// 	&& document.getElementsByClassName('ant-modal-root')[0]
								// 	&& document.getElementsByClassName('ant-modal-root')[0]
								// 		.innerText.indexOf('Submission') > -1) {
								// 	document.getElementsByClassName('ant-modal-root')[0].remove();
								// }
								if (screenShareState === ScreenShareState.Error) {
									return 'Please provide access to your device\'s screen share permission to move forward.';
								} if (webcamState === WebcamStateKind.Error) {
									return (
										<>
											<span>
												An error occurred while getting your cam stream,
												please check your device settings/permissions.
											</span>
											<br />
											<span>
												-: Try :-
											</span>
											<span>
												<ul>
													<li>Checking your system&apos;s camera permissions.</li>
													<li>Restarting your browser and(or) system.</li>
												</ul>
											</span>
										</>
									);
								}
								return 'getting webcam stream...';
							})()
						}
					</p>
				</div>
			);
		}

		// if (screenShareState === ScreenShareState.Loading && quizData?.isSumbitScreenShotEnabled
		// ) {
		// 	return (
		// 		<Modal
		// 			title="Screen Share"
		// 			visible
		// 			footer={[
		// 				<Button type="primary" onClick={handleScreenShare}>
		// 					Screen Share
		// 				</Button>,
		// 			]}
		// 			closable={false}
		// 			zIndex={10001}
		// 		>
		// 			<p>Please share your screen to continue!</p>
		// 		</Modal>
		// 	);
		// }

		if (false && multipleCamera) {
			return (
				<Modal
					title="Multiple Camera Detected"
					visible
					closable={false}
					zIndex={10001}
					footer={false}
				>
					<p>
						Multiple cameras are detected or some application is providing a camera feed.
						To continue please uninstall all the apps that are providing camera feed and then
						close all the browser windows before retiring.
					</p>
					<p>WebCam List.</p>
					<ul>
						{webCamList.map((camName) => (<li>{camName}</li>))}
					</ul>
				</Modal>
			);
		}

		// if (noUserPresent) {
		// 	return (
		// 		<div>
		// 			<Modal
		// 				title="No face detected"
		// 				visible
		// 				closable={false}
		// 				zIndex={10001}
		// 			>
		// 				<p>Please fix your camera so that your face is visible!</p>
		// 			</Modal>
		// 		</div>
		// 	);
		// }

		if (showWebCamModal) {
			return (
				<div className="blocked-webcam-message-container">
					<Loader />
					<p>
						{
							(() => {
								// if (document.getElementsByClassName('ant-modal-root')
								// 	&& document.getElementsByClassName('ant-modal-root')[0]
								// 	&& document.getElementsByClassName('ant-modal-root')[0]
								// 		.innerText.indexOf('Submission') > -1) {
								// 	document.getElementsByClassName('ant-modal-root')[0].remove();
								// }
								if (webcamState === WebcamStateKind.Blocked) {
									return 'Please provide access to your device\'s webcam to move forward.';
								} if (webcamState === WebcamStateKind.Error) {
									return (
										<>
											<span>
												An error occurred while getting your cam stream,
												please check your device settings/permissions.
											</span>
											<br />
											<span>
												-: Try :-
											</span>
											<span>
												<ul>
													<li>Checking your system&apos;s camera permissions.</li>
													<li>Restarting your browser and(or) system.</li>
												</ul>
											</span>
										</>
									);
								}
								return 'getting webcam stream...';
							})()
						}
					</p>
				</div>
			);
		}

		if (showWebCamModal || fullScreenModalOpen || appErrorModalOpen) {
			return (
				<></>
			);
		}
		return <MainScreen />;
	}, [
		multipleCamera,
		enableCamera,
		appErrorModalVisible,
		fullScreenModalVisible,
		webcamState,
		quizData?.isFullScreen,
		screenShareState,
		webCamList,
	]);

	return (
		<Spin
			wrapperClassName="wrapper"
			spinning={loading}
			indicator={<Loader />}
			size="large"
			tip={loadingText}
		>
			{sessionData?.stream.authToken
				&& quizData?.isWebCamAllowed
				&& quizData?.isLiveStreamEnabled
				&& liveStreamRoomName?.length
				&& (
					liveStreamRoomName.map((roomName) => {
						if (!sessionData.stream.authToken) {
							return <></>;
						}
						return (
							<Jaas
								key={roomName}
								token={sessionData.stream.authToken}
								roomName={roomName}
								// room={jaasRoom}
								// setRoom={setJaasRoom}
								displayName={
									`${sessionData.displayName} ${sessionData.enrollmentId ? `(${sessionData.enrollmentId})` : `(${sessionData.email})`}`
								}
								webCamStream={jitsiWebCanStream}
								onUserKicked={handleUserKickedFromConference}
							/>
						);
					})
				)}
			{isElectronApp ? (
				<Modal
					title="MULTIPLE MONITORS"
					visible={appErrorModalVisible}
					closable={false}
					zIndex={10001}
					footer={[]}
				>
					<p>Please disconnect second screen to continue!</p>
					<Button type="primary" loading={loadingMultipleScreenRetry} onClick={refreshMonitorCheck}>
						Retry
					</Button>
				</Modal>
			) : ''}
			{errorWhileUploading ? (
				<Modal
					title="Error while uploading recording!"
					visible={Boolean(errorWhileUploading)}
					closable={false}
					zIndex={10001}
					footer={[]}
				>
					<p>Something went wrong with upload unable to upload!</p>
				</Modal>
			) : ''}
			<Modal
				title="Submission Under Process"
				visible={uploadingAllRecording}
				footer={false}
				closable={false}
				zIndex={10001}
				bodyStyle={{}}
			>
				<p>
					<span style={{ marginRight: '10px', color: 'green', display: 'inline-block' }}>
						<CheckOutlined />
					</span>
					Test submission.
				</p>
				<p>
					<span style={{ marginRight: '10px', color: 'grey', display: 'inline-block' }}>
						<LoadingOutlined />
					</span>
					Finalizing your submission.
				</p>
			</Modal>
			{
				isAuthenticated && quizData?.isFullScreen && !isElectronApp ? (
					<Modal
						title="FULL SCREEN"
						visible={fullScreenModalVisible}
						footer={[
							<Button type="primary" onClick={switchToFullScreen}>
								Full Screen
							</Button>,
						]}
						closable={false}
						zIndex={10001}
					>
						<p>Your session will be terminated if you do not switch to FullScreen mode!</p>
					</Modal>
				) : ''
			}
			{renderer()}
			{
				quizData?.isWebCamAllowed && (
					<InvigilatorCam
						stream={invigilatorCamStream}
						onUpload={uploadUserImage}
						videoElement={videoElement}
						setVideoElement={setVideoElement}
					/>
				)
			}
		</Spin>
	);
}
