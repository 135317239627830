import React, {
	useCallback, useEffect, useRef, useState,
} from 'react';
import {
	CaretLeftFilled, CaretRightFilled, CheckCircleFilled,
	CheckCircleOutlined, CheckCircleTwoTone, ExclamationCircleTwoTone,
	CloseCircleTwoTone, LeftOutlined, RightOutlined,
} from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import Split from 'react-split';
import { Link, useHistory } from 'react-router-dom';
import { AttemptCoding } from '../../../components/attempt-coding';
import { AttemptMCQ } from '../../../components/attempt-mcq';
import { AttemptMQ } from '../../../components/attempt-mq';
import { AttemptSidebarSegment } from '../../../components/attempt-sidebar-segment';
import { AttemptSubjective } from '../../../components/attempt-subjective';
import { AttemptWeb } from '../../../components/attempt-web';
import {
	CheatingType, QuestionExecutionType, QuestionType, TestCaseStatus,
} from '../../../config';
import { logger } from '../../../libs/utils/logger';
import { useAppStore, useQuizStore, useSessionStore } from '../../../store';

import './quiz-attempt.scoped.css';
import { AttemptQuestionNotLoaded } from '../../../components/attempt-question-not-loaded';

const appStoreSelector = (state: AppState) => ({
	setAppLoading: state.setAppLoading,
});

const quizStoreSelector = (state: QuizState) => ({
	quizData: state.quizData,
	quizDashboardData: state.quizDashboardData,
	submittingQuestion: state.submittingQuestion,
	compiling: state.compiling,
	attemptQuestionData: state.attemptQuestionData,
	nextLink: state.nextLink,
	previousLink: state.previousLink,
	getAttemptQuestionData: state.getAttemptQuestionData,
	removeAttemptQuestionData: state.removeAttemptQuestionData,
	compileCode: state.compileCode,
	setQuestionTestCaseStatus: state.setQuestionTestCaseStatus,
	submitQuestion: state.submitQuestion,
	sendCheatingDetails: state.sendCheatingDetails,
});

const sessionStoreSelector = (state: SessionState) => ({
	sessionData: state.sessionData,
});

interface QuizAttemptScreenProps {
	quizName: string
	questionId: string
}

export const QuizAttemptScreen: React.FunctionComponent<QuizAttemptScreenProps> = (props) => {
	const { questionId, quizName } = props;

	const history = useHistory();

	const { setAppLoading } = useAppStore(appStoreSelector);
	const { sessionData } = useSessionStore(sessionStoreSelector);
	const {
		quizData,
		nextLink,
		previousLink,
		submittingQuestion,
		compiling,
		quizDashboardData,
		attemptQuestionData, compileCode,
		getAttemptQuestionData,
		removeAttemptQuestionData,
		setQuestionTestCaseStatus,
		submitQuestion,
		sendCheatingDetails,
	} = useQuizStore(quizStoreSelector);

	const [sidebarVisible, setSidebarVisible] = useState<boolean>(true);
	const [questionLoadingErrorMessage, setQuestionLoadingErrorMessage] = useState<string>('');

	const confirmModalRef = useRef<any>(null);

	useEffect(() => {
		if (attemptQuestionData?.hasBeenInvalidated) {
			message.warn('Uh Oh! This test has been updated, redirecting you to the dashboard.', 3);
			if (nextLink) {
				history.replace(nextLink);
			}
		}
	}, [history, attemptQuestionData?.hasBeenInvalidated, nextLink]);

	// useEffect(() => {
	// 	if (attemptQuestionData?._id
	// 		&& !quizDashboardData?.questionIds.includes(attemptQuestionData?._id)) {
	// 		message.warn(
	// 			'Uh Oh! This question has been moved/updated, redirecting you to the dashboard.', 3,
	// 		);
	// 		if (nextLink) {
	// 			history.replace(nextLink);
	// 		}
	// 	}
	// }, [history, attemptQuestionData?._id, quizDashboardData?.questionIds, nextLink]);

	useEffect(() => {
		if (attemptQuestionData?.isSegmentSubmitted) {
			message.warn('Question segment has already been submitted, redirecting you to the dashboard.', 1);
			if (nextLink) {
				history.replace(nextLink);
			}
		}
	}, [history, attemptQuestionData?.isSegmentSubmitted, nextLink]);

	useEffect(() => {
		(async () => {
			try {
				setQuestionLoadingErrorMessage('');
				await setAppLoading(false);
				// await setAppLoading(true, 'getting question data...');
				await setAppLoading(true);

				await getAttemptQuestionData(questionId);
			} catch (e: any) {
				logger.error(e);
				setQuestionLoadingErrorMessage(e.message ?? e ?? '');
				message.error(e.message);
			} finally {
				setAppLoading(false);
			}
		})();

		return () => removeAttemptQuestionData();
	}, [
		questionId, setQuestionLoadingErrorMessage,
		getAttemptQuestionData, removeAttemptQuestionData,
		setAppLoading,
	]);

	const setAttempt = useCallback((attemptData: unknown) => {
		window.localStorage.setItem(`${quizData?.quizId}:${sessionData?.userId}:${questionId}`, JSON.stringify(attemptData));
	}, [questionId, quizData, sessionData]);

	const getAttempt = useCallback(() => {
		const data = window.localStorage.getItem(`${quizData?.quizId}:${sessionData?.userId}:${questionId}`);
		if (data) {
			return JSON.parse(data);
		}

		return null;
	}, [questionId, quizData?.quizId, sessionData?.userId]);

	const retryQuestionGet = useCallback(async () => {
		try {
			await setAppLoading(true);
			await getAttemptQuestionData(questionId);
		} catch (e: any) {
			logger.error(e);
			setQuestionLoadingErrorMessage(e.message ?? e ?? '');
			message.error(e.message);
		} finally {
			setAppLoading(false);
		}
	}, [questionId, getAttemptQuestionData, setAppLoading]);

	const handleSubmitQuestion = useCallback(async (data: any) => {
		try {
			await submitQuestion({
				...data,
				sid: sessionData?.sessionId,
			});

			message.success('Your Answer is submitted successfully', 3);

			if (attemptQuestionData?.type && +attemptQuestionData.type === QuestionType.MCQ && nextLink) {
				history.push(nextLink);
			}
		} catch (e: any) {
			logger.error(e);

			message.error(e.message);
		}
	}, [history, sessionData, attemptQuestionData, nextLink, submitQuestion]);

	const goToNextQuestion = useCallback(() => {
		if (nextLink) {
			history.push(nextLink);
		}
	}, [history, nextLink]);

	const goToPreviousQuestion = useCallback(() => {
		if (previousLink) {
			history.push(previousLink);
		}
	}, [history, previousLink]);

	const compileCodeCallback = useCallback(async (data: any) => {
		if (!navigator.onLine) {
			if (confirmModalRef.current) confirmModalRef.current.destroy();
			message.error('Please check your internet connection.');
			return;
		}
		if (!sessionData) {
			throw new Error('session data not present.');
		}

		try {
			await compileCode({
				...data,
				sid: sessionData.sessionId,
			});
		} catch (err: any) {
			message.error(err.message);
			logger.log('Error when submit....', err.message);
			return;
		}

		if (data.questionSubmission && navigator.onLine) {
			// message.success('Submission success!', 2);
			if (confirmModalRef.current) {
				confirmModalRef.current.destroy();
			}
			confirmModalRef.current = Modal.confirm({
				content: (
					<div>
						<div>
							<ExclamationCircleTwoTone twoToneColor="#faad14" style={{ fontSize: 32 }} />
						</div>
						<div style={{ marginTop: '0.5rem' }}>
							<span>Submission success! do you want to proceed to next question?</span>
						</div>
						<div style={{ marginTop: '0.75rem' }}>
							<Button
								type="link"
								loading
								style={{
									backgroundColor: '#f7f7f7',
									padding: '0.5rem 0.75rem',
									height: 'unset',
								}}
							>
								Compiling
							</Button>
						</div>
					</div>
				),
				cancelText: 'stay',
				okText: 'next question',
				icon: <CheckCircleOutlined style={{ color: 'green' }} />,
				onOk: goToNextQuestion,
				width: 300,
				autoFocusButton: null,
			});
		}
	}, [sessionData, compileCode, goToNextQuestion]);

	useEffect(() => {
		// After submission if internet connection gone close modal

		window.addEventListener('offline', () => {
			if (confirmModalRef.current) {
				confirmModalRef.current.destroy();
			}
			message.error('Please check your internet connection.');
		});
	}, []);

	useEffect(() => {
		if (!compiling) {
			let isAllTestCasePassed = true;
			if (attemptQuestionData?.questionTypeCoding?.executionType
				&& +attemptQuestionData.questionTypeCoding.executionType
				!== QuestionExecutionType.CompileOnly
				&& !attemptQuestionData.questionTypeCoding?.consoleOutput?.error) {
				attemptQuestionData?.questionTypeCoding?.testCases.forEach((testCase) => {
					if (testCase.status === TestCaseStatus.Failed) {
						isAllTestCasePassed = false;
						return 0;
					}
					return 0;
				});
			}

			confirmModalRef.current?.update({
				content: (
					<div>
						<div>
							{attemptQuestionData?.questionTypeCoding?.consoleOutput?.error
								|| !isAllTestCasePassed ? (
								<CloseCircleTwoTone twoToneColor="#ff4d4f	" style={{ fontSize: 32 }} />
							) : (
								<CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 32 }} />
							)}
						</div>
						<div style={{ marginTop: '0.5rem' }}>
							<span>Submission success! do you want to proceed to next question?</span>
						</div>

						{/* error */}
						{attemptQuestionData?.questionTypeCoding?.consoleOutput?.error && (
							<div style={{ marginTop: '1rem', marginBottom: '0.75rem' }}>
								<span
									style={{
										color: 'var(--primary-color)',
										padding: '0.5rem 0.75rem',
										backgroundColor: '#f7f7f7',
										borderRadius: 5,
									}}
								>
									Compile error
								</span>
							</div>
						)}
						{/* error */}

						{
							attemptQuestionData?.questionTypeCoding?.executionType
							&& +attemptQuestionData.questionTypeCoding.executionType
							!== QuestionExecutionType.CompileOnly
							&& !attemptQuestionData.questionTypeCoding?.consoleOutput?.error && (
								<div style={{ marginTop: '1rem', marginBottom: '0.75rem' }}>
									<span
										style={{
											color: 'var(--primary-color)',
											padding: '0.5rem 0.75rem',
											backgroundColor: '#f7f7f7',
											borderRadius: 5,
										}}
									>
										{
											`${attemptQuestionData?.questionTypeCoding?.testCases.filter(
												(testCase) => testCase.status === TestCaseStatus.Passed,
											).length} Passed, ${attemptQuestionData?.questionTypeCoding?.testCases.filter(
												(testCase) => testCase.status === TestCaseStatus.Failed,
											).length} Failed`
										}
									</span>
								</div>
							)
						}
					</div>
				),
			});
		}
	}, [compiling, attemptQuestionData]);

	const toggleSidebar = useCallback(() => {
		setSidebarVisible(!sidebarVisible);
	}, [sidebarVisible]);

	const sendPasteNotification = useCallback(() => {
		sendCheatingDetails(CheatingType.Paste);
	}, [sendCheatingDetails]);

	if (attemptQuestionData?.hasBeenInvalidated) {
		return (
			<div className="question-attempt-wrapper invalidated">
				<h1>Uh Oh! This question has been moved/updated.</h1>
				<div>
					{
						nextLink && (
							<Link
								replace
								to={nextLink}
							>
								Go back to Dashboard
							</Link>
						)
					}
				</div>
			</div>
		);
	}

	return (
		<div className="question-attempt-wrapper">
			{
				sidebarVisible && (
					<div className="sidebar-container">
						{
							quizDashboardData?.segments.map((segment, index) => (
								<AttemptSidebarSegment
									key={segment._id}
									quizName={quizName}
									segmentData={segment}
									segmentIndex={index}
									isLocked={segment.isDisabled}
									isSubmitted={segment.isSubmitted}
									currentQuestionId={attemptQuestionData?._id ?? ''}
								/>
							))
						}
					</div>
				)
			}
			<div className="sidebar-toggler">
				<Button
					type="text"
					size="small"
					icon={
						sidebarVisible
							? <LeftOutlined style={{ color: 'var(--primary-color)' }} />
							: <RightOutlined style={{ color: 'var(--primary-color)' }} />
					}
					onClick={toggleSidebar}
				/>
			</div>
			{
				attemptQuestionData && (
					<div className="question-attempt-container" style={{ maxWidth: `calc(100% - ${(sidebarVisible ? 65 : 0) + 12}px)` }}>
						<Split
							style={{ display: 'flex', flex: 'auto', maxWidth: '100%' }}
							sizes={(
								(() => {
									if (+attemptQuestionData.type === QuestionType.MCQ) {
										return [55, 45];
									} if (+attemptQuestionData.type === QuestionType.Coding) {
										return [45, 55];
									} if (+attemptQuestionData.type === QuestionType.Web) {
										return [35, 65];
									}

									return [50, 50];
								})()
							)}
							minSize={0}
							gutterSize={8}
						>
							<div className="question-details-pane">
								<div className="question-details-container">
									<div className="question-type">
										<span>
											{QuestionType[Number(attemptQuestionData.type)]}
										</span>
									</div>
									<div className="question-title">
										<span>{attemptQuestionData.title}</span>
									</div>
									<div
										className="fancy-divider"
										style={{
											minHeight: 5.5, maxHeight: 5.5, margin: '0.25rem 0 0.45rem 0', maxWidth: 250,
										}}
									>
										<div />
										<div />
										<div />
									</div>
									<div
										className={`question-description ${quizData?.copyPasteAllowed ? 'selectable' : ''}`}
										// eslint-disable-next-line react/no-danger
										dangerouslySetInnerHTML={{
											__html: attemptQuestionData.text as string,
										}}
									/>
								</div>
								<div className="details-pane-footer">
									<Button
										type="text"
										disabled={!previousLink}
										onClick={goToPreviousQuestion}
									>
										<CaretLeftFilled style={{ color: 'var(--primary-color)' }} />
										previous
									</Button>

									<Button
										type="text"
										disabled={!nextLink}
										onClick={goToNextQuestion}
									>
										next
										<CaretRightFilled style={{ color: 'var(--primary-color)' }} />
									</Button>
								</div>
							</div>
							{/* <div className="question-details-attempt-divider" /> */}
							<div className={+attemptQuestionData.type === QuestionType.MCQ ? 'question-attempt-pane-scroll' : 'question-attempt-pane'}>
								{
									(() => {
										if (+attemptQuestionData.type === QuestionType.MCQ) {
											return (
												<AttemptMCQ
													questionData={attemptQuestionData}
													onSubmit={handleSubmitQuestion}
													submitting={submittingQuestion}
													setAttempt={setAttempt}
													getAttempt={getAttempt}
												/>
											);
										} if (+attemptQuestionData.type === QuestionType.Subjective) {
											return (
												<AttemptSubjective
													disableCopyPaste={!quizData?.copyPasteAllowed}
													sendPasteNotification={sendPasteNotification}
													questionData={attemptQuestionData}
													onSubmit={handleSubmitQuestion}
													submitting={submittingQuestion}
													setAttempt={setAttempt}
													getAttempt={getAttempt}
												/>
											);
										} if (+attemptQuestionData.type === QuestionType.Coding) {
											if (!(attemptQuestionData.questionTypeCoding?.codeLanguages?.length)) {
												return (
													<div style={{ width: '100%' }}>
														<h1 className="no-lang-text">
															Sorry, you cannot attempt this question
															because no language is given for this question ;)
														</h1>
													</div>
												);
											}
											return (
												<AttemptCoding
													disableCopyPaste={!quizData?.copyPasteAllowed}
													questionData={attemptQuestionData}
													compileCode={compileCodeCallback}
													setTestCaseStatus={setQuestionTestCaseStatus}
													compiling={compiling}
													submitting={submittingQuestion}
													setAttempt={setAttempt}
													getAttempt={getAttempt}
													sendPasteNotification={sendPasteNotification}
												/>
											);
										} if (+attemptQuestionData.type === QuestionType.MQ) {
											return (
												<AttemptMQ
													disableCopyPaste={!quizData?.copyPasteAllowed}
													questionData={attemptQuestionData}
													onSubmit={handleSubmitQuestion}
													submitting={submittingQuestion}
													setAttempt={setAttempt}
													getAttempt={getAttempt}
													sendPasteNotification={sendPasteNotification}
												/>
											);
										} if (+attemptQuestionData.type === QuestionType.Web) {
											return (
												<AttemptWeb
													disableCopyPaste={!quizData?.copyPasteAllowed}
													questionData={attemptQuestionData}
													compileCode={compileCodeCallback}
													onSubmit={handleSubmitQuestion}
													setTestCaseStatus={setQuestionTestCaseStatus}
													compiling={compiling}
													submitting={submittingQuestion}
													setAttempt={setAttempt}
													getAttempt={getAttempt}
													sendPasteNotification={sendPasteNotification}
												/>
											);
										}

										return null;
									})()
								}

							</div>
						</Split>
					</div>
				)
			}
			{
				!attemptQuestionData && questionLoadingErrorMessage && (
					<AttemptQuestionNotLoaded
						retryFunc={retryQuestionGet}
						errorMessage={questionLoadingErrorMessage}
					/>
				)
			}
		</div>
	);
};
