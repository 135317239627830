import React, { useCallback, useEffect } from 'react';
import { Button } from 'antd';

import { APP_CONFIG, isElectronApp } from '../../../config';
import { ReactComponent as Error404 } from '../../../assets/img/404.svg';
import ChitkaraLogo from '../../../assets/img/chitkara_wide.png';
import './general.scoped.css';
import { useAppStore } from '../../../store';

const appStoreSelector = (state: AppState) => ({
	setAppLoading: state.setAppLoading,
});

export const GeneralError = (params: {
	quizId: string,
	error?: string,
}) => {
	const { quizId, error } = params;
	const { setAppLoading } = useAppStore(appStoreSelector);

	useEffect(() => {
		if ('api' in window) {
			setTimeout(() => {
				if ('api' in window) (window.api as any).sendToMain('change-closeable-state', true);
			}, 1000);
		}
	}, []);

	const handleReload = useCallback(async (link: string) => {
		await setAppLoading(true);
		window.location.replace(link);
	}, [setAppLoading]);

	return (
		<div className="general-error">
			<div className="header">
				<div className="cq-logo">
					{!APP_CONFIG.isChitkara
						&& (
							<a href={APP_CONFIG.QuizServerURL}>
								<span>code</span>
								<span>quotient</span>
							</a>
						)}
					{APP_CONFIG.isChitkara
						&& (
							<a href={APP_CONFIG.QuizServerURL}>
								<img src={ChitkaraLogo} alt="Logo" height={50} />
							</a>
						)}
				</div>
			</div>
			<div className="general-error-container">
				<div className="left-pane">
					<div className="circle-artwork">
						<div className="circle-1" />
						<div className="circle-2" />
						<div className="circle-3" />
					</div>
					<div className="text-container">
						<h1>Uh oh!</h1>
						<p>
							Unable to load the quiz.
							{error
								&& (
									<>
										<br />
										{error}
									</>
								)}
							{!error
								&& (
									<>
										<br />
										Please check your interent connection.
									</>
								)}
						</p>
						<Button style={{ fontSize: '18px' }} type="link" onClick={() => handleReload(`${window.location.origin}/test/${quizId}`)}>Refresh</Button>
					</div>
				</div>
				<div className="right-pane">
					<Error404 style={{ maxWidth: '100%' }} />
				</div>
			</div>
		</div>
	);
};
