import { EventEmitter } from 'events';
import TypedEmitter from 'typed-emitter';

interface EventsSupported {
	'priorityChanged': () => void
}

export default class ArrayBasedQueue<T> extends (
	EventEmitter as new () => TypedEmitter<EventsSupported>
) {
	private arr: Array<T> = [];

	add(data: T) {
		this.arr.push(data);
		this.emit('priorityChanged');
	}

	get() {
		return this.arr?.[0] ?? null;
	}

	delete(obj: T) {
		this.arr = this.arr.filter((ele) => ele !== obj);
		this.emit('priorityChanged');
	}
}
