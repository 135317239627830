import React, { useCallback } from 'react';
import {
	Switch, Route, useRouteMatch, Redirect,
} from 'react-router-dom';
import { NotFoundScreen } from './not-found';
import { GeneralError } from './general';

export const ErrorScreen: React.FunctionComponent = () => {
	const match = useRouteMatch();
	const notFoundScreen = useCallback(() => <NotFoundScreen />, []);
	const errorScreen = useCallback((params) => {
		const quizName = params?.match?.params?.quizName ?? '';
		console.log(params);
		const error = new URL(window.location.href).searchParams.get('error') ?? '';
		return <GeneralError quizId={quizName} error={error} />;
	}, []);

	return (
		<Switch>
			<Route
				exact
				path={`${match.url}/404`}
				render={notFoundScreen}
			/>
			<Route
				exact
				strict
				path={`${match.url}/:quizName/error`}
				render={errorScreen}
			/>
			<Redirect to={`${match.url}/404`} />
		</Switch>
	);
};
