import React, {
	useCallback, useEffect, useRef, useState,
} from 'react';
import { LiveStreamManager, JaaS } from '../clients/live-stream';
import JitsiTrack from '../@types/jitsi/hand-crafted/modules/RTC/JitsiTrack';

export function useRoom(
	apiKey: string, token: string,
	webCamStreamConf: MediaStreamTrack | null,
	audioStreamConf: MediaStreamTrack | null,
	userData: { displayName: string, userId: string, email: string, enrollmentId?: string },
): [
		Array<MessageObj>,
		boolean,
		(roomName: Array<string>) => void,
		(track: MediaStreamTrack | null) => void,
		(track: MediaStreamTrack | null) => void,
		(message: string) => void,
	] {
	const [isMicInUse, setMicInUse] = useState<boolean>(false);
	const [webCamStream, setWebCamStream] = useState<MediaStreamTrack | null>(webCamStreamConf);
	const [audioStream, setAudioStream] = useState<MediaStreamTrack | null>(audioStreamConf);
	const roomManagerRef = useRef<LiveStreamManager>(new LiveStreamManager());
	const [messages, setMessages] = useState<Array<MessageObj>>([]);
	const addRoom = useCallback((roomName: string) => {
		const roomObj = new JaaS(roomName, {
			apiKey,
			token,
			userDetails: {
				displayName: userData.displayName,
				email: userData.email,
				enrollmentId: userData.enrollmentId,
			},
		});
		roomManagerRef.current.addNewStream(roomObj);
	}, [apiKey, token, userData.displayName, userData.email, userData.enrollmentId]);

	useEffect(() => {
		roomManagerRef.current.on('newMessageReceived', () => {
			setMessages(roomManagerRef.current.chatMessages);
		});
		roomManagerRef.current.on('micStateChanged', () => {
			const roomsUsingMic = roomManagerRef.current.roomsWhereMicIsInUse;
			if (roomsUsingMic.length) {
				setMicInUse(true);
			} else {
				setMicInUse(false);
			}
		});
	}, [setMicInUse]);

	useEffect(() => {
		roomManagerRef.current.setWebCamStream(webCamStream);
	}, [webCamStream]);

	useEffect(() => {
		roomManagerRef.current.setAudioStream(audioStream);
	}, [audioStream]);

	const setRooms = useCallback((newRooms?: Array<string>) => {
		const prevRooms = roomManagerRef.current.getRoomsArray();
		const prevRoomsSet = new Set(prevRooms);
		(newRooms ?? []).forEach((roomName) => {
			if (!prevRoomsSet.has(roomName)) {
				addRoom(roomName);
			}
			prevRoomsSet.delete(roomName);
		});
		prevRoomsSet.forEach((rooms) => {
			roomManagerRef.current.removeUserFromRoom(rooms);
		});
	}, [addRoom]);

	const sendMessage = useCallback((message: string) => {
		roomManagerRef.current.sendMessage(message);
	}, []);

	return [
		messages, isMicInUse,
		setRooms, setWebCamStream, setAudioStream, sendMessage,
	];
}
