import SpeedTest, { Results } from '@cloudflare/speedtest';
import AbstractSpeedClass from './index';
import { NetworkStatus } from '../../../config';

export default class SpeedTestClass extends AbstractSpeedClass {
	private speedTest: SpeedTest;

	private previousResult: Results | null;

	constructor(freq: number) {
		super(freq);
		this.speedTest = new SpeedTest({
			autoStart: false,
			measurements: [
				{ type: 'latency', numPackets: 1 },
				{ type: 'upload', bytes: 5e5, count: 1 },
			],
		});
		this.previousResult = null;
		this.speedTest.onFinish = this.handleSpeedTestResultFinished.bind(this);
		this.speedTest.onError = console.error;
	}

	start() {
		if (!this.speedTest.isRunning) {
			this.speedTest.restart();
		}
	}

	networkStateChanged(status: number): void {
		if (NetworkStatus.Offline === status) {
			this.previousResult = null;
			this.emit('result', null);
			this.pause();
			return;
		}
		if (NetworkStatus.Online === status) {
			this.start();
		}
	}

	private handleSpeedTestResultFinished(result: any) {
		const calcualtedResult = SpeedTestClass.calculateStatsFromResult(result);
		this.emit('result', calcualtedResult);
		setTimeout(() => {
			this.start();
		}, this.frequncy);
		console.log('Network result: ', result);
	}

	pause() {
		if (this.speedTest.isRunning) {
			this.speedTest.pause();
		}
	}

	static calculateStatsFromResult(result: Results): NetworkStats {
		const tempResult = result.getSummary();
		let uploadBandwidth = tempResult.upload;
		if (uploadBandwidth) {
			uploadBandwidth /= (1024 * 1024 * 8);
			uploadBandwidth = parseFloat((uploadBandwidth.toFixed(2)));
		}
		const uploadLatency = tempResult.upLoadedLatency ?? 0;
		const uploadJitter = tempResult.upLoadedJitter ?? 0;
		const finalObj = {
			uploadSpeed: uploadBandwidth ?? 0,
			uploadLatency: parseFloat(((uploadLatency).toFixed(2))),
		};
		return {
			upload: {
				speed: finalObj.uploadSpeed,
				latency: finalObj.uploadLatency,
			},
		};
	}

	getCurrentNetworkStats() {
		if (this.speedTest.isRunning) {
			const result = this.speedTest.results;
			return SpeedTestClass.calculateStatsFromResult(result);
		}
		if (this.previousResult) {
			return SpeedTestClass.calculateStatsFromResult(this.previousResult);
		}
		return false;
	}
}
