export enum Platform{
	'browser' = 1,
	'app' = 2,
}

export function getRandomInteger(min: number, max: number): number {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

// HERE RSA CAN BE USED TO INCREASE THE ACCURACY OF THE TEST
export const currentPlatform = () : Platform => {
	if ('api' in window) {
		return Platform.app;
	}
	return Platform.browser;
};

export const getConnectionStringFromNumber = (speedInMB: number): string => {
	if (speedInMB < 1) {
		return `${speedInMB * 1024} KB/s`;
	}
	return `${speedInMB} MB/s`;
};

export const generateOtp = (length: number) => {
	const digits = '0123456789';
	const len = digits.length;
	let OTP = '';
	OTP += Math.max(Math.floor(Math.random() * 10), 1);
	for (let index = 1; index < length; index += 1) {
		OTP += digits[Math.floor(Math.random() * len)];
	}
	return OTP;
};
