import React, {
	ReactElement, useCallback, useEffect, useState,
} from 'react';
import { useRoom } from '../../hooks/remote-proctoring';
import JitsiTrack from '../../@types/jitsi/hand-crafted/modules/RTC/JitsiTrack';
import { LiveChat } from '../live-chat';

interface RemoteProctoringProps {
	apiKey: string,
	token: string,
	rooms: Array<string>;
	webCamStream: MediaStreamTrack | null;
	audioCamStream: MediaStreamTrack | null;
	setIsMicInUse?: (boolean) => void;
	userData: {
		displayName: string,
		email: string,
		enrollmentId?: string,
		userId: string,
	}
}

export const RemoteProctoring = (props: RemoteProctoringProps): ReactElement => {
	const {
		apiKey, token, userData, rooms, webCamStream, audioCamStream, setIsMicInUse,
	} = props;

	const [
		messages, isMicActive, setRooms,
		changeWebCamStream,
		changeAudioStream,
		sendMessage,
	] = useRoom(apiKey, token, null, null, userData);

	const [messageInputValue, setMessageInputValue] = useState<string>('');

	useEffect(() => {
		setIsMicInUse?.(isMicActive);
	}, [isMicActive, setIsMicInUse]);

	useEffect(() => {
		setRooms(rooms);
	}, [rooms, setRooms]);

	useEffect(() => {
		changeWebCamStream(webCamStream);
	}, [webCamStream, changeWebCamStream]);

	useEffect(() => {
		changeAudioStream(audioCamStream);
	}, [audioCamStream, changeAudioStream]);

	return (
		<LiveChat
			messages={messages}
			sendMessage={sendMessage}
			messageInputValue={messageInputValue}
			setMessageInputValue={setMessageInputValue}
		/>
	);
};
