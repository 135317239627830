import React, { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { QuizLoginScreen } from '../quiz-login';
import { APP_CONFIG, isElectronApp } from '../../../config';
import { useAppStore, useQuizStore } from '../../../store';

interface QuizInvitePageProps {
	token: string,
	quizName: string,
}

const appStoreSelector = (state: AppState) => ({
	setAppLoading: state.setAppLoading,
	openLinkInApp: state.openLinkInApp,
	setAppModalShown: state.setAppModalShown,
	appModalShow: state.appModalShown,
});

const quizStoreSelector = (state: QuizState) => ({
	validateToken: state.validateToken,
});

export const QuizInvitePage: React.FC<QuizInvitePageProps> = (props: QuizInvitePageProps) => {
	const { token, quizName } = props;
	const {
		setAppLoading, openLinkInApp, setAppModalShown, appModalShow,
	} = useAppStore(appStoreSelector);

	const { validateToken } = useQuizStore(quizStoreSelector);

	const validateTokenFunction = useCallback(async (value: string) => {
		try {
			await setAppLoading(true);
			const result = await validateToken(value);
			if (!result) {
				throw new Error('Token is not valid');
			}
			openLinkInApp(`${APP_CONFIG.QuizServerURL}/test/invite/${token}`);
		} catch (error: any) {
			message.error(error?.message ?? error);
			setTimeout(() => {
				window.location.replace(`${APP_CONFIG.QuizServerURL}/login`);
			}, 3000);
		} finally {
			setAppLoading(false);
		}
	}, [openLinkInApp, setAppLoading, token, validateToken]);

	useEffect(() => {
		validateTokenFunction(token);
	}, [token, validateTokenFunction]);

	return (
		<QuizLoginScreen
			quizName={quizName}
			noOverlay
			openInAppHandler={async () => {
				await validateTokenFunction(token);
			}}
			noForm
		/>
	);
};
