/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/object-curly-spacing */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/indent */
import React, {
	useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { APP_CONFIG, ScreenShareState, WebcamStateKind } from '../../config';
import './jaas.scoped.css';
import { useQuizStore } from '../../store';
import { ParticipantsJitsi } from '../../@types/jitsi';

interface JaasParams {
	token: string,
	displayName: string,
	roomName: string,
	isStage?: boolean,
	webCamStream: any,
	onUserKicked?: (roomName: string) => void,
}

const quizSelector = (state: QuizState) => ({
	jitsiCredentials: state.getJitsiCredentials,
	updateJitsiCredentials: state.updateJitsiCredentials,
});

const apiKey = APP_CONFIG.LiveStreamPublicApiKey;
export const Jaas = (params: JaasParams): React.ReactElement => {
	const {
		token, roomName, isStage, webCamStream, displayName, onUserKicked,
	} = params;

	const [room, setRoom] = useState<any>(null);
	const { jitsiCredentials, updateJitsiCredentials } = useQuizStore(quizSelector);
	const [releaseVersion, setReleaseVersion] = useState('');
	const [connection, setConnection] = useState<null | any>(null);

	const localTracks = useRef<any[]>([webCamStream]);
	const participantsMap = useRef<Map<string, ParticipantsJitsi>>(new Map());

	const options: { [key: string]: any } = useMemo(() => {
		const stage = isStage ? 'stage' : '';
		const subdomain = isStage ? stage : '';
		const option = {
			hosts: {
				domain: `${stage}8x8.vc`,
				muc: `conference.${apiKey}.${stage}8x8.vc`,
				focus: `focus.${stage}8x8.vc`,
			},
			serviceUrl: `wss://${subdomain}8x8.vc/${apiKey}/xmpp-websocket?room=${roomName}${releaseVersion}`,
			websocketKeepAliveUrl: `https://${subdomain}8x8.vc/${apiKey}/_unlock?room=${roomName}`,
			constraints: {
				video: {
					height: {
						ideal: 480,
						max: 480,
						min: 180,
					},
					width: {
						ideal: 768,
						max: 768,
						min: 320,
					},
				},
			},
			channelLastN: 25,
			p2p: {
				enabled: true,
			},
			confID: `https://${stage}8x8.vc/${apiKey}/${roomName}`,
			siteID: apiKey,
			applicationName: 'Test App',
			logging: {
				defaultLogLevel: 'trace',
				'modules/RTC/TraceablePeerConnection.js': 'info',
				'modules/statistics/CallStats.js': 'info',
				'modules/xmpp/strophe.util.js': 'log',
			},
		};
		return option;
	}, [roomName, releaseVersion, isStage]);

	const onUserJoined = useCallback((id, data) => {
		const currentUserData = {
			id: data._id ?? '',
			name: data._displayName ?? '',
			role: data._role,
		};
		participantsMap.current.set(id, currentUserData);
		console.log('user joined!', id, data, currentUserData);
	}, []);

	const onUserLeft = useCallback((id) => {
		console.log('user left!, id');
	}, []);

	const conferenceLeft = useCallback(() => {
		console.log('Conference Left');
	}, []);

	const onUserKickedFromConference = useCallback((roomNameWhereUserWasKicked) => {
		if (onUserKicked) {
			onUserKicked(roomNameWhereUserWasKicked);
		}
	}, [onUserKicked]);

	const onConferenceJoined = useCallback((rooms) => {
		console.log('Room joined!');
		console.log('Adding tracks');
		updateJitsiCredentials();
		rooms?.setLocalParticipantProperty('requestingTranscription', true);
	}, [updateJitsiCredentials]);

	const onConnectionSuccess = useCallback(async (
		connections: any, option: { [key: string]: any }, roomTitle: string,
	) => {
		let currentRoom: any;
		try {
			console.log('Going to join room');
			currentRoom = connections.initJitsiConference(roomTitle, option);
			for (let i = 0; i < localTracks.current.length; i += 1) {
				console.log('Adding new track');
				currentRoom.addTrack(localTracks.current[i]).catch((err) => {
					console.error('CATCHED THE ERROR: ', err);
				});
			}
			currentRoom.on(
				JitsiMeetJS.events.conference.CONFERENCE_JOINED,
				() => {
					console.log('Conference Joined');
					onConferenceJoined(currentRoom);
				},
			);
			currentRoom.on(
				JitsiMeetJS.events.conference.CONFERENCE_LEFT,
				conferenceLeft,
			);
			currentRoom.on(
				JitsiMeetJS.events.conference.USER_JOINED,
				onUserJoined,
			);
			currentRoom.on(
				JitsiMeetJS.events.conference.USER_LEFT,
				onUserLeft,
			);
			currentRoom.addEventListener(
				JitsiMeetJS.events.conference.KICKED,
				() => {
					onUserKickedFromConference(roomTitle);
				},
			);

			currentRoom.on(JitsiMeetJS.events.conference.ENDPOINT_MESSAGE_RECEIVED, (...args) => { console.log('RECEIVED ENDPOINT MESSAGE', args); });

			// Join
			currentRoom.join();
			currentRoom.setSenderVideoConstraint(480); // Send at most 720p
			currentRoom.setReceiverVideoConstraint(360); // Receive at most 360p for each participant
			setRoom(currentRoom);
		} catch (err) {
			console.error(err);
		}

		return () => {
			currentRoom.leave().then(() => console.log('Confrence Leaved')).catch((err) => {
				console.error(err);
			});
		};
	}, [
		conferenceLeft, setRoom, onUserKickedFromConference,
		onConferenceJoined, onUserJoined, onUserLeft,
	]);

	const createConnection = useCallback(async (option: { [key: string]: any }, roomTitle) => {
		try {
			await jitsiCredentials();
			JitsiMeetJS.init(option);
			JitsiMeetJS.setLogLevel(option.logging.defaultLogLevel);
			for (const [loggerId, level] of Object.entries(option.logging)) {
				if (loggerId !== 'defaultLogLevel') {
					JitsiMeetJS.setLogLevelById(level, loggerId);
				}
			}
			const connections = new JitsiMeetJS.JitsiConnection(null, token, option);
			connections.addEventListener(
				JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
				() => {
					onConnectionSuccess(connections, option, roomTitle);
				},
			);
			connections.addEventListener(
				JitsiMeetJS.events.connection.CONNECTION_FAILED, () => {
					setConnection(null);
				},
			);
			connections.addEventListener(
				JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, () => {
					setConnection(null);
				},
			);
			connections.connect();
			setConnection(connections);
		} catch (err) {
			console.log(err);
		}
	}, [token, onConnectionSuccess, jitsiCredentials]);

	useEffect(() => {
		const currentConnection = connection;
		if (options && roomName && !currentConnection) {
			createConnection(options, roomName);
		}
	}, [connection, options, roomName, createConnection]);

	useEffect(() => {
		const currentConnection = connection;
		return () => {
			if (currentConnection) {
				currentConnection.removeEventListener(
					JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
					onConnectionSuccess,
				);
				currentConnection.removeEventListener(
					JitsiMeetJS.events.connection.CONNECTION_FAILED,
					() => setConnection(null),
				);
				currentConnection.removeEventListener(
					JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
					() => setConnection(null),
				);
				currentConnection.disconnect();
				setConnection(null);
			}
		};
	}, [connection, onConnectionSuccess]);

	useEffect(() => {
		const currentRoom = room;
		return () => {
			if (currentRoom) {
				currentRoom.leave().then(() => console.log('Confrence Leaved')).catch((err) => {
					console.error(err);
				});
				setRoom(null);
			}
		};
	}, [room, roomName, setRoom]);

	useEffect(() => {
		if (room) {
			room.setDisplayName(displayName);
		}
	}, [room, displayName]);

	return (
		<></>
	);
};
