import {
	Button, Form, Input, message,
} from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import ReCAPTCHA from '@hcaptcha/react-hcaptcha';

import { APP_CONFIG } from '../../config';
import cqHeaderImag from '../../assets/icons/cq_logo_primary.png';
import chitkaraHeaderImage from '../../assets/icons/chitkara-university-logo.png';
import './forgot-password-form.scoped.css';

const headerImag = (APP_CONFIG.isChitkara) ? chitkaraHeaderImage : cqHeaderImag;

interface ForgetPasswordFormProps {
	onForgot: (data: forgotPasswordPayload) => Promise<void>
	goBack: () => void,
}

export const ForgotPasswordForm = (props: ForgetPasswordFormProps) => {
	const { onForgot, goBack } = props;
	const [sendingRequest, setSendingRequest] = useState<boolean>(false);
	const [captia, setCaptia] = useState<string | null>(null);

	const recaptchaRef = useRef<any>();

	const sendForgotPasswordRequest = useCallback(async (data) => {
		try {
			const { email } = data;
			if (!captia) throw new Error('Please validate reCAPTCHA first.');
			setSendingRequest(true);
			await onForgot({ email, captia });
			message.success('Please check your email.');
			goBack();
		} catch (error: any) {
			console.log(error);
			message.error(error?.message ?? error);
		} finally {
			if (recaptchaRef.current) {
				recaptchaRef.current.resetCaptcha();
			}
			setSendingRequest(false);
		}
	}, [captia, goBack, onForgot]);

	return (
		<div className="forgot-form-container">
			<Form
				layout="vertical"
				onFinish={sendForgotPasswordRequest}
			>
				<div id="banner-holder">
					<h2>forgot password</h2>
				</div>
				<Form.Item
					label="enter your registered email address"
					name="email"
					rules={[
						{ required: true, whitespace: true, message: 'Please enter email!' },
						{ type: 'email', message: 'Please enter valid email' },
					]}
					normalize={(value, prevVal, prevVals) => value.trim()}
				>
					<Input id="formgotEmailInput" placeholder="email" />
				</Form.Item>
				<Form.Item
					name="captia"
				>
					<div style={{ marginTop: '1rem' }}>
						<ReCAPTCHA
							ref={recaptchaRef}
							sitekey={APP_CONFIG.CaptiaKey}
							onVerify={setCaptia}
						/>
					</div>
				</Form.Item>
				<Form.Item>
					<div className="form-actions">
						<Button
							type="primary"
							size="large"
							htmlType="submit"
							loading={sendingRequest}
						>
							forgot
						</Button>
						<div>
							<Button onClick={goBack} type="link">Go Back</Button>
						</div>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
};
