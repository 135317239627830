import axios, { AxiosInstance } from 'axios';

export class UploadQueue {
	private _quizServerURL: string;

	private _staticServerURL: string;

	private _quizServerAxiosInstance: AxiosInstance;

	private _staticServerAxiosInstance: AxiosInstance;

	private _isProcessingData: boolean;

	private _uploadQueue: Array<{
		metaData: Record<string, string>,
		dataToUpload: any,
		presignedURLData: {
			url: string,
			fields: Record<string, string>,
		},
	}>;

	constructor(staticServerURL: string, quizServerURL: string) {
		this._quizServerURL = quizServerURL;
		this._staticServerURL = staticServerURL;
		this._uploadQueue = [];
		this._isProcessingData = false;
		this._staticServerAxiosInstance = axios.create({
			baseURL: this._staticServerURL,
			withCredentials: true,
			headers: {
				'Content-Type': 'application/json',
			},
		});

		this._quizServerAxiosInstance = axios.create({
			baseURL: this._quizServerURL,
			withCredentials: true,
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	async popFromUploadQueue() {
		if (this._isProcessingData) {
			return;
		}
		this._isProcessingData = true;
		let wasDataPresent = false;
		try {
			const data = this._uploadQueue.shift();
			if (data) {
				wasDataPresent = true;
				await UploadQueue.handleUpload(data.dataToUpload, data.metaData, data.presignedURLData);
			}
		} catch (error) {
			console.error(error);
		}
		this._isProcessingData = false;
		if (wasDataPresent) {
			this.popFromUploadQueue();
		}
	}

	static async handleUpload(
		data: any,
		metaData: Record<string, string>,
		presignedURLData: {
			url: string,
			fields: Record<string, string>,
		},
		maxWeight: number = 2 * 60 * 1000,
	): Promise<void> {
		const formData = new FormData();
		Object.keys(presignedURLData.fields).forEach((key) => {
			formData.set(key, presignedURLData.fields[key]);
		});
		formData.set('file', data);
		const resData = await axios({
			method: 'POST',
			url: presignedURLData.url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/formdata',
			},
			timeout: maxWeight,
		});
		console.log(resData);
	}

	async getPresignedURL(userId: string, quizId: string): Promise<{
		url: string, fields: Record<string, string>
	}> {
		const result = await this._staticServerAxiosInstance.post('/getPresignedURL', {
			userId,
			quizId,
		});
		if (!result.data?.url) {
			throw new Error('Something went wrong unable to create presigned url');
		}
		return result.data;
	}

	async upload(data: Record<string, string>): Promise<string | null> {
		try {
			if (!data.userId || !data.quizId || !data.image) {
				return null;
			}
			const rowResponse = await this.getPresignedURL(data.userId, data.quizId);
			if (rowResponse) {
				this._uploadQueue.push({
					metaData: data,
					dataToUpload: data.image,
					presignedURLData: rowResponse,
				});
			}
			const url = new URL(rowResponse?.fields.key, rowResponse.url);
			this.popFromUploadQueue();
			return url.toString();
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	async uploadSync(data: Record<string, string>): Promise<string | null> {
		try {
			if (!data.userId || !data.quizId || !data.image) {
				return null;
			}
			const rowResponse = await this.getPresignedURL(data.userId, data.quizId);
			if (rowResponse) {
				this._uploadQueue.push({
					metaData: data,
					dataToUpload: data.image,
					presignedURLData: rowResponse,
				});
			}
			const url = new URL(rowResponse?.fields.key, rowResponse.url);
			await UploadQueue.handleUpload(data.image, data, rowResponse, 30 * 1000);
			return url.toString();
		} catch (error) {
			console.error(error);
			return null;
		}
	}
}
