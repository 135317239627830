import React, { useCallback, useEffect, useState } from 'react';
import {
	Button, message,
	Dropdown, Menu, Tooltip,
	Popover,
	Spin,
} from 'antd';

import { useHistory } from 'react-router-dom';
import Icon, {
	HomeFilled,
	ExclamationCircleFilled,
	CheckCircleFilled,
	FullscreenOutlined,
	FullscreenExitOutlined,
	LoginOutlined,
	LogoutOutlined,
	ReloadOutlined,
	WifiOutlined,
} from '@ant-design/icons';
import './app-header.css';
import SpeedTest from '../../libs/utils/speed-test/speed-test-by-cloudflare';
import { ReactComponent as CQLogo } from '../../assets/img/cq-logo.svg';
import ChitkaraLogo from '../../assets/img/chitkara.png';
// import { ReactComponent as UserIcon } from '../../assets/icons/user-icon.svg';
import userIcon from '../../assets/icons/user-icon.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout-icon.svg';

import './app-header.scoped.css';
import { APP_CONFIG, NetworkStatus, isElectronApp } from '../../config';
import { FontChangeButton } from '../font-change-button';
import { NetworkStats } from '../network-stats';

const Logo = (APP_CONFIG.isChitkara) ? <img src={ChitkaraLogo} alt="Logo" height={35} /> : <CQLogo height={35} />;
interface AppHeaderProps {
	quizName: string
	quizData: QuizData | null
	sessionData: SessionData | null
	hasQuizStarted?: boolean
	timeLeft?: number
	tabSwitchCount?: number
	tabSwitchInCount?: number
	fullScreenInCount?: number
	fullScreenOutCount?: number
	totalSubmittedQuestions?: number
	showHome?: boolean
	nextLink?: string | null
	previousLink?: string | null
	networkStatus: NetworkStatus,
	networkStats: NetworkStats | null,
	setLoading: (status: boolean) => Promise<void>,
	stopRecording: () => Promise<void>,
	submitQuiz?: (SumitQuizParms) => Promise<string | void>,
	setTimeLeft?: (time: number) => Promise<void>,
	getRemainingTime?: () => Promise<void>,
}

export const AppHeader: React.FunctionComponent<AppHeaderProps> = (props) => {
	const {
		quizName,
		sessionData,
		quizData,
		timeLeft,
		tabSwitchCount,
		tabSwitchInCount,
		fullScreenInCount,
		fullScreenOutCount,
		hasQuizStarted,
		// nextLink,
		// previousLink,
		showHome,
		networkStatus,
		totalSubmittedQuestions,
		networkStats,
		setLoading,
		submitQuiz,
		setTimeLeft,
		stopRecording,
		getRemainingTime,
	} = props;

	const loadingNetworkStat = networkStats === null;

	const history = useHistory();
	const refreshHandler = useCallback(async () => {
		if (networkStatus === NetworkStatus.Online) {
			setLoading(true);
			try {
				await stopRecording();
			} catch (error) {
				console.error(error);
			}
			window.location.reload();
			return;
		}
		message.error('Please check your internet connection.');
	}, [networkStatus, setLoading, stopRecording]);

	const [timerText, setTimerText] = useState<string>('');
	const [offline, setOfflineStatus] = useState<boolean>(false);
	const [showConnectionStatus, setShowConnectionStatus] = useState<boolean>(false);

	// useEffect(() => {
	// 	let intervalId: NodeJS.Timeout;
	// 	(async () => {
	// 		if (typeof timeLeft === 'number') {
	// 			if (timeLeft > 0) {
	// 				intervalId = setInterval(() => setTimeLeft?.(timeLeft - 1), 1000);
	// 			} else {
	// 				const redirectLink = await submitQuiz?.(true);

	// 				if (redirectLink) {
	// 					window.location.replace(`${APP_CONFIG.QuizServerURL}${redirectLink}`);
	// 				}
	// 			}
	// 		}
	// 	})();

	// 	return () => clearInterval(intervalId);
	// }, [timeLeft, submitQuiz, setTimeLeft]);

	useEffect(() => {
		if (timeLeft) {
			setTimerText(`${String(Math.floor(timeLeft / 3600)).padStart(2, '0')}:${String(Math.floor((timeLeft / 60) % 60)).padStart(2, '0')}:${String(timeLeft % 60).padStart(2, '0')}`);
			if (timeLeft === 299) {
				message.error('Hurry Up! Only 5 minutes are left to complete your test.');
			}
		}
	}, [timeLeft, setTimerText]);

	useEffect(() => {
		const intervalId: NodeJS.Timeout = setInterval(() => getRemainingTime?.(), 20000);

		return () => clearInterval(intervalId);
	}, [getRemainingTime]);

	const goToDashboard = useCallback(() => {
		history.push(`/test/${quizName}/dashboard`);
	}, [history, quizName]);

	const logoutUser = useCallback(() => {
		try {
			if (!navigator.onLine) {
				throw new Error('Please check your internet connection.');
			}
			history.push({
				state: { referrer: '' },
			});
			window.location.replace(`${APP_CONFIG.QuizServerURL}/logout`);
		} catch (error: any) {
			message.error(error.message);
		}
	}, [history]);

	// const goToNextQuestion = useCallback(() => {
	// 	if (nextLink) {
	// 		history.push(nextLink);
	// 	}
	// }, [history, nextLink]);

	// const goToPreviousQuestion = useCallback(() => {
	// 	if (previousLink) {
	// 		history.push(previousLink);
	// 	}
	// }, [history, previousLink]);

	useEffect(() => {
		let statusTimeOut: null | NodeJS.Timeout = null;
		switch (networkStatus) {
			case NetworkStatus.Offline: {
				setShowConnectionStatus(true);
				setOfflineStatus(true);
				break;
			}

			case NetworkStatus.Online: {
				setOfflineStatus(false);
				statusTimeOut = setTimeout(() => {
					setShowConnectionStatus(false);
				}, 3000);
				break;
			}
			default: {
				console.log('Network State Change');
			}
		}
		return () => {
			if (statusTimeOut) {
				clearInterval(statusTimeOut);
			}
		};
	}, [networkStatus]);

	return (
		<div className="app-header">
			<div className="header-left">
				<div className="app-logo">
					<Button
						type="text"
						shape="circle"
						icon={Logo}
						onClick={goToDashboard}
					/>
				</div>

				<div className="quiz-title">
					<span>{quizData?.title}</span>
				</div>

				{
					hasQuizStarted && (
						<div className="quiz-timer-container">
							<div className="quiz-timer-dot" />
							<span className={`${timeLeft && timeLeft < 300 ? 'last-time' : ''}`}>
								time left &nbsp;
								{timerText}
							</span>
						</div>
					)
				}
				{
					hasQuizStarted && (
						<div className="cheatingDetails">
							<div className="cheatingDetailsFullSceen">
								<Tooltip title="Full Screen Out Count">
									<div className="cheatingIcons">
										<FullscreenOutlined />
										<span style={{ position: 'relative', bottom: '1px' }}>:</span>
										<span className="cheatingCounter">{fullScreenOutCount || 0}</span>
									</div>
								</Tooltip>
								<Tooltip title="Full Screen In Count">
									<div className="cheatingIcons">
										<FullscreenExitOutlined />
										<span style={{ position: 'relative', bottom: '1px' }}>:</span>
										<span className="cheatingCounter">{fullScreenInCount || 0}</span>
									</div>
								</Tooltip>
							</div>
							<div className="cheatingDetailsTabSwitch">
								<Tooltip title="Tab Switch Out Count">
									<div className="cheatingIcons">
										<LogoutOutlined />
										<span style={{ position: 'relative', bottom: '1px' }}>:</span>
										<span className="cheatingCounter">{tabSwitchCount || 0}</span>
									</div>
								</Tooltip>
								<Tooltip title="Tab Switch In Count">
									<div className="cheatingIcons">
										<LoginOutlined />
										<span style={{ position: 'relative', bottom: '1px' }}>:</span>
										<span className="cheatingCounter">{tabSwitchInCount || 0}</span>
									</div>
								</Tooltip>
							</div>
						</div>
					)
				}
				<div style={{ width: '20px', height: '20px' }} />
				{
					networkStatus === NetworkStatus.Online
					&& <NetworkStats network={networkStats} />
				}
			</div>

			{
				showConnectionStatus && (
					<div className="ConnectionStatusNotifier">
						{
							offline ? (
								<div className="offlineConnectionStatus">
									<ExclamationCircleFilled style={{ color: 'red', fontSize: '18px' }} />
									<span className="OfflineStatusText">Your device lost its internet connection.</span>
								</div>
							)
								: (
									<div className="onlineConnectionStatus">
										<CheckCircleFilled style={{ color: 'green', fontSize: '18px' }} />
										<span className="onlineStatusText">Your device is connected to the internet.</span>
									</div>
								)
						}
					</div>
				)
			}

			<div className="header-right">
				{/* Button to change font */}
				{isElectronApp
					&& <FontChangeButton />}
				{
					hasQuizStarted && (
						<div className="quiz-nav-container">
							{
								showHome && (
									<Tooltip title="dashboard">
										<Button
											type="text"
											size="large"
											shape="circle"
											icon={<HomeFilled style={{ color: 'var(--primary-color)' }} />}
											onClick={goToDashboard}
										/>
									</Tooltip>
								)
							}
							<div>
								{/* <Tooltip title="prev">
									<Button
										type="text"
										size="large"
										shape="circle"
										icon={<CaretLeftFilled style={{ color: 'var(--primary-color)' }} />}
										disabled={!previousLink}
										onClick={goToPreviousQuestion}
									/>
								</Tooltip> */}

								<div>
									<span>
										attempted
										&nbsp;
										{totalSubmittedQuestions ?? 0}
										/
										{quizData?.questions}
									</span>
								</div>

								{/* <Tooltip title="next">
									<Button
										type="text"
										size="large"
										shape="circle"
										icon={<CaretRightFilled style={{ color: 'var(--primary-color)' }} />}
										disabled={!nextLink}
										onClick={goToNextQuestion}
									/>
								</Tooltip> */}
							</div>
						</div>
					)
				}

				<div className="user-info-container">
					<Dropdown
						trigger={['click']}
						placement="bottomRight"
						overlayClassName="user-info-actions"
						overlay={(
							<Menu>
								{/* <Menu.Item
									key="user-logout"
									icon={<LogoutIcon height={16} />}
								>
									<a
										href={`${APP_CONFIG.QuizServerURL}/logout`}
										style={{ fontSize: 16, color: '#848484' }}
									>
										Logout
									</a>
								</Menu.Item> */}
								{isElectronApp
									? (
										<Menu.Item
											key="refresh"
											icon={<ReloadOutlined style={{ marginRight: 2, color: 'var(--primary-color)' }} height={16} />}
											onClick={refreshHandler}
										>
											<button
												style={
													{
														fontSize: 16, color: '#848484', border: 'none', background: 'inherit', cursor: 'pointer',
													}
												}
												type="button"
											>
												Refresh
											</button>
										</Menu.Item>
									)
									: (
										<Menu.Item
											key="user-logout"
											icon={<LogoutIcon style={{ marginRight: 2 }} height={16} />}
											onClick={logoutUser}
										>
											<button
												style={
													{
														fontSize: 16, color: '#848484', border: 'none', background: 'inherit', cursor: 'pointer',
													}
												}
												type="button"
											>
												Logout
											</button>
										</Menu.Item>
									)}

							</Menu>
						)}
					>
						<div className="user-info-toggler">
							<div className="user-info">
								<span className="user-display-name">{sessionData?.displayName}</span>
							</div>
							<div className="user-avatar-container">
								<img className="user-avatar" src={sessionData?.profilePic || userIcon} alt="user avatar" />
							</div>
						</div>
					</Dropdown>
				</div>
			</div>
		</div>
	);
};
