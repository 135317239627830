import create, { UseStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import { CQLoginClient, CQQuizClient } from '../../clients';
import { logger } from '../../libs/utils/logger';
import { immer } from '../middleware';

enum SessionAction {
	Auth = 'auth',

	SocketConnected = 'socket-connected',
	SocketDisconnected = 'socket-disconnected',
}

const initialState: SessionState = {
	connected: false,
	isAuthenticated: false,
	sessionData: null,

	loginUser: async () => {},
	registerUser: async () => {},
	getSessionData: async () => {},
	forgotPassword: async () => { },
};

interface SessionStoragePayload {
	cqQuizClient: CQQuizClient,
	cqLoginClient: CQLoginClient,
}

export function createSessionStore(clients: SessionStoragePayload): UseStore<SessionState> {
	const { cqQuizClient, cqLoginClient } = clients;
	return create<SessionState>(immer(devtools((set, get) => {
		cqQuizClient.on('auth', async (data) => {
			// logger.log('session data ===> ', data);

			set({
				isAuthenticated: !!data,
				sessionData: data,
			}, false, SessionAction.Auth);
		});

		cqQuizClient.on('socket-auth', async () => {
			logger.log('socket connected');

			set({
				connected: true,
			}, false, SessionAction.SocketConnected);
		});

		cqQuizClient.on('socket-disconnected', async () => {
			logger.log('socket disconnected');

			set({
				connected: false,
			}, false, SessionAction.SocketDisconnected);
		});

		return {
			...initialState,

			loginUser: async (
				email: string, password: string, quizCode: string, quizName: string,
			): Promise<void> => {
				if (!navigator.onLine) {
					throw new Error('Please check your internet connection.');
				}
				logger.info('user login.');
				await cqQuizClient.loginUser(email, password, quizCode, quizName);
				logger.info('user login success.');

				await get().getSessionData();
			},

			registerUser: async (
				name: string, email: string, password: string, quizCode: string,
				mobile: string, quizName: string, quizCreatedBy: string, enrollmentId?: string,
			): Promise<void> => {
				if (!navigator.onLine) {
					throw new Error('Please check your internet connection.');
				}
				logger.info('user register.');
				await cqQuizClient.registerUser(
					name, email, password, quizCode, mobile, quizName, quizCreatedBy, enrollmentId,
				);
				logger.info('user register success.');

				await get().getSessionData();
			},

			getSessionData: async (): Promise<void> => {
				logger.info('getting session data.');
				await cqQuizClient.getSessionData();

				logger.info('initializing socket connection');
				await cqQuizClient.connect();
			},

			forgotPassword: async (data:forgotPasswordPayload): Promise<any> => {
				logger.info('Sending forgot password request');
				return cqLoginClient.forgotPassword(data);
			},
		};
	}, 'SessionStore')));
}
