import React from 'react';
import { logger } from '../../libs/utils/logger';

export class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	static getDerivedStateFromError(error: unknown) {
		logger.error('Error Boundery hit: ', error);
		if (error instanceof Error) {
			return { error: error?.message ?? 'Component failed' };
		}
		if (typeof error === 'string') {
			return { error };
		}
		return { error: 'Component failed' };
	}

	render() {
		const { children } = this.props;
		if ('error' in this.state) {
			const { error } = this.state;
			console.error(error);
			if (typeof error === 'string') {
				return <></>;
			}
		}
		return children ?? [];
	}
}
